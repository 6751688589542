export default {
    USER_API: process.env.VUE_APP_USER_API || "https://api.protradingscans.com/",
    // USER_API: process.env.VUE_APP_USER_API || "http://192.168.0.3:8013/",
    // USER_API:"http://192.168.100.23:9090/",
    // USER_API:"http://localhost:9090",
    CHARTING_API: process.env.VUE_APP_CORE || "https://core.protradingscans.com/api/v1",
    // CHARTING_API:"http://localhost:5000/api/v1",
    // CHARTING_API: "http://192.168.0.3:5000/api/v1",
    // CHARTING_API:"https://core.protradingscans.com/ap1i/v1",
    // CHARTING_API:"http://192.168.0.102:5000/api/v1",
    // CHARTING_API:"http://192.168.0.113:5000/api/v1",

}