<template>
    <div class="w-full h-full flex flex-col">
      <div
        class="w-full h-[4rem] px-4 flex items-center mt-3 font-[700] text-[16px]"
      >
        <span>Chart Types</span>
      </div>
      <ul
      class="!w-full flex-1 -bg-red-300 bg-white  dark:!bg-[#1A1A1A] dark:!border-[#6C707A] leading-3 flex flex-col items-center justify-between rounded-[3px] scrollbar overflow-y-auto"
      >
        <div class="!w-full -h-full flex flex-col px-4 py-4 -bg-red-300">
          <li
            @click="handleSelectChartType(chartType)"
            class="flex w-full px-5 gap-4 h-[5rem] items-center py-[14px] border border-transparent text-appBtnActiveTxt dark:!text-[#6C707A] rounded-lg md:pl-4"
            v-for="(chartType, index) in chartTypes"
            :key="index"
            :class="[
              selectedChartType?.name == chartType?.name
              ? 'bg-selectedColor dark:!bg-transparent !text-appBtnTxt border !border-selectedBorder'
                      : 'hover:border-[#e4e4e4] hover:border dark:hover:!bg-[#2D2D2D] !text-appBtnTxt hover:bg-selectedHover  -!border-[transparent]',
              getDisable(chartType.key)
              ? 'pointer-events-none opacity-50 cursor-default'
                      : 'cursor-pointer',
                      index == 0 ? 'mt-0' : 'mt-2',
            ]"
          >
            <div
              :class="
                chartType?.name === selectedChartType?.name
                  ? '!text-appBtnActiveTxt'
                  : 'text-[#323232]'
              "
              
            >
              <span v-html="getSvg(chartType.icon, selectedChartType)"></span>
            </div>
            <div class="!text-[16px]">{{ chartType.name }}</div>
          </li>
        </div>
      </ul>
    </div>
  </template>
  <script>
  import { getSvgByName } from '../../assets/getSvgByName'
  import { mapGetters, mapActions } from 'vuex'
  import { chartEvents } from '../../chartEvents'
  
  export default {
    name: 'ChartTypeVue',
    data() {
      return {
        selectedChartType: {
          name: 'Candlestick Chart',
          icon: 'candlestick',
          key: 'Candles',
        },
  
        chartTypes: [
          {
            key: 'Bars',
            name: 'Bar Chart',
            icon: 'barchart',
          },
          {
            key: 'LineChart',
            name: 'Line Chart',
            icon: 'linechart',
          },
          {
            name: 'Candlestick Chart',
            icon: 'candlestick',
            key: 'Candles',
          },
          {
            key: 'Kagi',
            name: 'Kagi Chart',
            icon: 'kagichart',
          },
          {
            name: 'Hiekin Ashi Chart',
            icon: 'heikinashichart',
            key: 'HeikenAshi',
          },
          {
            name: 'Up/Down Chart',
            icon: 'updownchart',
            key: 'UpAndDown',
          },
          {
            name: 'Point And Figures Chart',
            key: 'PnF',
            icon: 'pointfigureschart',
          },
          {
            name: 'Bar Trend Chart',
            key: 'BarTrend',
            icon: 'bartrendchart',
          },
          {
            name: 'Comparison Chart',
            icon: 'comparisonchart',
            key: 'CompareChartMain',
          },
        ],
      }
    },
    components: {},
  
    methods: {
      ...mapActions([
        'setNewChartType',
        'toggleTheme',
        'handleNextSymbol',
        'handlePreviousSymbol',
        'toggleScannerModal',
        'setNewChartTimeFrame',
        'updateTrainingModeState',
      ]),
      getSvg(name, chartType) {
        return getSvgByName(name, chartType)
      },
  
      getDisable(key) {
        if (key == 'CompareChartMain') {
          return false
        } else {
          return false
        }
      },
  
      handleSelectChartType(item) {
        // console.log('item', item)
        chartEvents.$emit('selectCahrtTypeInMobile', item)
        // if (item?.key == 'CompareChartMain') {
        //   this.compareDialogOpen = true
        // } else {
        //   this.selectedChartType = item
        //   this.setNewChartType({ type: item?.key, location: 'FromChartTypeVue' })
        // }
        this.$emit('handleSelectChartType')
      },
    },
    computed: {},
    mounted() {
      let type = localStorage.getItem('SET_CHART_TYPE')
      if (type) {
        let obj = this.chartTypes?.find((item) => item.key == type)
        if (obj) {
          this.selectedChartType = obj
        }
      }
    },
    watch: {
      getChartType(type) {
        if (type) {
          let obj = this.chartTypes?.find((item) => item.key == type)
          if (obj) {
            this.selectedChartType = obj
          }
        }
      },
    },
  }
  </script>
  <style scoped></style>
  