<template>
  <div :style="themePrimaryColor">
    <div class="bottom-tool-bar" style="">
      <!-- first chart control group -->
      <div>
        <sui-button-group class="bottom-container-btns">
          <sui-dropdown
            text="Trend"
            class="button secondary"
            :style="trendCharts.selected ? themeSecondaryColor : {}"
          >
            <sui-dropdown-menu
              :style="themePrimaryColor"
              style="z-index: 101; width: 150px; right: 0; color: black"
            >
              <sui-dropdown-item
                :style="
                  trend.selected
                    ? { ...themeSecondaryColor, ...themeFontColor }
                    : { ...themePrimaryColor, ...themeFontColor }
                "
                @mouseover="
                  trend.selected ? null : enterThemehoverColor($event.target)
                "
                @mouseout="
                  trend.selected ? null : exitElementColor($event.target)
                "
                :ref="trend.chartId"
                @click="toggleOverlay(trend)"
                v-for="trend in trendCharts.data"
                :key="trend.id"
                :class="trend.selected ? 'selected-chart' : ''"
              >
                {{ trend.desc }}
              </sui-dropdown-item>
            </sui-dropdown-menu>
          </sui-dropdown>

          <sui-dropdown
            text="Momentum"
            class="button secondary"
            :style="momentumCharts.selected ? themeSecondaryColor : {}"
          >
            <sui-dropdown-menu
              :style="themePrimaryColor"
              style="z-index: 101; width: 150px; right: 0; color: black"
            >
              <sui-dropdown-item
                :style="
                  trend.selected
                    ? { ...themeSecondaryColor, ...themeFontColor }
                    : { ...themePrimaryColor, ...themeFontColor }
                "
                @mouseover="
                  trend.selected ? null : enterThemehoverColor($event.target)
                "
                @mouseout="
                  trend.selected ? null : exitElementColor($event.target)
                "
                :ref="trend.chartId"
                @click="toggleOverlay(trend)"
                v-for="trend in momentumCharts.data"
                :class="trend.selected ? 'selected-chart' : ''"
                :key="trend.id"
              >
                {{ trend.desc }}
              </sui-dropdown-item>
            </sui-dropdown-menu>
          </sui-dropdown>

          <sui-dropdown
            text="Volatility"
            class="button secondary"
            :style="volatilityCharts.selected ? themeSecondaryColor : {}"
          >
            <sui-dropdown-menu
              :style="themePrimaryColor"
              style="
                z-index: 101 !important;
                width: 150px;
                right: 0;
                color: black;
              "
            >
              <sui-dropdown-item
                :style="
                  trend.selected
                    ? { ...themeSecondaryColor, ...themeFontColor }
                    : { ...themePrimaryColor, ...themeFontColor }
                "
                @mouseover="
                  trend.selected ? null : enterThemehoverColor($event.target)
                "
                @mouseout="
                  trend.selected ? null : exitElementColor($event.target)
                "
                :ref="trend.chartId"
                @click="toggleOverlay(trend)"
                v-for="trend in volatilityCharts.data"
                :class="trend.selected ? 'selected-chart' : ''"
                :key="trend.id"
              >
                {{ trend.desc }}
              </sui-dropdown-item>
            </sui-dropdown-menu>
          </sui-dropdown>

          <sui-dropdown
            v-if="getUserEmail==='mat@loop2.com.au'"
            text="+"
            class="button secondary"
            :style="plusCharts.selected ? themeSecondaryColor : {}"
          >
            <sui-dropdown-menu
              :style="themePrimaryColor"
              style="
                z-index: 101 !important;
                width: 150px;
                right: 0;
                color: black;
              "
            >
              <sui-dropdown-item
                :style="
                  trend.selected
                    ? { ...themeSecondaryColor, ...themeFontColor }
                    : { ...themePrimaryColor, ...themeFontColor }
                "
                @mouseover="
                  trend.selected ? null : enterThemehoverColor($event.target)
                "
                @mouseout="
                  trend.selected ? null : exitElementColor($event.target)
                "
                :ref="trend.chartId"
                @click="toggleOverlay(trend)"
                v-for="trend in plusCharts.data"
                :class="trend.selected ? 'selected-chart' : ''"
                :key="trend.id"
              >
                {{ trend.desc }}
              </sui-dropdown-item>
            </sui-dropdown-menu>
          </sui-dropdown>

          <sui-dropdown
            text="Drawings"
            class="button secondary drawing footer-drawing-btn"
            style="display: none"
          >
            <sui-dropdown-menu
              :style="themePrimaryColor"
              style="z-index: 101; width: 195px; right: 0; color: black"
            >
              <sui-dropdown-item
                @click="
                  Mob_Menu_Dropdn_Item('Measure Tool'),
                    selectTool('RangeTool:PriceTime')
                "
                :style="
                  mob_menu_dropdn_item === 'Measure Tool'
                    ? { ...themeSecondaryColor, ...themeFontColor }
                    : {} && themeFontColor
                "
              >
                <sui-icon
                  :style="themeFontColor"
                  name="ticket alternate icon "
                />
                Measure Tool
              </sui-dropdown-item>
              <sui-dropdown-item
                @click="
                  Mob_Menu_Dropdn_Item('Trend Line'),
                    selectTool('TrendLine:Trend')
                "
                :style="
                  mob_menu_dropdn_item === 'Trend Line'
                    ? { ...themeSecondaryColor, ...themeFontColor }
                    : {} && themeFontColor
                "
              >
                <sui-icon
                  :style="themeFontColor"
                  name="window minimize outline icon "
                />
                Trend Line
              </sui-dropdown-item>
              <sui-dropdown-item
                @click="
                  Mob_Menu_Dropdn_Item('Horizontal Line'),
                    selectTool('HLineTool:Segment')
                "
                :style="
                  mob_menu_dropdn_item === 'Horizontal Line'
                    ? { ...themeSecondaryColor, ...themeFontColor }
                    : {} && themeFontColor
                "
              >
                <sui-icon
                  :style="themeFontColor"
                  name="arrows alternate horizontal icon "
                />
                Horizontal Line
              </sui-dropdown-item>
              <sui-dropdown-item
                @click="
                  Mob_Menu_Dropdn_Item('Text'), selectTool('TextTool:Label')
                "
                :style="
                  mob_menu_dropdn_item === 'Text'
                    ? { ...themeSecondaryColor, ...themeFontColor }
                    : {} && themeFontColor
                "
              >
                <sui-icon :style="themeFontColor" name="font icon " />
                Text
              </sui-dropdown-item>
              <sui-dropdown-item
                @click="
                  Mob_Menu_Dropdn_Item('Box'), selectTool('RectangleTool:Shape')
                "
                :style="
                  mob_menu_dropdn_item === 'Box'
                    ? { ...themeSecondaryColor, ...themeFontColor }
                    : {} && themeFontColor
                "
              >
                <sui-icon :style="themeFontColor" name="box icon " />
                Box
              </sui-dropdown-item>
              <sui-dropdown-item
                @click="
                  Mob_Menu_Dropdn_Item('Parallel Line'),
                    selectTool('ChannelTool:Segment')
                "
                :style="
                  mob_menu_dropdn_item === 'Parallel Line'
                    ? { ...themeSecondaryColor, ...themeFontColor }
                    : {} && themeFontColor
                "
              >
                <sui-icon :style="themeFontColor" name="pause icon " />
                Parallel Line
              </sui-dropdown-item>
              <sui-dropdown-item
                @click="
                  Mob_Menu_Dropdn_Item('Fibonacci Retracement'),
                    selectTool('FibonacciRetrace:Segment')
                "
                :style="
                  mob_menu_dropdn_item === 'Fibonacci Retracement'
                    ? { ...themeSecondaryColor, ...themeFontColor }
                    : {} && themeFontColor
                "
              >
                <sui-icon :style="themeFontColor" name="random icon " />
                Fibonacci Retracement
              </sui-dropdown-item>
              <sui-dropdown-item
                @click="
                  Mob_Menu_Dropdn_Item('Trade Planner'),
                    selectTool('TradeVisualizerTool:PL')
                "
                :style="
                  mob_menu_dropdn_item === 'Trade Planner'
                    ? { ...themeSecondaryColor, ...themeFontColor }
                    : {} && themeFontColor
                "
              >
                <sui-icon
                  :style="themeFontColor"
                  name="sliders horizontal icon "
                />
                Trade Planner
              </sui-dropdown-item>
              <sui-dropdown-item
                @click="
                  Mob_Menu_Dropdn_Item('Trailing Stop'),
                    selectTool('TrailingStopTool:PL')
                "
                :style="
                  mob_menu_dropdn_item === 'Trailing Stop'
                    ? { ...themeSecondaryColor, ...themeFontColor }
                    : {} && themeFontColor
                "
              >
                <sui-icon :style="themeFontColor" name="schlix icon " />
                Trailing Stop
              </sui-dropdown-item>
              <sui-dropdown-item
                @click="
                  onDeleteButtonClick(),
                    Mob_Menu_Dropdn_Item('Delete All Drawings')
                "
                :style="
                  mob_menu_dropdn_item === 'Delete All Drawings'
                    ? { ...themeSecondaryColor, ...themeFontColor }
                    : {} && themeFontColor
                "
              >
                <sui-icon
                  :style="themeFontColor"
                  name="trash alternate outline icon "
                />
                Delete All Drawings
              </sui-dropdown-item>
            </sui-dropdown-menu>
          </sui-dropdown>
        </sui-button-group>
      </div>
      <!-- chart drawing objects -->
      <div class="footer-web-drawings" style="margin-left: 2rem">
        <sui-button-group icons>
          <custom-image-icon-button
            v-for="tool of toolButtons"
            :imageSrc="tool.img"
            @click="tool.onClick"
            :key="tool.img"
            :isText="tool.isText"
            :tooltipText="tool.toolTip"
            :toolColor="tool.style"
          />
          <custom-image-icon-button
            v-for="tool in emojiButton"
            :imageSrc="tool.img"
            @click="tool.onClick"
            :key="tool.img"
            :isText="tool.isText"
            :tooltipText="tool.toolTip"
            :toolColor="tool.style"
          />

          <sui-button
            icon="trash"
            secondary
            @click="onDeleteButtonClick"
            data-tooltip="Delete All Drawings"
            data-position="top center"
            data-inverted=""
          />
        </sui-button-group>

      </div>
    </div>
    <div
      v-if="openEmoji"
      style="
    position: absolute;
    /*margin-bottom: 200%;*/
    /*top: 0px;*/
    bottom: 80px;
    left: 39%;
    transform: translate(-50%, -89%);
    z-index: 99 !important;
    background: white; /* Optional: Background color */
    border-radius: 8px; /* Optional: Rounded corners */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Optional: Shadow for better visibility */
    padding: 20px; /* Optional: Padding for spacing */
    margin-bottom: 200px !important;
  "
    >
      <VuemojiPicker @emojiClick="handleEmojiClick" :isDark="false" />
    </div>

    <!-- trailing stop dialog box -->
    <trailing-stop v-if="showTrailingStop" :trialingStopData="trialingStopData" @hideTrailingStop="trailingStopDialogOnClose" />`
  </div>
</template>

<script>
import CustomImageIconButton from '@/baseComponents/CustomImageIconButton.vue'
import TrailingStop from 'Components/dialogBoxes/TrailingStop'
import { mapActions, mapGetters } from 'vuex'
import user from '@/store/user'
import ChartAdding from 'Components/ChartAdding'
import { chartEvents } from '../chartEvents'
import Overlays from '../overlays'
import { VuemojiPicker } from 'vuemoji-picker'

export default {
  components: { CustomImageIconButton, TrailingStop, VuemojiPicker },
  name: 'BottomToolbox',
  mixins: [ChartAdding],
  mounted() {
    // console.log('trendCharts' , this.trendCharts)
    // if (this.$store.state.tradingChartReference) {
    //   this.$store.state.tradingChartReference.$on(
    //     'remove-tool',
    //     this.onToolRemoved
    //   )
    // }
    /*this.$nextTick(() => {
      chartEvents.$emit('bindComponentEvent','remove-tool', this.onToolRemoved)
    })*/
    chartEvents.$on('updateTrailingStopModal',(params) => {
      console.log("TrailingStopTool",params)
      this.trialingStopData = params
      this.showTrailingStop = true
    })
    chartEvents.$emit('bindComponentEvent', 'remove-tool', this.onToolRemoved)
  },
  beforeDestroy() {
    chartEvents.$off('updateTrailingStopModal')
  },
  data() {
    return {
      trialingStopData: {},
      showTrailingStop: false,
      again_mob_menu_dropdn_item: '',
      mob_menu_dropdn_item: '',
      trendButtonToggle: false,
      momentumButtonToggle: false,
      volatilityButtonToggle: false,
      toolRemoved: false,
      toolButtons: [
        {
          img: '/images/bottom/ruler.svg',
          onClick: () => this.selectTool('RangeTool:PriceTime'),
          toolTip: 'Price Measurement Tool',
          toolTipMobile: 'Measure Tool',
          style: 'background-color : red',
        },
        {
          img: '/images/bottom/trend-line.svg',
          onClick: () => this.selectTool('TrendLine:Trend'),
          toolTip: 'Line Tool',
          toolTipMobile: 'Line Tool',
          style: 'background-color : blue',
        },
        {
          img: '/images/bottom/arc.svg',
          onClick: () => this.selectTool('ArcTool:Arc'),
          toolTip: 'Arc Tool',
          toolTipMobile: 'Arc Tool',
          style: 'background-color : blue',
        },
        {
          img: '/images/bottom/horizontal-line.svg',
          onClick: () => this.selectTool('HLineTool:Segment'),
          toolTip: 'Horizontal Line Tool',
          toolTipMobile: 'Horizontal Tool',
          style: 'background-color : green',
        },
        {
          img: 'T',
          onClick: () => this.selectTool('TextTool:Label'),
          isText: true,
          toolTip: 'Text Tool',
          toolTipMobile: 'Text Tool',
        },
        {
          img: '/images/bottom/rectangle.svg',
          onClick: () => this.selectTool('RectangleTool:Shape'),
          toolTip: 'Box Tool',
          toolTipMobile: 'Box Tool',
        },
        {
          img: '/images/bottom/parallel-line.svg',
          onClick: () => this.selectTool('ChannelTool:Segment'),
          toolTip: 'Parellel Lines Tool',
          toolTipMobile: 'Parellel Tool',
        },
        {
          img: '/images/bottom/fib-retracement.svg',
          onClick: () => this.selectTool('FibonacciRetrace:Segment'),
          toolTip: 'Fibonacci Retracement Tool',
          toolTipMobile: 'Fibonacci Tool',
        },
        {
          img: '/images/bottom/trade-planner.svg',
          onClick: () => this.selectTool('TradeVisualizerTool:PL'),
          toolTip: 'Trade Visualizer',
          toolTipMobile: 'Trade Visualizer',
        },
        {
          img: '/images/bottom/trailingstop.svg',
          onClick: () => this.selectTool('TrailingStopTool:PL'),
          toolTip: 'Trailing Stop',
          toolTipMobile: 'Trailing Stop',
        },
      ],
      emojiButton: [
        {
          // Author: Ahsan Ahmed Khan
          img: '/images/bottom/money-emoji.svg',
          onClick: () => this.handleOpenEmoji(),
          toolTip: 'Emoji Tool',
          toolTipMobile: 'Emoji Tool',
        },
      ],
      isActive: false,
      activeBtn: null,
      openEmoji: false,
      selectedEmoji: null,
    }
  },
  methods: {
    ...mapActions([
      'saveChartSettings',
      'enterThemehoverColor',
      'exitElementColor',
      'deleteAllDrawing',
      'deleteDrawing',
    ]),
    trailingStopDialogOnClose() {
      this.showTrailingStop = false
    },
    onDeleteButtonClick() {
      chartEvents.$emit('onDeleteButtonClick')
    },
    handleOpenEmoji() {
      this.openEmoji = !this.openEmoji

    },
    handleEmojiClick(detail) {
      this.openEmoji = false
      localStorage.setItem('emoji', detail?.emoji?.unicode)
      // let emoji = {text:detail?.emoji?.unicode}
      this.selectedEmoji = { text: detail?.emoji?.unicode }
      this.selectTool('EmojiTool:Label')
    },

    Mob_Menu_Dropdn_Item(menuItem) {
      this.mob_menu_dropdn_item = menuItem
      if (this.mob_menu_dropdn_item === this.again_mob_menu_dropdn_item) {
        this.mob_menu_dropdn_item = ''
        this.again_mob_menu_dropdn_item = ''
      } else {
        this.again_mob_menu_dropdn_item = this.mob_menu_dropdn_item
      }
    },
    updateOverlayChart(
      storeRef,
      multi_value = false,
      updatedProps,
      mapping,
      name,
      gridId,
      dataType = 'object',
      updateRefs = false
    ) {
      // console.log(JSON.stringify({
      //   storeRef, multi_value, updatedProps, mapping, name, gridId, dataType
      // }))
      // console.log("this.getSingleIndicator[storeRef] == ", JSON.stringify(this.getSingleIndicator[storeRef]))
      let userSettingsList = multi_value
        ? this.getMultiIndicator[storeRef]
          ? this.getMultiIndicator[storeRef]
          : null
        : this.getSingleIndicator[storeRef]
      if (!multi_value) {
        updatedProps = [updatedProps]
        userSettingsList = [userSettingsList]
      } else {
        if (userSettingsList && userSettingsList.length) {
          updatedProps = userSettingsList.map((a) => {
            return a
          })
        }
      }
      let itemId = 0
      if (updateRefs && multi_value) {
        // this.remove_overlay(name)
      }
      // console.log("userSettingsList",userSettingsList)
      // console.log("updatedProps",JSON.stringify(updatedProps),name)
      if (userSettingsList && !multi_value) {
        for (let userSettingKey in userSettingsList) {
          let updateProp = userSettingsList[userSettingKey]
          if (updateProp['period']) {
            updateProp['period'] = Number(updateProp['period'])
          }
          this.add_overlay(
            name,
            gridId,
            updateProp,
            dataType,
            multi_value,
            itemId,
            updateRefs
          )
          itemId++
        }
      } else {
        for (const propsKey in updatedProps) {
          let updateProp = updatedProps[propsKey]
          if (updateProp['period']) {
            updateProp['period'] = Number(updateProp['period'])
          }
          // console.log(JSON.stringify(updateProp))
          this.add_overlay(
            name,
            gridId,
            updateProp,
            dataType,
            multi_value,
            itemId,
            true
          )
          itemId++
        }
      }

      return updatedProps
    },

    updateStateCharts() {
      const defaultCharts = [
        'trendCharts',
        'momentumCharts',
        'volatilityCharts',
      ]
      for (const chart of defaultCharts) {

        if (this[chart] && this[chart].selected) {
          for (const datum of this[chart].data) {
            if (datum.selected) {
              this.add_overlay(datum.name)
            }
          }
        }
      }
    },
    onToolRemoved() {
      this.toolRemoved = false
    },
    selectTool(toolID) {
      chartEvents.$emit('generateCustomEvent', 'tool-selected', [toolID])
    },
    async toggleOverlay(trend) {
      console.log(trend)
      let { name, selected, chartId, tool_type } = trend
      let overlay_tv = Object.values(Overlays)
      // console.log(name, gridId,updatedProps ,dataType,changeState,storeRef,mapping,multi_value,tool_type)
      let config = this.allChartOverlays.find((n) => n.name === name)
      let overlay = overlay_tv.find(
        (n) => n.name.toUpperCase() === name.toUpperCase()
      )
      let chartList = this.userChartlist
      if (overlay && overlay.methods.meta_info && config) {
        const userSettings = config.getData()

        if (
          await this.saveChartSettings({
            tool_type,
            name: name,
            selected,
            chartId,
          })
        ) {
          if (selected) {
            let ind = chartList.indexOf(chartId)
            if (ind > -1) {
              chartList.splice(ind, 1)
              chartEvents.$emit('remove_overlay_new', overlay, name)
            }
          } else {
            let isArray = Array.isArray(userSettings)
            if (isArray) {
              for (const settings of userSettings) {
                settings['chartId'] = chartId
                settings['tool_type'] = tool_type
              }
            } else {
              userSettings['chartId'] = chartId
              userSettings['tool_type'] = tool_type
            }

            let grid = {}
            if (config.childRef === 'VolumeOVB') {
              // grid.id =1

              let gridMap = this.$store.state?.dc?.dcgl
              const volumeKey = 'offchart.VolumeOBV0'
              if (gridMap && volumeKey in gridMap) {
                let gridID = gridMap[volumeKey].split('_')[0].slice(1)
                grid.id = Number(gridID)
              } else {
                return
              }

              // console.log("overlayInfo",overlayInfo)
            }
            chartList.push(chartId)
            chartEvents.$emit(
              'add_overlay_new',
              overlay,
              name,
              userSettings,
              false,
              true,
              grid
            )
          }
          this.$store.commit('SET_USER_CHART_LIST', { data: chartList })
        }
      }
    },
    remove_overlay(name) {
      const e = { type: name, side: 'auto' }
      let preset = this.get_preset(e.type) || {}
      // console.log('add_overlay preset  --- ',preset,updatedProps)

      if (preset.side) e.side = preset.side
      // let onchart = this.chart.data.onchart
      let sideData = this.dc.data[e.side]
      let chartData = sideData.filter((a) => a.type === name)
      // if(chartData && chartData.length){
      //   this.dc.del(chartData[0].id)
      // }
      for (const data of chartData) {
        this.dc.del(data.id)
      }
    },
    add_overlay(
      name,
      gridId,
      updatedProps = undefined,
      dataType = undefined,
      multi_value = false,
      itemId = 0,
      updateRefs = false
    ) {
      // console.log("name",name,"gridId == ",gridId)
      const e = { type: name, side: 'auto' }
      let preset = this.get_preset(e.type) || {}
      if (preset.side) e.side = preset.side
      // let onchart = this.chart.data.onchart
      let offchart = this.dc.data.offchart
      let onchart = this.dc.data.onchart
      // console.log(JSON.stringify({ name, gridId, updatedProps, dataType, multi_value, itemId, updateRefs }))
      // console.log(JSON.stringify(offchart.find(indi => indi.type === e.type)))

      // Update Preset as per scan
      if (updatedProps) {
        if (dataType === 'object') {
          for (const key in updatedProps) {
            if (Object.hasOwnProperty.call(updatedProps, key)) {
              const element = updatedProps[key]
              // if(preset.settings[key]){
              preset.settings[key] = element
              // }
            }
          }
        } else if (dataType === 'string') {
          preset.settings['length'] = updatedProps
        }
      }

      if (e.side === 'onchart') {
        if (multi_value) {
          let list = this.$store.state.dc.get(name)
          let chartObj = list.find((i) => i.id === `onchart.${name}${itemId}`)
          // console.log("onchart == ", name, chartObj,preset.settings)
          let data = []
          let newRef = false
          if (updateRefs) {
            newRef = true
            data = updateRefs[itemId]
          }
          // else{
          if (chartObj) {
            let newSettings = {
              settings: { ...chartObj.settings, ...preset.settings },
            }
            // console.log(newSettings)
            this.dc.merge(chartObj.id, newSettings)
            // this.dc.tv.resetChart(true)
            // console.log(this.dc.get_one(chartObj.id))
          } else {
            let multiValChart = {
              name: preset.name,
              id: itemId,
              type: e.type,
              data: [],
              settings: preset.settings || {},
            }

            this.dc.add('onchart', multiValChart)

            if (newRef) {
              this.dc.tv.resetChart(true)
            }
          }
        } else {
          let chartData = onchart.find((indi) => indi.type === e.type)
          if (chartData) {
            // console.log()
            let newSettings = {
              settings: { ...chartData.settings, ...preset.settings },
            }
            // console.log("chart updates",newSettings)
            this.dc.merge(chartData.id, newSettings)
            // console.log(this.dc.get_one(chartData.id))
            // this.dc.tv.resetChart(true)
            return
          }
          this.dc.add('onchart', {
            name: preset.name,
            type: e.type,
            data: [],
            settings: preset.settings || {},
          })
        }
      } else {
        let chartData = offchart.find((indi) => indi.type === e.type)
        if (chartData) {
          // console.log(chartData.id)
          this.dc.merge(chartData.id, preset)
          this.dc.tv.resetChart(true)
          return
        }
        const h = this.avg_grid_h(offchart)

        // construct the new indicator
        const newIndicator = {
          name: preset.name,
          type: e.type,
          data: [],
          settings: preset.settings || {},
          grid: { height: h, id: gridId },
        }

        // add the new indi directly if it is a custom grid indicator
        if (gridId) {
          return this.dc.add('offchart', newIndicator)
        }

        // old indicator list
        const oldList = this.dc.get('offchart').map((ov) => ({
          name: ov.name,
          type: ov.type,
          data: [],
          settings: ov.settings,
          grid: ov.grid,
        }))

        // delete all offchart overlays
        this.dc.del('offchart')

        const customGridIndicators = []
        let addRes = null
        for (const indi of oldList) {
          // console.log(indi)
          if (indi.grid.id) {
            customGridIndicators.push(indi)
          } else {
            this.dc.add('offchart', indi)
          }
        }

        // add the new indi
        this.dc.add('offchart', newIndicator)

        // add the old custom grid indis
        customGridIndicators.forEach((indi) => {
          // console.log(indi)
          this.dc.add('offchart', indi)
        })
        // console.log("addRes",this.dc.gldc,e)
        this.addingChartDrawings(e, this)
        // this.$store.commit("CURRENT_OFF_CHART",e.type)
      }
      // this.dc.update_ids()
      // this.chart.update_ids()
    },
    get_preset(type) {
      let proto = this.tradingChart.overlays.find((x) => x.name === type)
      // console.log("proto == ",JSON.stringify(proto))
      if (proto && proto.methods.meta_info) {
        let meta = proto.methods.meta_info()
        return meta.preset
      }
    },
    avg_grid_h(ovs) {
      if (!ovs.length) return 0.25
      let gh = 0
      for (var ov of ovs) {
        if (ov.grid && typeof ov.grid.height === 'number') {
          gh += ov.grid.height
        }
      }
      return gh / ovs.length
    },
  },
  computed: {
    ...mapGetters([
      'dc',
      'tv',
      'scanningIndicators',
      'gurusScanSettings',
      'trendCharts',
      'momentumCharts',
      'volatilityCharts',
      'plusCharts',
      'chartSettingUpdatedDate',
      'isChartLoading',
      'getSingleIndicator',
      'getMultiIndicator',
      'themeSecondaryColor',
      'themePrimaryColor',
      'themeFontColor',
      'userChartlist',
      'drawingDataList',
      'eventsSettings',
      'chartOverlays',
      'getUserEmail',
      'allChartOverlays',
    ]),
    chart: function() {
      return this.dc
    },
    tradingChart: function() {
      return this.$store.state.tradingChartReference
    },
  },
  watch: {
    chartSettingUpdatedDate(n, o) {

      if (this.isChartLoading && n > o) {
        this.updateStateCharts()
      }
    },
  },
}
</script>

<style>
@media screen and (max-width: 700px) {
  .bottom-tool-bar {
    bottom: 0;
    position: fixed;
    /* margin-bottom: 0.2rem; */
  }
}

.bottom-tool-bar {
  bottom: 0;
  position: fixed;
}

@media screen and (min-width: 701px) {
  .bottom-tool-bar {
    /* margin-bottom: 0.3rem; */
    margin-bottom: 0.5rem;
  }
}

/* Dropup Button */
/* The container <div> - needed to position the dropup content */
.dropup {
  position: relative;
  display: inline-block;
}

/* Dropup content (Hidden by Default) */
.dropup-content {
  /* display: none; */
  position: absolute;
  left: 0;
  bottom: 2rem;
  background-color: #f1f1f1;
  min-width: 100px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  z-index: 50000;
}

/* Links inside the dropup */
.dropup-content button {
  color: black;
  padding: 8px 16px;
  text-decoration: none;
  display: block;
  z-index: 100;
  width: 100%;
  font-size: 11px;
  /* font-size: 12px;   changed  */
}

/* Change color of dropup links on hover */
.dropup-content button:hover {
  background-color: red;
}

.selected-chart {
  background-color: red;
  border-bottom: 1px solid #58585e;
}

/* Show the dropup menu on hover */
.drop-up-btn:hover .dropup-content {
  display: block;
}

@media screen and (max-width: 700px) {
  .footer-drawing-btn {
    display: block !important;
  }

  .footer-web-drawings {
    display: none;
  }

  .bottom-tool-bar .ui.button {
    padding: 0.78571429em 0.7em 0.78571429em !important;
  }

  .drawing {
    margin-left: 10px !important;
  }

  .bottom-tool-bar {
    font-size: 1px;
  }
}

@media screen and (max-width: 378px) {
}

/* Change the background color of the dropup button when the dropup content is shown */
/* .dropup:hover {
  background-color: red;
} */
</style>
