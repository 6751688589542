<template>
<div>
        <right-bar-header
               headerText="Scan Result"
               @onClose="onClose"
               :headerCount="resultsCount"
               :headerIcon="isScanerOpen"
            ></right-bar-header>
            <div v-if="isScanerOpen">

              
                <div>
                  <!-- MINI HEADER -->
                <div class="flex justify-between bg-[#F6F6F6] items-center py-[5px] px-[10px]">
                    <div class="text-[13px] text-[#323232]"> <span :class=" resultsCount > 0 ? 'font-semibold':''">{{ resultsCount > 0 ? `(${resultsCount?.toLocaleString('en-US')})` : 'No' }}</span> stock(s) found</div>
                    <div class="flex gap-2">
                        <div>
                            <svg width="20" height="21" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                               <path d="M7.04307 3.25697C7.18775 2.93236 7.26013 2.77005 7.36088 2.72007C7.44838 2.67664 7.55113 2.67664 7.63863 2.72007C7.73938 2.77005 7.81175 2.93236 7.95644 3.25697L9.10894 5.84255C9.15175 5.93851 9.17313 5.98649 9.20625 6.02324C9.2355 6.05575 9.27132 6.08176 9.31125 6.09956C9.35644 6.11968 9.40869 6.12519 9.51319 6.13622L12.3284 6.43335C12.6818 6.47065 12.8585 6.4893 12.9372 6.56967C13.0055 6.63947 13.0373 6.73722 13.023 6.83387C13.0066 6.94512 12.8746 7.06406 12.6106 7.302L10.5077 9.19712C10.4297 9.26744 10.3906 9.30262 10.3659 9.34544C10.3441 9.38337 10.3304 9.42544 10.3258 9.46894C10.3206 9.51812 10.3315 9.5695 10.3533 9.67231L10.9407 12.4415C11.0144 12.7892 11.0513 12.963 10.9992 13.0626C10.9539 13.1492 10.8708 13.2096 10.7744 13.2259C10.6636 13.2447 10.5096 13.1559 10.2018 12.9784L7.74957 11.564C7.65857 11.5115 7.61307 11.4853 7.56469 11.475C7.52188 11.4659 7.47763 11.4659 7.43482 11.475C7.38644 11.4853 7.34094 11.5115 7.24994 11.564L4.79777 12.9784C4.4899 13.1559 4.33597 13.2447 4.22509 13.2259C4.12877 13.2096 4.04562 13.1492 4.00035 13.0626C3.94823 12.963 3.9851 12.7892 4.05884 12.4415L4.64619 9.67231C4.66799 9.5695 4.67889 9.51812 4.67372 9.46894C4.66913 9.42544 4.65547 9.38337 4.63359 9.34544C4.60887 9.30262 4.56985 9.26744 4.4918 9.19712L2.38892 7.302C2.12491 7.06406 1.9929 6.94512 1.97649 6.83387C1.96224 6.73722 1.994 6.63947 2.06233 6.56967C2.14101 6.4893 2.31773 6.47065 2.67117 6.43335L5.48635 6.13622C5.59083 6.12519 5.64307 6.11968 5.68825 6.09956C5.72822 6.08176 5.764 6.05575 5.79329 6.02324C5.82638 5.98649 5.84777 5.93851 5.89055 5.84255L7.04307 3.25697Z" fill="#323232"/>
                            </svg>

                        </div>

                        <div>
                            <svg width="20" height="20" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.333496 7.00065C0.333496 3.85795 0.333496 2.2866 1.30981 1.3103C2.28612 0.333984 3.85746 0.333984 7.00016 0.333984C10.1428 0.333984 11.7142 0.333984 12.6905 1.3103C13.6668 2.2866 13.6668 3.85795 13.6668 7.00065C13.6668 10.1433 13.6668 11.7147 12.6905 12.691C11.7142 13.6673 10.1428 13.6673 7.00016 13.6673C3.85746 13.6673 2.28612 13.6673 1.30981 12.691C0.333496 11.7147 0.333496 10.1433 0.333496 7.00065ZM7.00016 3.16732C7.2763 3.16732 7.50016 3.39118 7.50016 3.66732V7.12685L8.64663 5.98045C8.8419 5.78518 9.15843 5.78518 9.3537 5.98045C9.54896 6.17572 9.54896 6.49225 9.3537 6.68752L7.3537 8.68752C7.25996 8.78132 7.13276 8.83398 7.00016 8.83398C6.86756 8.83398 6.74036 8.78132 6.64663 8.68752L4.64661 6.68752C4.45135 6.49225 4.45135 6.17572 4.64661 5.98045C4.84187 5.78518 5.15846 5.78518 5.35372 5.98045L6.50016 7.12685V3.66732C6.50016 3.39118 6.72403 3.16732 7.00016 3.16732ZM4.3335 9.83398C4.05736 9.83398 3.8335 10.0579 3.8335 10.334C3.8335 10.6101 4.05736 10.834 4.3335 10.834H9.66683C9.94296 10.834 10.1668 10.6101 10.1668 10.334C10.1668 10.0579 9.94296 9.83398 9.66683 9.83398H4.3335Z" fill="#41901C"/>
                             </svg>

                        </div>
                    </div>
                </div>
              <!-- MINI HEADER -->
              <div 
                v-if="resultsCount < 1"
                class="h-[250px] flex items-center justify-center text-[14px]"
                >
                  run scanners to see the results
              </div>
              <div 
              v-else
              class="h-[240px] overflow-y-auto scrollbar"
              >

                <div v-for="(scan, index) of sacnResults"
                @click="selectScan(scan, index)"
                :key="`${
                  scan.symbol ? scan.symbol + scan.patternName : scan
                }-${Date.now()}`"
                class="flex justify-between px-[10px] py-[10px] border-t-[1px] border-[#ededed] cursor-pointer hover:bg-[#f6f6f6]">
                    <div class="flex gap-4">   
                        <div class="text-[12px] font-bold w-[70px] line-clamp-1">
                            {{ scan.symbol }}
                        </div>
                        <div class="text-[10px]">
                            {{ scan.patternName }}
                        </div>    
                    </div>

                    <div>
                      
                      <span v-if="scan['patternName']">
                        <img
                    v-if="scanSearchImg[scan.patternName]?.key === 'icon'"
                    style="width: 10px"
                    :src="scanSearchImg[scan.patternName].img"
                    alt=""
                  />

                  <span
                    v-else-if="scanSearchImg[scan.patternName]?.key === 'text'"
                    style="font-size: 8.45px; font-weight: bold"
                    >{{ scanSearchImg[scan.patternName].code }}</span
                  >
                      </span>
                    </div>
                </div>


                </div>
              </div>

              
            </div>
        
    </div>
    
</template>
<script>
import { json } from "d3"
import RightBarHeader from "../components/RightBarHeader.vue"

export default {
    name : 'ScannerResult',
    components : {
        RightBarHeader
    },
    props : {
        sacnResults : {
            type : Array,
            default : []
        },
        resultsCount : {
            type : Number,
            default : 0
        }
    },
data(){
    return{
        isScanerOpen : true,
        scanSearchImg: {
          // pattern icon
          SaucerBottom: {
            key: 'icon',
            img: '/images/patterns/saucer_bottom.svg',
          },
          DoubleBottom: {
            key: 'icon',
            img: '/images/patterns/double_bottom.svg',
          },
          BullishPennant: {
            key: 'icon',
            img: '/images/patterns/bullish_pennant.svg',
          },
          BearishFlag: {
            key: 'icon',
            img: '/images/patterns/bearish_flag.svg',
          },
          AscendingTriangle: {
            key: 'icon',
            img: '/images/patterns/ascending_triangle.svg',
          },
          DoubleTop: {
            key: 'icon',
            img: '/images/patterns/double_top.svg',
          },
          TriPointResistance: {
            key: 'icon',
            img: '/images/patterns/tri_point_resistance.svg',
          },
          BearishPennant: {
            key: 'icon',
            img: '/images/patterns/bearish_pennant.svg',
          },
          DescendingTriangle: {
            key: 'icon',
            img: '/images/patterns/descending_triangle.svg',
          },
          TriPointSupport: {
            key: 'icon',
            img: '/images/patterns/tri_point_support.svg',
          },
          BullishFlag: {
            key: 'icon',
            img: '/images/patterns/bullish_flag.svg',
          },
  
          // Candle stick icon
  
          Doji: {
            key: 'icon',
            img: '/images/candlesticks/doji.svg',
          },
          InvertedHammer: {
            key: 'icon',
            img: '/images/candlesticks/inverted-hammer.svg',
          },
          BullishEngulfing: {
            key: 'icon',
            img: '/images/candlesticks/bullish-engulfing.svg',
          },
          BullishHarami: {
            key: 'icon',
            img: '/images/candlesticks/bullish-harami.svg',
          },
          BearishEngulfing: {
            key: 'icon',
            img: '/images/candlesticks/bearish-engulfing.svg',
          },
          BearishHarami: {
            key: 'icon',
            img: '/images/candlesticks/bearish-harami.svg',
          },
          Hammer: {
            key: 'icon',
            img: '/images/candlesticks/hammer.svg',
          },
          HangingMan: {
            key: 'icon',
            img: '/images/candlesticks/hanging-man.svg',
          },
  
          // Text / Events code
  
          TCIBreak: {
            key: 'text',
            code: 'TCI ',
          },
          TCITrend: {
            key: 'text',
            code: 'TCI ',
          },
          MacdSetting: {
            key: 'text',
            code: 'MACD ',
          },
          ObvAbove: {
            key: 'text',
            code: 'OBV ',
          },
          VolumeMore: {
            key: 'text',
            code: 'VOL ',
          },
          ProReversalSignal:{
            key: 'text',
            code: 'PRS ',
          },
  
  
          // Text / Indicator code
          Cross: {
            key: 'text',
            code: 'CROSS ',
          },
          Roc: {
            key: 'text',
            code: 'ROC ',
          },
          RSI: {
            key: 'text',
            code: 'RSI ',
          },
          Stoc: {
            key: 'text',
            code: 'STOCK ',
          },
          Bands: {
            key: 'text',
            code: 'BANDS ',
          },
          VolumeIncrease: {
            key: 'text',
            code: 'Vol-Inc ',
          },
          TrendLineCrossing: {
            key: 'text',
            code: 'TX',
          },
  
        },
    }
},
    methods : {
        onClose() {
            console.log("onClose")
            let result = JSON.parse(localStorage.getItem("rightBar"))
            result.scanResult = !result.scanResult

            localStorage.setItem("rightBar", JSON.stringify(result))
            this.isScanerOpen = !this.isScanerOpen
            // this.$emit('onClose')
            
        },

        selectScan(scan, index) {
            // console.log("selectScan", scan, index)
            this.$emit('selectedScanSymbol', scan, index)
        },

        checkRightBar(){
          let result = JSON.parse(localStorage.getItem("rightBar"))
          this.isScanerOpen = result.scanResult
        }

        
    },
    mounted() {
        console.log("this.sacnResults", localStorage.getItem("rightBar"))
        this.checkRightBar()
    },
    watch:{
      sacnResults(o,n){
        let res = JSON.parse(localStorage.getItem("rightBar"))
        res.scanResult = true
        localStorage.setItem("rightBar", JSON.stringify(res))
        this.isScanerOpen = true

      }
    }

}


</script>
