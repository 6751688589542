<template>
    <div @click="onClick"
     class="bg-[#110023] flex justify-between items-center h-[30px] px-[10px] cursor-pointer">
                <div class="text-white text-[15px] gap-1">
                    {{headerText}} <span>{{headerCount > 0 ? `(${headerCount.toLocaleString('en-US')})` : ''}}</span>
                </div>
                headerIcon
                <div> 
                    <div v-if="headerIcon" class="text-white bg-[#1a4f79] flex items-center justify-center h-[15px] w-[15px] rounded-[4px] cursor-pointer" >
                    <svg width="8" height="4" viewBox="0 0 6 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                       <path d="M5.25 1.5625C5.56065 1.5625 5.8125 1.31065 5.8125 1C5.8125 0.68935 5.56065 0.4375 5.25 0.4375H0.75C0.439342 0.4375 0.1875 0.68935 0.1875 1C0.1875 1.31065 0.439342 1.5625 0.75 1.5625H5.25Z" fill="white"/>
                    </svg>
                </div>
                <div v-else class="text-[18px] text-white bg-[#1a4f79] flex items-center justify-center h-[15px] w-[15px] rounded-[4px] cursor-pointer">
                    +
                </div>

                </div>
                
            </div>
</template>

<script>
export default {
    name: 'RighBarHeader',
    props:{
        headerText:{
            type : String,
            default : ''
        },
        headerCount:{
            type : Number,
            default : null
        },
        headerIcon :{
            type : Boolean,
            default : false
        }

    },
    data() {
        return {
            isStockInfo: false
        }
    },
    methods: {
        onClick(){
            this.$emit('onClose')
        }
    }
}
</script>