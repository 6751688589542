var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full h-full"},[_vm._m(0),_c('ul',{staticClass:"!w-full flex-1 -bg-red-300 bg-white dark:!bg-[#1A1A1A] dark:!border-[#6C707A] leading-3 flex flex-col items-center justify-between rounded-[3px] scrollbar overflow-y-auto"},[_c('div',{staticClass:"!w-full -h-full flex flex-col px-4 py-4 -bg-red-300"},_vm._l((_vm.rangeList),function(range,index){return _c('li',{key:index,staticClass:"flex w-full px-5 gap-4 h-[5rem] items-center py-[14px] border border-transparent text-appBtnActiveTxt dark:!text-[#6C707A] rounded-lg md:pl-4",class:[
          _vm.getChartVisibleRange == range.key
          ? 'bg-selectedColor dark:!bg-transparent !text-appBtnTxt border !border-selectedBorder'
                  : 'hover:border-[#e4e4e4] hover:border dark:hover:!bg-[#2D2D2D] !text-appBtnTxt hover:bg-selectedHover  -!border-[transparent]',
                  index == 0 ? 'mt-0' : 'mt-2',

    ],on:{"click":function($event){return _vm.handleSelectRange(range)}}},[_c('div',{staticClass:"text-[16px]"},[_vm._v(_vm._s(range.fullName))])])}),0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full h-[4rem] px-4 flex items-center mt-3 font-[700] text-[16px]"},[_c('span',[_vm._v("Ranges")])])
}]

export { render, staticRenderFns }