<template>
  <dialog-box :modalHeader="header" :open="open" :action="false">
    <div >
      <sui-segment :loading="isLoading" :inverted="themeDarkMode"  v-bind:style="customStyle" :style="themeDarkMode ? {'background-color':'#151515', border:'1px solid #2C2929'}:{}">
        <sui-form >
          <DataSettings :id="id" type="guru" />
          <slot />
          
        </sui-form>  
      </sui-segment>
      
    </div>
    <template v-slot:action>
      <sui-modal-actions class="gurus-action-bar" :style="{...themePrimaryColor,...themeFontColor}">
        <div>
          <sui-button icon="undo" secondary @click="onResetGurus" />
        </div>
        <div>
          <sui-button @click.native="onClose" secondary
          style="
              background-color:white;
              border: 1px solid #555;
              width:80px;
              color: #555;
              "
          >Cancel</sui-button>
          <sui-button
            :disabled="!valid"
            @click.native="stopScan"
            v-if="isLoading"
            style=
                "width: 80px;
                "
          >
            Stop
          </sui-button>
          <sui-button
          style="color:white;width: 80px;"
            :disabled="!valid"
            :style="themeSecondaryColor"
            @click.native="startScan"
            v-if="!isLoading"
          >
            Scan
          </sui-button>
          
        </div>
      </sui-modal-actions>
    </template>
  </dialog-box>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import DialogBox from '@/baseComponents/DialogBox.vue'
import DataSettings from '../DataSettings.vue'

export default {
  name: 'GuruBaseModal',
  components: {
    DialogBox,
    DataSettings
  },
  model: {
    prop: 'open',
    event: 'change'
  },
  props: {
    scanType:{
      type: String,
      default: ""
    },
    id:{
      type: String,
      default: ""
    },
    open: {
      type: Boolean,
      default: false
    },
    header: {
      type: String,
      default: "Guru's Tool"
    },
    valid: {
      type: Boolean,
      default: false
    },
    customStyle: {
      type: String,
      default: 'height: 25vh;'
    },
    isLoading: { type: Boolean, default: false }
  },
  mounted(){
    // let guruScan=this.userData?.GuruRequests?? null;
    // console.log("guruScan mounted",guruScan)
    // // let guruScan= localStorage.getItem("GuruRequests")
    // if(guruScan){
    //   let scan= JSON.parse(guruScan);
    //   if(scan[this.scanType]){
    //     const payload={
    //       id:this.id,
    //       settings:scan[this.scanType]
    //     }
    //       console.log("scan result==>",payload)
    //     this.$store.commit("UPDATE_LATEST_GURU_SETTINGS",payload)
    //     const value = scan[this.scanType]?.settings?.selectedMarket;
    //     console.log("scan result==> selectedMarket",value)
    //     this.$store.dispatch('setSelectedMarketNew',{id:this.id,value: value,type:"guru"})
    //     }
    // }
    // this.handleUpdateLatestGuru()
  },
  watch:{
    userData(n){
      if(n){
        this.handleUpdateLatestGuru()
      }
    }
  },
  methods: {
    ...mapActions(['resetGurusSettings']),
    
    handleUpdateLatestGuru() {
      let guruScan=this.userData?.GuruRequests?? null;
    // let guruScan= localStorage.getItem("GuruRequests")
    console.log("guruScan mounted",JSON.parse(this.userData?.ScanRequests))
    if(guruScan){
      let scan= JSON.parse(guruScan);
      if(scan[this.scanType]){
        const payload={
          id:this.id,
          settings:scan[this.scanType]
        }
          // console.log("scan result==>",payload)
        this.$store.commit("UPDATE_LATEST_GURU_SETTINGS",payload)
        const value = scan[this.scanType]?.settings?.selectedMarket;
        // console.log("scan result==> selectedMarket",value)
        this.$store.dispatch('setSelectedMarketNew',{id:this.id,value: value,type:"guru"})
        }
    }
    },
    onClose() {
      this.$emit('onClose')
    },
    stopScan() {
      this.$emit('stop')
    },
    startScan() {
      this.$emit('ok')
    },
    onResetGurus() {
      this.resetGurusSettings()
    }
  },
  computed:{
    ...mapGetters(['getUserSettingData'
      ,'themePrimaryColor'
      ,'themeSecondaryColor'
      ,'themeDarkMode'
      ,'themeFontColor','userData']),
  }
}
</script>

<style >
.gurus-action-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>
