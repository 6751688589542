<template>
    <div class="w-full h-full xxs:mt-[-3px] flex flex-col">
      <div v-if="isHeader" class="w-full h-[5rem] border-b">
        <DropDownHead 
          @ShowNavDropDownClose="ShowNavDropDownClose" 
          :headerName="headerName"
          />
      </div>
      <div class="w-full !h-[calc(100%-10rem)] -bg-blue-300 overflow-hidden">
        <div v-if="btnName == 'chartType'" class="w-full h-full -bg-green-300">
          <ChartTypeVue @handleSelectChartType="ShowNavDropDownClose" />
        </div>
  
        <div v-if="btnName == 'interval'" class="w-full h-full">
          <IntervalVue
            @handleSelectInterval="ShowNavDropDownClose"
            @timeFrameEmit="handleSelectInterval"
          />
        </div>
  
        <div v-if="btnName == 'range'" class="w-full h-full">
          <RangeVue
            @handleSelectRange="ShowNavDropDownClose"
            @getTimeStamp="handleSelectRange"
            @closeRange="ShowNavDropDownClose"
          />
        </div>
  
        <div v-if="btnName == 'navmenu'" class="w-full h-full">
          <NavBtnInMob
            @handleSelectRange="ShowNavDropDownClose"
            @openRightBarFromMob="handleSelectRightBarData"
            @closeRange="handleClose"
            @ShowNavDropDownClose="ShowNavDropDownClose"
            @trasferData="trasferData"
          />
        </div>
      </div>
  
      <div class="w-full -h-[5rem] bg-white px-8 -border-t">
        <!-- <DropDownFooter
          firstTitle="Close"
          colorType="color"
          @close="ShowNavDropDownClose"
        /> -->
      </div>
    </div>
  </template>
  <script>
  import ChartTypeVue from './navDropDown/ChartTypeVue.vue'
  import DropDownHead from './navDropDown/DropDownHead.vue'
  import IntervalVue from './navDropDown/IntervalVue.vue'
  import RangeVue from './navDropDown/RangeVue.vue'
  import NavBtnInMob from './navDropDown/navBtnInMob.vue'
import { chartEvents } from '../chartEvents'
  // import DropDownFooter from './navDropDown/DropDownFooter.vue'
  
  export default {
    name: 'App',
    data() {
      return {
        headerName: 'Menu',
        isHeader: true,
      }
    },
    components: {
      ChartTypeVue,
      DropDownHead,
      IntervalVue,
      RangeVue,
      NavBtnInMob,
      // DropDownFooter,
    },
    methods: {
      ShowNavDropDownClose() {
        this.$emit('ShowNavDropDownClose')
      },
      handleSelectInterval(name) {
        // this.$emit('handleSelectInterval', name)
      },
  
      handleSelectRange(name) {
        // this.$emit('handleSelectRange', name)
      },
  
      handleSelectRightBarData(name) {
        // this.$emit('openRightBarFromMob', name)
        console.log('openRightBarFromMobName', name)
      },
  
      handleClose() {
        this.$emit('closeRange')
      },

      trasferData(item){
        console.log('trasferData', item.btn)
        this.headerName = item.btn
      },
      
      ToggaleHeader(){
          console.log('ToggaleHeader')
          this.isHeader = !this.isHeader
      }

    },
    computed: {},
    props: {
      btnName: String,
    },
    mounted() {},
    watch: {},
    created() {
      chartEvents.$on('triggerIndicatorInMobileModalHeader', this.ToggaleHeader)
    },
    beforeDestroy() {
      chartEvents.$off('triggerIndicatorInMobileModalHeader')
    },
  }
  </script>
  <style scoped></style>
  