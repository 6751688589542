<template>
   <div class="w-full h-[100vh] overflow-y-scroll pb-[8rem] scrollbar">
    <RightBar/>
    <!-- jjjj -->
   </div>
</template>

<script>
import RightBar from '../../ResponsiveRightbar.vue';
export default {
    name: 'ScannerResultOption',
    components: { RightBar },
    data() {
        return {
        }
    },
    methods: {
    },
}
</script>