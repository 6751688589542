export function debounce(func, timeout = 300) {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
}

export function changeScaneToaggle() {
    let res = JSON.parse(localStorage.getItem("rightBar"))
    res.scanResult = true
    localStorage.setItem("rightBar", JSON.stringify(res))

}