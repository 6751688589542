<template>
    <dialog-box
      modalHeader=""
      :open="open"
      :action="false"
      :scrolling="false"
      
      
      class="radius:[0px]"
    >
  <div class="flex flex-col gap-3 h-full">
    <!--    New Modal Hearder start -->
    <div
      class="w-full h-full flex items-center justify-between py-2 pl-2 border-b dark:border-[#2D2D2D]!important border-[#E2E8F0]!important"
    >
      <div>
        <span class="md:text-[18px] font-sans font-[600]">Symbol lookups</span>
      </div>
  
      <div
        @click="closeModal"
        class="cursor-pointer p-[8px] rounded-md hover:!bg-appBtnHover dark:!bg-[#FFFFFF1A] dark:hover:!bg-[#2D2D2D]"
      >
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 18 18"
           class="h-[18px] w-[18px]"
          >
            <path
              stroke="currentColor"
              stroke-width="1.2"
              d="m1.5 1.5 15 15m0-15-15 15"
            ></path>
          </svg>
  
      </div>
    </div>
  
    <!--    New Modal Hearder end -->
  
    <div class="xxs:h-auto!important md:!h-[6vh]!important md:py-1!important -bg-green-500">
            <span
              class="text-gray-400 xxs:!leading-[10px] xxs:!text-[12px] md:!text-[13px] !font-normal"
            >Enter the stock symbol or company name to search for real-time data
              and trading information. For example, type 'AAPL' for Apple Inc. or
              'GOOGL' for Alphabet Inc.</span
            >
    </div>
  
    <!--  New Searchbar Start  -->
    <div
      class="flex items-center xxs:h-auto md:!h-[4vh] shrink-0 w-full space-x-1 -bg-red-600 dark:hover:bg-[#23232370] dark:!border-[#2D2D2D] qm:mt-0 xxs:mt-4 px-2 py-1.5 text-sm hover:bg-appBtnHover dark:bg-[#121212] bg-[#F6F6F6] rounded-xl"
      :class="
              themeDarkMode ? '!border-[#1a1a1a] border' : 'border border-[#D1D1D1]'
            "
    >
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.16667 15.8333C12.8486 15.8333 15.8333 12.8486 15.8333 9.16667C15.8333 5.48477 12.8486 2.5 9.16667 2.5C5.48477 2.5 2.5 5.48477 2.5 9.16667C2.5 12.8486 5.48477 15.8333 9.16667 15.8333Z"
          stroke="#6D6D6D"
          stroke-width="1.7"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M17.5 17.5L13.875 13.875"
          stroke="#6D6D6D"
          stroke-width="1.7"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
  
      <input
        v-model="filteredtext"
        v-on:keyup="keyUpSearch"
        class="bg-transparent text-[#181818] uppercase dark:text-[#ffffff80] placeholder:text-[#6D6D6D] placeholder:dark:text-[#6D6D6D] placeholder:text-[14px] text-[16px] focus:outline-none w-full"
        placeholder="Symbol, eg: AAPL"
      />
      <div class="flex items-center space-x-3">
  
        <svg
          width="2"
          class="text-[#E7E7E7] dark:text-[#7C7C7C]"
          height="28"
          viewBox="0 0 2 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="2" height="28" fill="currentColor" />
        </svg>
  
        <div @click="clearSymbolSearch" class="text-[#7C7C7C] cursor-pointer text-[14px]">
          Clear
        </div>
      </div>
    </div>
    <!--  New Searchbar End  -->
  
    <!--  New Market Section Start  -->
    <div
      class="w-full flex h-[10%] items-center mt-1 space-x-3 px-2 overflow-x-auto no-scrollbar"
    >
      <div
        v-for="(metadata, i) in markets"
        :key="metadata.id"
        v-show="metadata.show"
        className="flex items-center"
        @click="changeMarketState(i, 'markets', 'showCategory')"
      >
        <div
          :ref="`marketDiv_${metadata.code}`"
          class="flex p-1.5 w-fit px-4 rounded-3xl xxs:!text-[12px] sm:!text-[14px] suggestionText whitespace-nowrap font-[400]"
          :class="
                    metadata.selected
                      ? 'text-[#F6F6F6] cursor-default bg-[#292929] font-[500]'
                      : 'bg-[#F6F6F6] cursor-pointer hover:bg- dark:hover:bg-[#232323]  dark:bg-[#1a1a1a] dark:text-[#ffffff60] text-[#292929]'
                  "
        >
          {{ metadata.desc }}
        </div>
      </div>
    </div>
    <!--  New Market Section End  -->
   
    <!-- watch List symbols -->
    <div class="py-[4px] flex gap-3 w-full overflow-x-auto no-scrollbar">
        
        
      

        <div
              v-for="(item, i) in watchListSymbol"
              :key="i"
              className="flex items-center"
            >
              <div
                class="flex gap-3 py-1.5 w-fit hover:bg-appBtnHover dark:bg-[#1a1a1a] dark:text-[#ffffff60] bg-[#F6F6F6] px-4 rounded-3xl text-[14px] suggestionText whitespace-nowrap font-[400]"
              >
                <span
                  class="text-[#292929] xxs:text-[12px] sm:text-[14px] dark:text-[#ffffff] cursor-default"
                  >{{ item?.name }}</span
                >
                <span
                  @click="removeSymbol(item)"
                  class="cursor-pointer mr-[-6px]"
                  
                >
                <svg width="21" height="21" class="text-[#4C4C4C] dark:text-[#7f8490]" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.01754 12.2176C4.80665 12.4284 4.80665 12.7703 5.01754 12.9812C5.22842 13.1921 5.57033 13.1921 5.78121 12.9812L5.01754 12.2176ZM9.38119 9.38119C9.59208 9.1703 9.59208 8.82844 9.38119 8.61756C9.1703 8.40667 8.82844 8.40667 8.61756 8.61756L9.38119 9.38119ZM8.61756 8.61756C8.40667 8.82844 8.40667 9.1703 8.61756 9.38119C8.82844 9.59208 9.1703 9.59208 9.38119 9.38119L8.61756 8.61756ZM12.9812 5.78121C13.1921 5.57033 13.1921 5.22842 12.9812 5.01754C12.7703 4.80665 12.4284 4.80665 12.2176 5.01754L12.9812 5.78121ZM9.38119 8.61756C9.1703 8.40667 8.82844 8.40667 8.61756 8.61756C8.40667 8.82844 8.40667 9.1703 8.61756 9.38119L9.38119 8.61756ZM12.2176 12.9812C12.4284 13.1921 12.7703 13.1921 12.9812 12.9812C13.1921 12.7703 13.1921 12.4284 12.9812 12.2176L12.2176 12.9812ZM8.61756 9.38119C8.82844 9.59208 9.1703 9.59208 9.38119 9.38119C9.59208 9.1703 9.59208 8.82844 9.38119 8.61756L8.61756 9.38119ZM5.78121 5.01754C5.57033 4.80665 5.22842 4.80665 5.01754 5.01754C4.80665 5.22842 4.80665 5.57033 5.01754 5.78121L5.78121 5.01754ZM5.78121 12.9812L9.38119 9.38119L8.61756 8.61756L5.01754 12.2176L5.78121 12.9812ZM9.38119 9.38119L12.9812 5.78121L12.2176 5.01754L8.61756 8.61756L9.38119 9.38119ZM8.61756 9.38119L12.2176 12.9812L12.9812 12.2176L9.38119 8.61756L8.61756 9.38119ZM9.38119 8.61756L5.78121 5.01754L5.01754 5.78121L8.61756 9.38119L9.38119 8.61756Z" fill="currentColor"/>
            </svg>
            </span>
              </div>
            </div>



    </div>
    <!-- watch List symbols -->


    <!--  New Alphabet section start  -->
    <div
      class="w-full flex h-[10%] items-center space-x-3 px-2 overflow-x-auto no-scrollbar"
    >
      <div
        v-for="(metadata, i) in categoryByMarket"
        :key="metadata.id"
        className="flex items-center"
        v-show="showCategory && metadata.show"
        @click.prevent="
                  updateContract(i, metadata.desc, 'category', 'showContracts')
                "
      >
        <div
          :ref="`categoryDiv_${metadata.desc}`"
          class="flex p-1.5 w-fit px-4 rounded-3xl xxs:!text-[12px] sm:!text-[14px] suggestionText whitespace-nowrap font-[400]"
          :class="[
                    metadata.selected
                      ? 'text-[#F6F6F6] cursor-default bg-[#292929] font-[500]'
                      : 'bg-[#F6F6F6] cursor-pointer hover:bg-[#e2e2e2] dark:hover:bg-[#232323]  dark:bg-[#1a1a1a] dark:text-[#ffffff60] text-[#292929]',
                      metadata.isHidden ? 'hidden':'block'
                    ]
                  "
  
        >
          {{ metadata.desc }}
  
        </div>

        
        </div>
        
      </div>
    </div>
    <!--  New Alphabet section End    -->
  
    <div
  
      class="flex flex-col  h-[60vh] p-4 text=[13.5] overflow-y-auto scrollbar"
    >
  
  
      <!--  New Searched Symbol section Start    -->
      <div
        v-for="(symbol, i) in symbolData"
        :key="i"
        @click="selectSymbol(symbol, i)"
        class="flex items-center justify-between cursor-pointer px-2 dark:hover:!bg-[#2D2D2D30] hover:bg-[#E4E4E460] border-b dark:border-b-[#2D2D2D] py-5 border-b-[#E7E7E7]"
        :style="
                            symbol.selected
                              ? {
                                  cursor: 'pointer',
                                  color: 'white',
                                  ...themeSecondaryColor,
                                }
                              : {
                                  cursor: 'pointer',
                                  ...themeFontColor,
                                //   padding: '5px',
                                }
                          "
      >
        <div class="flex items-start sm:space-x-4 space-x-1">
          <div>
            <div
              class="w-[40px] h-[40px] flex uppercase  items-center dark:bg-[#676868] text-[white] dark:text-[#f6f6f6] justify-center rounded-full bg-[#6B7280]"
            >
              <!--              <TickerIcon :ticker="items.symbol" />-->
              <img v-if="symbol.symbol.indexOf('_') == -1" :src="`https://logo.synthfinance.com/ticker/${symbol.symbol?.split('.')[0]}`" class="rounded-full w-full h-full object-contain" />
              <span v-else>{{ symbol.symbol.slice(0,1)}}</span>
            </div>
          </div>
          <div class="dark:text-[#F6F6F6]">
            <div
              class="font-[300] text-[1.3rem]"
              :title="symbol?.companyName"
            >
              {{ symbol.symbol }}
              <span class="!font-normal dark:text-[#ffffff90] text-[14px]">{{
                  getCompanyName(symbol)
                }}</span>
            </div>
            <!--            <div class="text-[#BDBDBD] mt-1 sm:text-[14px]">-->
            <!--              {{ items?.exchange }}-->
            <!--            </div>-->
            <div class="flex sm:items-center sm:space-x-5">
          <div>
            <div class="font-[600] dark:text-white priceText">
              {{ symbol?.close ? `$ ${symbol?.close?.toFixed(2)}` : '$0'
              }}<span
              :class=" symbol?.change < 0 ?'text-errorText':' text-[#037950]'"
              class="text-[12px] changeText ml-1 font-[500]"
            >
                         {{symbol?.change > 0 ? '+' : ''}}{{ symbol?.change?.toFixed(2) || '0' }}
                          {{
                symbol?.changePercent
                  ? `(${symbol.changePercent?.toFixed(2)}%)`
                  : '(0.0%)'
              }}</span
            >
            <span class="dark:text-[#ffffff90] text-[12px] font-[400]">
                {{selectedMarket}}
            </span>
            </div>
  
          </div>
        </div>
          </div>
        </div>
        <div class="flex sm:items-center sm:space-x-5">
            <svg class="h-[30px] w-[30px]" :class="getColor(symbol) ? 'fill-[#41901C] ':'fill-[#BCBCBC]' " viewBox="0 0 30 30"  xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M15 30C7.92893 30 4.39339 30 2.1967 27.8032C-2.38419e-07 25.6066 0 22.071 0 15C0 7.92893 -2.38419e-07 4.39339 2.1967 2.1967C4.39339 -2.38419e-07 7.92893 0 15 0C22.071 0 25.6066 -2.38419e-07 27.8032 2.1967C30 4.39339 30 7.92893 30 15C30 22.071 30 25.6066 27.8032 27.8032C25.6066 30 22.071 30 15 30ZM15 9.375C15.6213 9.375 16.125 9.87868 16.125 10.5V13.875H19.5C20.1213 13.875 20.625 14.3787 20.625 15C20.625 15.6213 20.1213 16.125 19.5 16.125H16.125V19.5C16.125 20.1213 15.6213 20.625 15 20.625C14.3787 20.625 13.875 20.1213 13.875 19.5V16.125H10.5C9.87868 16.125 9.375 15.6213 9.375 15C9.375 14.3787 9.87868 13.875 10.5 13.875H13.875V10.5C13.875 9.87868 14.3787 9.375 15 9.375Z"/>
            </svg>

        </div>
      </div>
      <!--  New Searched Symbol section End    -->
  
  
  
    </div>
       <!-- footer -->
       <div class="flex justify-end">
           <div>
               <sui-button @click.native="$emit('onClose')">Cancel</sui-button>
               <sui-button   @click.native="SaveWatchList" secondary> Save </sui-button>
           </div>
        </div>
       <!-- footer -->
  </div>
    </dialog-box>
  </template>
<script>
import DialogBox from '@/baseComponents/DialogBox.vue'
import { mapActions, mapGetters } from 'vuex';
import { fetchSymbolByName } from '../../../services/symbol';
import { marketsData } from 'Data/markets';
import { chartEvents } from '../../../chartEvents';


const categoryList = [
//   {
//     show: true,
//     id: 0,
//     desc: '0-9',
//     keys: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
//     selected: false,
//   },
  { show: true, id: 1, desc: 'A-B', keys: ['A'], selected: false },
  {
    show: true,
    id: 2,
    desc: 'C-F',
    keys: ['C', 'D', 'E', 'F'],
    selected: false,
  },
  {
    show: true,
    id: 3,
    desc: 'G-M',
    keys: ['G', 'H', 'I', 'J', 'K', 'L', 'M'],
    selected: false,
  },
  {
    show: true,
    id: 4,
    desc: 'N-R',
    keys: ['N', 'O', 'P', 'Q', 'R'],
    selected: false,
  },
  {
    show: true,
    id: 5,
    desc: 'S-Z',
    keys: ['S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
    selected: false,
  },
]
const localMarket = marketsData.map((m) => {
  return { ...m, show: true, categoryList }
})

const getLocalMarketClone = () => {
  return JSON.parse(JSON.stringify(localMarket))
}


export default {
    name: 'AddSymbolsWatchList',
    components: {DialogBox},
    model: {
    prop: 'open',
    event: 'change',
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  
  data() {
    return {
      cannotUpdateSymbol: true,
      searchMode: false,
      activateSearch: true,
      loading: false,
      filtered2: [],
      filteredtext: '',
      selectedMarket: 'ASX',
      selectedRange: 'A',
      selectedSymbol: '',
      watchListSymbol:[],
      markets: getLocalMarketClone(),
      category: JSON.parse(JSON.stringify(categoryList)),
      symbolData: [
        // { code: 'AAF', name: 'Symbol Name', selected: false },
      ],
      userData: {},
      multiItems: 0,
      changesNotSaved: false,
      showCategory: true,
      showContracts: false,
      theme_type: 1,
      selected_theme: 1,
      darkBackColor: 'white',
      darkFontColor: 'black',
      darkBorderColor: '#eee',
      isAdd:true
    }
  },

  methods: {
    ...mapActions(['toggleShowAddSymbolModal']),

    selectSymbol(symbol, i) {
      // console.log('selectSymbol', symbol, i)
        this.isAdd = true
        let payload ={
            id : symbol.exchange,
            name : symbol.symbol,
            close : symbol.close,
            change : symbol.change,
            changePercent : symbol.changePercent
        }

       if(this.watchListSymbol.length == 20) {
        alert("You can't add more than 20 symbols") 
        return
       }
       this.watchListSymbol.push(payload)




    },
    getColor(item) {
      let isPresent = this.watchListSymbol?.some(
        (val) => val?.name == item?.symbol
      )
      if (isPresent) {
        return true
      } else {
        return false
      }
    // return true
    },
    SaveWatchList(){
        
        this.isAdd = true
        this.watchListSymbol = []
        // console.log("save", this.watchListSymbol, this.isAdd) 
        this.toggleShowAddSymbolModal()
        chartEvents.$emit('selectSymbolAddInWatchList', this.watchListSymbol)
    },
    keyUpSearch(e) {
      const ignoredKeyCodes = [13,35, 36, 37, 38, 39, 40, 16, 17]
      const { keyCode, target } = e
      const searchText = target.value.trim()
      if (searchText === '') {
        if (this.searchMode) {
          this.filteredText(searchText)
        }
        this.searchMode = false
        return
      } else {
        this.searchMode = true
      }

      if (ignoredKeyCodes.indexOf(keyCode) > -1) {
        return
      }
      this.activateSearch = true
      this.filteredText(searchText)
    },
    async onSaveSettings() {
      // this.$emit('change', !open)

      this.onClose()
      this.$emit(
        'selectSymbolModalData',
        this.selectedSymbol + '~' + this.selectedMarket
      )
    },
    getSymbolName(symbol) {
      let res = ''
      let symbolName = symbol.symbol
      if (symbolName) {
        let symbolNameParts = symbolName.split('.')
        if (symbolNameParts.length) {
          res = symbolNameParts[0]
        }
      }
      return res
    },
    getCompanyName(symbol) {
      let res = ''
      let companyName = symbol.companyName
      if (companyName) {
        if (companyName.length > 30) {
          res = `${companyName.substring(0, 30)} ...`
        } else {
          res = companyName
        }
      }
      return res
    },
    onClose() {
    //   this.$emit('change', !open)
    this.isAdd = true
    this.watchListSymbol = []
    this.toggleShowAddSymbolModal()
    },
    closeModal() {
      if (this.changesNotSaved) {
        this.$store.commit('RESET_CHART_SETTINGS', {
          chartSettings: this.defaultChartSettings,
        })
        this.$store.commit('UPDATE_CHART_STATE', new Date().getTime())
      }
      this.clearSymbolSearch()
      // this.settings = this.defaultIndicatorSettings
      this.onClose()
    },
    removeSymbol(item){
        this.isAdd = false
        let result = this.watchListSymbol.filter( sym => sym.name !== item.name)
        this.watchListSymbol = [...result]
        // console.log(item, this.watchListSymbol, result);

        
    },
    changeMarketState(id, prop, changeProp) {
      // console.log("changeMarketState",id, prop, changeProp)
      this[prop] = this[prop].map((v, i) => {
        if (i === id) {
          v.selected = true
        } else {
          v.selected = false
        }
        return v
      })
      this[changeProp] = true
      this.selectedMarket = this[prop][id].code
      this.symbolData = []
      let filterMarket = this.markets.find(
        (m) => m.code === this.selectedMarket
      )
      if (
        filterMarket &&
        filterMarket.categoryList &&
        filterMarket.categoryList.length
      ) {
        let selectedRange = filterMarket.categoryList[0].desc
        // console.log("selectedRange After Market Select")
        let divRefs = this.$refs[`categoryDiv_${selectedRange}`]
        if (divRefs && divRefs[0]) {
          divRefs[0].click()
        }
      }

      // this.resetCat()
      // if(market)
    },
    async updateContract(id, desc) {
      // this.changeState(id, 'category', 'showContracts')
      //  updateText
      this.selectedRange = desc
      let allMarkets = this.markets
      if (allMarkets && allMarkets.length) {
        let selectedMarket = allMarkets.find(
          (m) => m.code === this.selectedMarket
        )
        if (selectedMarket) {
          let categoryList = selectedMarket.categoryList
          categoryList = categoryList.map((v, i) => {
            if (i === id) {
              v.selected = true
            } else {
              v.selected = false
            }
            return v
          })
          selectedMarket.categoryList = categoryList
          // this.markets =allMarkets.map( m => {
          //   if(m => m.code === this.selectedMarket){
          //     // return {...m,categoryList}
          //     m.categoryList = categoryList
          //   }
          //   return m
          // })
        }
      }

      if (this.filteredtext && this.filteredtext.length) {
        this.activateSearch = false
      }
      await this.filteredText(desc, false)
    },
    clearSymbolSearch() {
      this.filteredtext = ''
      this.filtered2 = []
      this.symbolData = []
      this.activateSearch = true
      this.markets = getLocalMarketClone()
      // this.category= JSON.parse(JSON.stringify(categoryList))
      this.selectedMarket = 'ASX'
      this.selectedRange = '0-9'
      this.filteredText('1', false)
    },
    async filteredText(text, updateText = true) {
        
      let isRange = !updateText
      // console.log(this.activateSearch)
      if (this.activateSearch) {
        this.loading = true
        let filtered2 = await fetchSymbolByName(
          this,
          text,
          false,
          isRange,
          isRange ? this.selectedMarket : '',
          true,
          true
        )
        if (typeof filtered2 !== 'string') {
          this.filtered2 = filtered2
          this.loading = false
          this.filterData(updateText)
        } else {
          let textIsNotExist = text === null || text.length === 0
          if (textIsNotExist) {
            this.loading = false
            this.clearSymbolSearch()
          }
        }
      } else {
        this.filterData(updateText)
      }

      // console.log(JSON.stringify(filtered2))
    },

    transformData(data) {
    let result = [];

    for (let exchange in data) {
        let innerKeys = Object.keys(data[exchange]); // Exchange ke andar jo bhi key hai usko find karo
        innerKeys.forEach(key => {
            if (Array.isArray(data[exchange][key])) {
                result.push(...data[exchange][key]);
            }
        });
    }

    return result;
},
sortData(data, text) {
    return data.sort((a, b) => {
        if (a.symbol === text) return -1; // Exact match comes first
        if (b.symbol === text) return 1;
        if (a.symbol.startsWith(text) && !b.symbol.startsWith(text)) return -1; // Prefix match comes first
        if (!a.symbol.startsWith(text) && b.symbol.startsWith(text)) return 1;
        return a.symbol.length - b.symbol.length; // Shorter symbols come first
    });
},
groupByExchange(data) {
    return data.reduce((acc, item) => {
        const firstLetter = item.symbol[0]; // Pehla letter nikalna
        
        if (!acc[item.exchange]) {
            acc[item.exchange] = {}; // Exchange key create karna
        }
        
        if (!acc[item.exchange][firstLetter]) {
            acc[item.exchange][firstLetter] = []; // First letter array create karna
        }

        acc[item.exchange][firstLetter].push(item); // Data push karna
        
        return acc;
    }, {});
},

    filterData(updateText) {
      let filterData = this.filtered2
      if(this.filteredtext){
     let v = this.transformData(filterData)      
     let c = this.sortData(v, this.filteredtext)
     let f = this.groupByExchange(v)
     filterData = f
    //  console.log("filterData--->",this.filteredtext, c, f)
 
    }




      // console.log("filterData--->",filterData)
      if (filterData) {
        let selectedRange = this.category.find(
          (d) => d.desc === this.selectedRange
        )
        let selectedMarketData = filterData[this.selectedMarket]
        let filterMarkets = []
        let firstMarketKey
        let firstCategory
        if (updateText) {
          let marketKeys = Object.keys(filterData)
          if (marketKeys.length) {
            for (const marketKey of marketKeys) {
              let marketData = filterData[marketKey]
              if (!firstMarketKey) firstMarketKey = marketKey
              let keys = Object.keys(marketData)

              let localMarketClone = getLocalMarketClone()
              let filterMarket = localMarketClone.find(
                (m) => m.code === marketKey
              )
              if (filterMarket) {
                let categoryList = filterMarket.categoryList
                let filteredCatList = categoryList.filter((m) => {
                  let categoryKeys = m.keys
                  // let result = categoryKeys.includes(keys)
                  let result = categoryKeys.find((element) =>
                    keys.includes(element)
                  )
                  // console.log({categoryKeys,keys,result})
                  return result
                })
                // filteredCatList = Object.assign({},filteredCatList)
                // console.log("category Keys",marketKey,keys,filteredCatList.length)
                // console.log("filteredCatList for market",filteredCatList.length)
                if (filteredCatList.length) {
                  filterMarket.categoryList = filteredCatList
                  firstCategory = filteredCatList[0].desc
                }

                filterMarkets.push(filterMarket)
              }
            }
          }

          // console.log("marketKeys",marketKeys)

          // categoryList.filter(c => )
          // console.log("filterMarkets",filterMarkets)
          this.markets = filterMarkets

          // if(firstCategory)
          // this.selectedRange = firstCategory
        }
        // console.log("set Data with lenght ",{
        //   selectedRangeProp:this.selectedRange,
        //   selectedMarketData,
        //   firstMarketKey,
        //   firstCategory,
        //   selectedRange
        // })
        let alphaCategoryData = []
        if (selectedRange) {
          let keys = selectedRange.keys
          for (const selectedMarketDataKey in selectedMarketData) {
            // console.log("selectedMarketDataKey",selectedMarketDataKey)
            if (keys.includes(selectedMarketDataKey)) {
              alphaCategoryData.push(
                ...selectedMarketData[selectedMarketDataKey]
              )
            }
          }
        }

        this.symbolData = alphaCategoryData
        this.showCategory = true
        this.showContracts = true

        // console.log("firstMarketKey",firstMarketKey)
        if (firstMarketKey) {
          this.selectedMarket = firstMarketKey
          setTimeout(() => {
            this.selectMarketRef(this.selectedMarket)
          }, 300)
        }
      }
    },
    symbolChange(id, obj) {
      this.selectedSymbol = obj.symbol
      this.symbolData = this.symbolData.map((v, i) => {
        if (i === id) {
          v.selected = true
          this.cannotUpdateSymbol = false
        } else {
          v.selected = false
        }
        return v
      })
      this.selectedMarket = 'ASX'
      this.onSaveSettings()
    },
    selectMarketRef(selectedMarket) {
      let marketRef = this.$refs[`marketDiv_${selectedMarket}`]
      // console.log(`refs updates `,marketRef)
      if (marketRef && marketRef[0]) {
        marketRef[0].click()
      }
    },
    addSymbol(){
        console.log("watchListsData")
        if(this.isAdd){
        this.watchListSymbol = this.watchListsData
        }
    },


  },
  async mounted() {
    this.addSymbol()
    // console.log('getUserSettingData -- ',this.getUserSettingData)
    
    
  },
  watch: {
    themeDarkMode(val) {
      if (val) {
        this.darkBackColor = '#505050'
        this.darkFontColor = '#A2A2A2'
        this.darkBorderColor = '#505050'
      } else {
        this.darkBackColor = 'white'
        this.darkFontColor = 'black'
        this.darkBorderColor = '#eee'
      }
    },
    open(n, o) {
      // console.log("watching modal open close",n,o)
      if (n) {
        this.selectMarketRef(this.selectedMarket)
      }
    },

    watchListsData(n, o){
        console.log(n.o)

    }
  },
  computed: {
    ...mapGetters([
      'getUserSettingData',
      'themePrimaryColor',
      'themeSecondaryColor',
      'themeFontColor',
      'themeDarkMode',
      'watchListsData'
    ]),
    categoryByMarket() {
        // this.watchListSymbol = this.watchListsData
        this.addSymbol()

      if (this.markets && this.markets.length) {
        let selectedCategory = this.markets.find(
          (m) => m.code === this.selectedMarket
        )
        // console.log("selectedCategory---->",selectedCategory, this.watchListSymbol)
        return selectedCategory && selectedCategory.categoryList
          ? selectedCategory.categoryList
          : []
      }
      return []
    },

    wathchlistData (){
        // console.log("watchListsData",this.watchListsData)
        this.watchListSymbol = this.watchListsData
        return this.watchListsData

    }
    
    
  },
  
    
}
</script>
<style>
.symbolList {
  display: flex;
  justify-content: space-between;
}

.symbolList button {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  z-index: 100;
  width: 100%;
  font-size: 12px;
}

.symbolList button:hover {
  background-color: #000000;
}

.selected-chart {
  /* background-color: red; */
  color: #ffffff !important;
  border-bottom: 0px !important;
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

.header {
  font-size: 12.3px;
}

.ui.fluid.input > input {
  background-color: v-bind(darkBackColor);
  color: v-bind(darkFontColor);
  border: 1px solid v-bind(darkBorderColor);
}
</style>
