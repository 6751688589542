<template>
    <div v-if="showrighttoolbox" class="overflow-y-auto scrollbar" :class="{ active: isActive }">
      <div class=" h-full flex flex-col gap-1 ">
         <TickerInfo
          :metaData="metaData"
          :tickerInfoData="tickerInfoData"
         />
         <ScannerResult
           :sacnResults="results"
           :resultsCount="scanCount"
           @selectedScanSymbol="selectedScanSymbol"
         />

         <MyWatchList
           :watchListss="watchLists"
           @addSymbolInWatchList="addCurrentCode"
           @rmoveSymbolInWatchList="removeCurrentCode"
           @selectedWatchLists="selectedWatchLists"
           @AddNewWatchList="showCreateWatchList"
           @removeWatchList="showDeleteWatch"
           @editWatchList="showCreateWatchRename"
           @downloadWatchList="downloadWatches"
           @addSymbolInWatchListToPoPUp="addSymbolInWatchListToPoPUp"
           
         />
         
         <!-- <div style="max-width: 100%; padding-left: 0.5em">
       <div class="row">
         <b :style="themeFontColor">Health Report</b>
       </div>
       <div class="row">
         <health-bar-two :percentage="getHealthPct" />
       </div>
     </div> -->
     
     <symbolHealthBar :percentage="getHealthPct" />
         
     
      </div>
    </div>
  </template>
  
  <script>
  import HealthBarTwo from 'BaseComponents/HelthBarTwo.vue'
  import { saveAs } from 'file-saver'
  import moment from 'moment'
  import { mapActions, mapGetters } from 'vuex'
  import DialogBox from '../baseComponents/DialogBox'
  import { TimeFrameMap } from '../constants'
  import { getMarketTime } from '../utils'
  //<<<<<<< HEAD
  import SymbolSearchBox from './SymbolSearchBox.vue'
  import WatchesLoader from './WatchesLoader.vue'
  import Imag from '../../public/images/patterns/ascending_triangle.svg'
  import { chartEvents } from '../chartEvents'
  import { FormatUnixDate } from '../utils/candle'
  import RightBarHeader from './RightBarHeader.vue'
  import TickerInfo from './TickerInfo.vue'
  import ScannerResult from './ScannerResult.vue'
  import MyWatchList from './MyWatchList.vue'
  import symbolHealthBar from './symbolHealthBar.vue'
import { create } from 'd3'
  // import DeleteWatchListSymbolModal from './dialogBoxes/patterns/DeleteWatchListSymbolModal.vue'
  //=======
  //>>>>>>> 36c1366226a27aa1747def882b55450960ac3a02
  export default {
    name: 'RightToolBox',
    components: { HealthBarTwo, DialogBox, WatchesLoader, SymbolSearchBox, Imag ,RightBarHeader, TickerInfo, ScannerResult, MyWatchList, symbolHealthBar},
    beforeDestroy() {
      window.removeEventListener('resize', this.resize)
    },
    data() {
      return {
        hoverColorDrpd: '',
        showDeleteWatchListSymbolModal : false,
        scanSearchImg: {
          // pattern icon
          SaucerBottom: {
            key: 'icon',
            img: '/images/patterns/saucer_bottom.svg',
          },
          DoubleBottom: {
            key: 'icon',
            img: '/images/patterns/double_bottom.svg',
          },
          BullishPennant: {
            key: 'icon',
            img: '/images/patterns/bullish_pennant.svg',
          },
          BearishFlag: {
            key: 'icon',
            img: '/images/patterns/bearish_flag.svg',
          },
          AscendingTriangle: {
            key: 'icon',
            img: '/images/patterns/ascending_triangle.svg',
          },
          DoubleTop: {
            key: 'icon',
            img: '/images/patterns/double_top.svg',
          },
          TriPointResistance: {
            key: 'icon',
            img: '/images/patterns/tri_point_resistance.svg',
          },
          BearishPennant: {
            key: 'icon',
            img: '/images/patterns/bearish_pennant.svg',
          },
          DescendingTriangle: {
            key: 'icon',
            img: '/images/patterns/descending_triangle.svg',
          },
          TriPointSupport: {
            key: 'icon',
            img: '/images/patterns/tri_point_support.svg',
          },
          BullishFlag: {
            key: 'icon',
            img: '/images/patterns/bullish_flag.svg',
          },
  
          // Candle stick icon
  
          Doji: {
            key: 'icon',
            img: '/images/candlesticks/doji.svg',
          },
          InvertedHammer: {
            key: 'icon',
            img: '/images/candlesticks/inverted-hammer.svg',
          },
          BullishEngulfing: {
            key: 'icon',
            img: '/images/candlesticks/bullish-engulfing.svg',
          },
          BullishHarami: {
            key: 'icon',
            img: '/images/candlesticks/bullish-harami.svg',
          },
          BearishEngulfing: {
            key: 'icon',
            img: '/images/candlesticks/bearish-engulfing.svg',
          },
          BearishHarami: {
            key: 'icon',
            img: '/images/candlesticks/bearish-harami.svg',
          },
          Hammer: {
            key: 'icon',
            img: '/images/candlesticks/hammer.svg',
          },
          HangingMan: {
            key: 'icon',
            img: '/images/candlesticks/hanging-man.svg',
          },
  
          // Text / Events code
  
          TCIBreak: {
            key: 'text',
            code: 'TCI ',
          },
          TCITrend: {
            key: 'text',
            code: 'TCI ',
          },
          MacdSetting: {
            key: 'text',
            code: 'MACD ',
          },
          ObvAbove: {
            key: 'text',
            code: 'OBV ',
          },
          VolumeMore: {
            key: 'text',
            code: 'VOL ',
          },
          ProReversalSignal:{
            key: 'text',
            code: 'PRS ',
          },
  
  
          // Text / Indicator code
          Cross: {
            key: 'text',
            code: 'CROSS ',
          },
          Roc: {
            key: 'text',
            code: 'ROC ',
          },
          RSI: {
            key: 'text',
            code: 'RSI ',
          },
          Stoc: {
            key: 'text',
            code: 'STOCK ',
          },
          Bands: {
            key: 'text',
            code: 'BANDS ',
          },
          VolumeIncrease: {
            key: 'text',
            code: 'Vol-Inc ',
          },
          TrendLineCrossing: {
            key: 'text',
            code: 'TX',
          },
  
        },
        scanSearchSymbolName: '',
        scanSearchPatternName: '',
        scanSearchTopText: '',
        scanSearchTopImage: null,
        openDropDownScan: false,
        isActive: false,
        showrighttoolbox: true,
        selectedScan: 'none',
        isStockInfo: true,
        selectedSymbol: {
          patternName: null,
          symbol: '',
        },
        chartWidth: 0,
        chartHeight: 0,
        selectedScanResultIndex: 0,
        watchListItemId: 0,
        watchListMainId: 0,
        counterValue: 0,
        showWatchOpt: false,
        showCreateWatchModal: false,
        results: [],
        scanCount: 0,
      }
    },
    mounted() {
    console.log("this.isTrainingMode",this.isTrainingMode)
    this.setScanResult()
  },
    methods: {
      ...mapActions([
        'exitElementColor',
        'enterThemehoverColor',
        'changeSymbolToScannedSymbol',
        'addDrawingForScannedSymbol',
        'updateIntraDayModeState',
        'setSelectedWatch',
        'addNewSymbolToList',
        'setSelectedWatchCode',
        'removeCurrentSymbolFromLists',
        'toggleWatchesDeleteModal',
        'toggleWatchesImportModal',
        'updateTrainingModeState',
        'updateWatchList',
        'updateWatchListInBulkAmmount'
      ]),
    //   extra
    

   setScanResult() {
       console.log('setScanResult---->', this.scanResults.length)

       if(this.scanResults.length > 0){
     this.results = this.scanResults[this.scanResults.length - 1].results
     this.scanCount = this.scanResults[this.scanResults.length - 1].results.length
       }
       console.log('setScanResult---->', this.results)

   },




   async removeSymbolInWatchListModal(name, index, market){
        console.log("jack---->",name, index, market)
        this.selectedWatchCodeLocal = await this.removeCurrentSymbolFromLists({
          name: name,
          id: market,
          index: index,
        })
    },

    onClose(){
     console.log('onclose')
    },
    selectedScanSymbol(val, index){
      console.log('selectedScanSymbol', val, index)
      this.AssignSymbol(val, index, true)
    },

    selectedWatchLists(){
      console.log('selectedWatchLists')
      this.selectedWatchLocal
    },

      increaseIndex() {
        if (this.$refs?.increasecandleref?.disabled) return
        if (this.isTrainingMode) {
          this.$store.commit('SET_CANDLE_INDEX_UPD_TRAINING', {
            time: null,
            index: this.candleIndexJumpSelected.index + 1,
            chartHit: false,
          })
        } else {
          this.candleIndex++
        }
      },
      decreaseIndex() {
          if (this.$refs?.decreasecandleref?.disabled) return
        if (this.isTrainingMode) {
          this.$store.commit('SET_CANDLE_INDEX_UPD_TRAINING', {
            time: null,
            index: this.candleIndexJumpSelected.index - 1,
            chartHit: false,
          })
        } else {
          this.candleIndex--
        }
      },
      ScanCopyBtn() {
        this.$store.commit('SCAN_COPY_BUTTON', true)
        this.$store.commit('SELECTED_SCAN_ID', this.selectedScan)
      },
      OpenDropDown() {
        this.openDropDownScan = !this.openDropDownScan
        // this.hoverColorDrpd = this.themeSecondaryColor.backgroundColor
        this.hoverColorDrpd = "#f6f6f6"
        // console.log("......ye chala  ", )
      },
      consoleLog() {
      console.log('this.scanResults', this.getCurrentSymbolData)
    },
      AssignSymbol(sym,index,setIndex=false) {

          


        this.selectedSymbol = sym
        //this method is used a lot and not everyone using this method has index
        //setIndex is a check key, false by default only true when select box for symbol list
        //calls this method.
        if(setIndex){
          this.selectedScanResultIndex= index;
        // //   this.selectedScanResults.findIndex((s)=>s.symbol===sym.symbol)
        }
        this.scanSearchTopImage = null
        this.scanSearchTopText = null
        this.scanSearchSymbolName = null
        console.log('AssignSymbol here ', sym, index)   
        if (sym) {
          console.log('AssignSymbol here---> ', sym)
          this.scanSearchSymbolName = sym.symbol
          let patternName = sym.patternName
          if (patternName && patternName.length) {
            let scanSearchImgElement = this.scanSearchImg[patternName]
            let key = scanSearchImgElement?.key
            let isText = key === 'text'
            let isIcon = key === 'icon'
            if (isIcon) {
              this.scanSearchTopImage = scanSearchImgElement.img
            } else if (isText) {
              this.scanSearchTopText = scanSearchImgElement.code
            } else {
              this.scanSearchPatternName = patternName
            }
          } else {
            this.scanSearchPatternName = ''
          }
        }
  
        this.openDropDownScan = false
      },
      showWatchOptions() {
        this.showWatchOpt = !this.showWatchOpt
      },
      showCreateWatchList() {
        // console.log("selectedWatch length ", this.selectedWatch.length)
        this.showWatchOpt = false
        this.showCreateWatchModal = true
        this.$store.dispatch('toggleWatchesModal')
      },
  
      showCreateWatchRename() {
        this.showWatchOpt = false
        this.showCreateWatchModal = true
        this.$store.dispatch('toggleWatchesRenameModal')
        // this.createNewWatchList({ScanResults:this.scanResults})
      },
      showDeleteWatch() {
        this.showWatchOpt = false
        this.toggleWatchesDeleteModal()
      },
      importWatches() {
        this.showWatchOpt = false
        this.toggleWatchesImportModal()
      },
      scanListDownload() {
        let scans = this.getScans()[0]
        let results = scans.results
        let symbolList = this.getSymbolsList
        // symbolList.filter(d=> d.startsWith(scans.results[0].symbol))
        // console.log("scans",results,symbolList)
        let symbolInfoList = []
        for (let result of results) {
          let symbolInfo = symbolList.find((d) => d.startsWith(result.symbol))
          symbolInfoList.push(symbolInfo)
        }
  
        // debugger
        //
        let resData = []
        for (let info of symbolInfoList) {
          let symbolArray = info.split('~')
          if (symbolArray && symbolArray.length === 2) {
            let symbolName = symbolArray[0]
            let marketId = symbolArray[1]
            resData.push(`${symbolName},${marketId}\n`)
          }
        }
        let blob = new Blob(resData, { type: 'text/txt;charset=utf-8' })
        saveAs(
          blob,
          `${scans.scanType.toUpperCase()}-${scans.createdDate.valueOf()}.txt`
        )
      },
      downloadWatches() {
        this.showWatchOpt = false
  
        let selectedWatchData = ''
        for (let i in this.watchLists) {
          if (this.watchLists[i].id === this.selectedWatch) {
            selectedWatchData = this.watchLists[i]
          }
        }
  
        let resData = []
        for (let selectedWatchDatum of selectedWatchData.symbolList) {
          //   s.startsWith({selectedWatchDatum} )
          // )
          // // console.log("symbolFull == ",symbolFull)
          // if (symbolFull) {
          //   }
          resData.push(`${selectedWatchDatum.name},${selectedWatchDatum.id}\n`)
        }
        let blob = new Blob(resData, { type: 'text/txt;charset=utf-8' })
        saveAs(blob, `${this.selectedWatch}.txt`)
      },
      watchSelect(e) {
        console.log(e)
      },
      resize() {
        this.chartWidth = window.innerWidth
        this.chartHeight = window.innerHeight
      },
      getScans() {
        // console.log("this.selectedScan",this.selectedScan)
        return this.scanResults.filter(
          (scan) =>
            `${scan.scanType} - ${scan.timeFrame} - ${scan.date}` ===
            this.selectedScan
        )
      },
      /** @param {number} value */
      formatMillions(value) {
        if (value >= 1000) {
          return (value / 1000.0).toFixed(3) + 'B'
        }
        return value.toFixed(3) + 'M'
      },
      symbolSelectBack() {
        if (this.selectedScanResultIndex <= 0) return
        this.selectedScanResultIndex--
        this.selectedSymbol =
          this.selectedScanResults[this.selectedScanResultIndex]
        this.AssignSymbol(this.selectedSymbol)
      },
      async addCurrentCode() {
        console.log("addCurrentCose----->", this.getCurrentSymbolData)
        let index = await this.addNewSymbolToList({
          name: this.symbolName,
          id: this.getMarketId,
          close: this.getCurrentSymbolData.close,
          change: this.getCurrentSymbolData.change,
          changePercent: this.getCurrentSymbolData.changePercent,
          
        })
        console.log('index', index)
        this.selectedWatchCodeLocal = index
        this.updateWatchListItem()
      },
     async addSymbolInWatchListToPoPUp(symbol){
          // console.log('addSymbolInWatchListToPoPUp', symbol)
          
          let index = await this.updateWatchListInBulkAmmount(symbol)
        console.log('index', index)
        this.selectedWatchCodeLocal = index
        this.updateWatchListItem()
     },

      async goForward() {
        this.selectedWatchCodeLocal = this.selectedWatchCodeLocal + 1
      },
      async goBackward() {
        this.selectedWatchCodeLocal = this.selectedWatchCodeLocal - 1
      },
      async removeCurrentCode() {
        console.log('removeCurrentCode--->',this.symbolName,this.getMarketId,this.selectedWatchCodeLocal)
        this.selectedWatchCodeLocal = await this.removeCurrentSymbolFromLists({
          name: this.symbolName,
          id: this.getMarketId,
          index: this.selectedWatchCodeLocal,
        })
        // this.selectedWatchCodeLocal=''
        // this.updateWatchListItem()
      },
      updateWatchListItem() {
        this.watchListItemId = this.watchListItemId + 1
      },
      symbolSelectNext() {
        if (this.selectedScanResultIndex >= this.selectedScanResults.length - 1)
          return
        this.selectedScanResultIndex++
        this.selectedSymbol =
          this.selectedScanResults[this.selectedScanResultIndex]
        console.log('selected symbol ', this.selectedSymbol)
        this.AssignSymbol(this.selectedSymbol)
      },
      setTimeStampToSplitter(timeStamp, indexBased = false) {
        // console.log("setTimeFrameToSplitter",timeStamp,FormatUnixDate(timeStamp))
        if (indexBased) {
          // Checking For Mouse Clisk Event which always send indexBased Param as true
          const chartData = this.dc.get_one(`chart.data`)
          // debugger
          if (chartData.length && chartData.length >= timeStamp) {
            let candleData = chartData[timeStamp]
            if (candleData && candleData[0]) {
              timeStamp = candleData[0]
            }
          } else {
            return
          }
        }
        const splitters = this.dc.get(`onchart.Splitters`)
        if (splitters) {
          splitters.forEach((splitter) => {
            // console.log("splitter.name",splitter.name)
            if (splitter.name === 'CurrentCandle') {
              return this.dc.set(`${splitter.id}.data`, [[timeStamp]])
            }
          })
        }
      },
      showSymbolScanDetails(symbolName) {
        const { timeFrame, isDrawing, scanType, settings } = this.results
        console.log(
          'OVERLAYS_CHARTS',
          JSON.stringify({ timeFrame, isDrawing, scanType, settings })
        )
        this.changeSymbolToScannedSymbol({
          symbolData: symbolName,
          timeFrame,
          isDrawing,
          scanType,
          settings,
        }).then((d) => {})
      },
      symbolChangeVerifyScanDetails() {
        const scans = this.getScans()
  
        if (
          scans &&
          scans.length > 0 &&
          this.symbolName === this.selectedSymbol?.symbol
        ) {
          const { scanType, settings } = scans[0]
          const symbolData = this.selectedSymbol
          console.log(
            'symbolChangeVerifyScanDetails',
            JSON.stringify({ symbolData, scanType, settings })
          )
          console.log('symbolChangeVerifyScanDetails')
          this.addDrawingForScannedSymbol({ symbolData, scanType, settings })
        }
      },
      deleteSymbolInWatchListToPoPUp(){
        console.log("hello---->")
        this.showDeleteWatchListSymbolModal = true
      }
    },
    computed: {
      ...mapGetters([
        'themeDarkMode',
        'scanCopyBtn',
        'candleIndexSelected',
        'candleIndexJumpSelected',
        'trainingOffset',
        'formattedSymbolData',
        'scanResults',
        'symbolMetaData',
        'symbolName',
        'companyName',
        'getSymbolHealth',
        'dc',
        'isTrainingMode',
        'isIntradayMode',
        'trainingModeStartingIndex',
        'trainingModeStartingIndexSubset',
        'trainingModeDataRaw',
        'trainingModeData',
        'getSymbolData',
        'getMarketId',
        'timeFrame',
        'themeSettings',
        'themePrimaryColor',
        'themeSecondaryColor',
        'themeFontColor',
        'watchLists',
        'showWatchRenameModal',
        'watchListsData',
        'selectedWatch',
        'selectedWatchCode',
        'getSymbolsList',
        'selectedWatchSymbolCode',
        'symbolClassificationInfo',
        'themeDarkMode',
        'selectedWatchData',
        'getCurrentSymbolData'
      ]),
      dropDownColor() {
        return this.themeDarkMode
          ? {
              background: '#505050 !important',
              borderColor: '#505050',
              color: '#A2A2A2',
            }
          : this.themeFontColor
      },
      candleIndex: {
        get() {
          return this.candleIndexSelected?.index
        },
        set(value) {
          this.$store.commit('SET_CANDLE_INDEX_UPD', {
            time: null,
            index: value,
            chartHit: false,
          })
        },
      },
      traningModeIndex: {
        get() {
          return (
            this.trainingModeStartingIndex +
            (this.candleIndexJumpSelected?.index - this.trainingOffset)
          )
        },
        set(value) {
          this.$store.commit('SET_CANDLE_INDEX_UPD_TRAINING', {
            time: null,
            index: value,
            chartHit: false,
          })
        },
      },
      fieldColor() {
        return this.themeDarkMode
          ? {
              background: '#505050 !important',
              borderColor: '#505050',
              color: '#A2A2A2',
            }
          : {}
      },
      metaData() {
        

        return [
          { left: 'Sector', right: this.symbolClassificationInfo?.sector?.length > 27 ? this.symbolClassificationInfo?.sector.slice(0, 24) + '...' : this.symbolClassificationInfo?.sector || 'N/A' },
          {
            left: 'Industry',
            right: this.symbolClassificationInfo?.industry?.length > 27 ? this.symbolClassificationInfo?.industry.slice(0, 24) + '...' : this.symbolClassificationInfo?.industry || 'N/A',
          },
          {
            left: 'Sub Industry',
            right: this.symbolClassificationInfo?.subIndustry?.length > 27 ? this.symbolClassificationInfo?.subIndustry.slice(0, 24) + '...' : this.symbolClassificationInfo?.subIndustry || 'N/A',
          },
          {
            left: 'Issued',
            right: this.symbolMetaData?.issue
              ? this.formatMillions(this.symbolMetaData?.issue / 1000000.0)
              : 'N/A',
          },
          {
            left: 'Market Cap',
            right: this.symbolMetaData?.marketCap
              ? this.formatMillions(this.symbolMetaData?.marketCap)
              : 'N/A',
          },
          {
            left: 'PE Ratio',
            right: this.symbolMetaData?.peRatio?.toFixed(3) || 'N/A',
          },
          {
            left: 'EPS',
            right: this.symbolMetaData?.eps?.toFixed(3) || 'N/A',
          },
          {
            left: 'Earning Yields',
            right:
              this.symbolMetaData?.earningYield?.toFixed(3) == null
                ? 'N/A'
                : this.symbolMetaData?.earningYield?.toFixed(3) + '%',
          },
  
          {
            left: 'Trading Basis',
            right: this.symbolMetaData?.tradingBasis || 'N/A',
          },
          {
            left: 'Dividend Rate',
            right: this.symbolMetaData?.dividendRate?.toFixed(3) || 'N/A',
          },
          {
            left: 'Dividend Yield',
            right:
              this.symbolMetaData?.dividendYield?.toFixed(3) == null
                ? 'N/A'
                : this.symbolMetaData?.dividendYield?.toFixed(3) + '%',
          },
          {
            left: 'Franking %',
            right: this.symbolMetaData?.frankedPct
              ? this.symbolMetaData?.frankedPct?.toFixed(3) + '%'
              : '0%',
          },
          {
            left: '1m Return',
            right:
              this.symbolMetaData?.return1m?.toFixed(3) == null
                ? 'N/A'
                : this.symbolMetaData?.return1m?.toFixed(3) + '%',
          },
          {
            left: '3m Return',
            right:
              this.symbolMetaData?.return3m?.toFixed(3) == null
                ? 'N/A'
                : this.symbolMetaData?.return3m?.toFixed(3) + '%',
          },
          {
            left: '6m Return',
            right:
              this.symbolMetaData?.return6m?.toFixed(3) == null
                ? 'N/A'
                : this.symbolMetaData?.return6m?.toFixed(3) + '%',
          },
          {
            left: '12m Return',
            right:
              this.symbolMetaData?.return12m?.toFixed(3) == null
                ? 'N/A'
                : this.symbolMetaData?.return12m?.toFixed(3) + '%',
          },
          {
          left: 'Open',
          right: this.isTrainingMode
            ? this.formattedSymbolData?.[this.traningModeIndex]?.open?.toFixed(
                3
              )
            : this.formattedSymbolData?.[this.candleIndex]?.open?.toFixed(3),
        },
        {
          left: 'High',
          right: this.isTrainingMode
            ? this.formattedSymbolData?.[this.traningModeIndex]?.high?.toFixed(
                3
              )
            : this.formattedSymbolData?.[this.candleIndex]?.high?.toFixed(3),
        },
        {
          left: 'Low',
          right: this.isTrainingMode
            ? this.formattedSymbolData?.[this.traningModeIndex]?.low?.toFixed(3)
            : this.formattedSymbolData?.[this.candleIndex]?.low?.toFixed(3),
        },
        {
          left: 'Close',
          right: this.isTrainingMode
            ? this.formattedSymbolData?.[this.traningModeIndex]?.close?.toFixed(
                3
              )
            : this.formattedSymbolData?.[this.candleIndex]?.close?.toFixed(3),
        },
        {
          left: 'Volume',
          right: this.isTrainingMode
            ? this.formattedSymbolData?.[
                this.traningModeIndex
              ]?.volume?.toLocaleString()
            : this.formattedSymbolData?.[
                this.candleIndex
              ]?.volume?.toLocaleString(),
        },
        {
          left: 'Value',
          right: this.isTrainingMode
            ? this.formattedSymbolData?.[
                this.traningModeIndex
              ]?.value?.toLocaleString()
            : this.formattedSymbolData?.[
                this.candleIndex
              ]?.value?.toLocaleString(),
        },
        {
          left: 'VWAP',
          right: this.isTrainingMode
            ? this.formattedSymbolData?.[
                this.traningModeIndex
              ]?.vwap?.toLocaleString()
            : this.formattedSymbolData?.[this.candleIndex]?.vwap?.toFixed(3),
        },
        ]
      },

      tickerInfoData (){
        return[
          {
            companyName: this.companyName,
            symbolName: this.symbolName,
            close: this.isTrainingMode
            ? this.formattedSymbolData?.[this.traningModeIndex]?.close?.toFixed(
                3
              )
            : this.formattedSymbolData?.[this.candleIndex]?.close?.toFixed(2),
            volume:this.isTrainingMode
            ? this.formattedSymbolData?.[
                this.traningModeIndex
              ]?.volume?.toLocaleString()
            : this.formattedSymbolData?.[
                this.candleIndex
              ]?.volume?.toLocaleString(),
              change: this.getCurrentSymbolData?.change.toFixed(2) || 0,
              changeInPercent: this.getCurrentSymbolData?.changePercent.toFixed(1) || 0
          }
        ]
      },
      selectedWatchLocal: {
        
        get() {
          console.log("selectedWatchLocal getter")
          return this.selectedWatchCode
        },
        set(value) {
          console.log("selectedWatchLocal setter")
          // console.log("selectedWatchLocal setter",value,this.watchLists[value])
          this.setSelectedWatch(value)
          this.selectedWatchCodeLocal = -1
        },
      },
      selectedWatchCodeLocal: {
        get() {
          return this.selectedWatchSymbolCode
        },
        set(value) {
          // console.log(value,this.watchListsData,this.watchListsData[value])
          if (
            value !== -1 &&
            this.watchListsData[value] &&
            this.watchListsData[value].name
          )
            this.setSelectedWatchCode({
              id: value,
              name: this.watchListsData[value].name,
            })
        },
      },
      isCurrentNodeAlreadyExist() {
        // console.log("isCurrentNodeAlreadyExist ==  ",this.symbolName,this.watchListsData.length)
        if (this.watchListsData.length) {
          let filter = this.watchListsData.filter(
            (f) => f.name === this.symbolName
          )
          // console.log(filter,filter.length)
          return filter.length > 0
        }
        return false
      },
      /** @returns {[String]} */
      selectedScanResults() {
        // console.log('selectedScanResults', this.getScans()[0].results)
        if (this.selectedScan === 'none' || this.selectedScan === '') return []
  
        return this.getScans()[0].results
      },
      /** @returns {Number} */
      maxLength() {
        return this.formattedSymbolData?.length
      },
      /** @returns {String} */
      selectedCandle() {
        return this.isTrainingMode
          ? moment(
              this.formattedSymbolData?.[this.traningModeIndex]?.date
            ).format('Do MMM YYYY')
          : moment(this.formattedSymbolData?.[this.candleIndex]?.date).format(
              'Do MMM YYYY'
            )
      },
      /** @returns {[{left: String, right: String}]} */
      ohlcvData() {
        return [
          {
            left: 'Open',
            right: this.isTrainingMode
              ? this.formattedSymbolData?.[this.traningModeIndex]?.open?.toFixed(
                  3
                )
              : this.formattedSymbolData?.[this.candleIndex]?.open?.toFixed(3),
          },
          {
            left: 'High',
            right: this.isTrainingMode
              ? this.formattedSymbolData?.[this.traningModeIndex]?.high?.toFixed(
                  3
                )
              : this.formattedSymbolData?.[this.candleIndex]?.high?.toFixed(3),
          },
          {
            left: 'Low',
            right: this.isTrainingMode
              ? this.formattedSymbolData?.[this.traningModeIndex]?.low?.toFixed(3)
              : this.formattedSymbolData?.[this.candleIndex]?.low?.toFixed(3),
          },
          {
            left: 'Close',
            right: this.isTrainingMode
              ? this.formattedSymbolData?.[this.traningModeIndex]?.close?.toFixed(
                  3
                )
              : this.formattedSymbolData?.[this.candleIndex]?.close?.toFixed(3),
          },
          {
            left: 'Volume',
            right: this.isTrainingMode
              ? this.formattedSymbolData?.[
                  this.traningModeIndex
                ]?.volume?.toLocaleString()
              : this.formattedSymbolData?.[
                  this.candleIndex
                ]?.volume?.toLocaleString(),
          },
          {
            left: 'Value',
            right: this.isTrainingMode
              ? this.formattedSymbolData?.[
                  this.traningModeIndex
                ]?.value?.toLocaleString()
              : this.formattedSymbolData?.[
                  this.candleIndex
                ]?.value?.toLocaleString(),
          },
          {
            left: 'VWAP',
            right: this.isTrainingMode
              ? this.formattedSymbolData?.[
                  this.traningModeIndex
                ]?.vwap?.toLocaleString()
              : this.formattedSymbolData?.[this.candleIndex]?.vwap?.toFixed(3),
          },
        ]
      },
      /** @returns {Number}} */
      resultsCount() {
        console.log('selectedScanResults mobile----->', this.selectedScanResults, this.scanResults)
        







        return this.selectedScanResults.length > 0
          ? this.selectedScanResults?.length
          : 0
      },
      /** @returns {Number}} */
      getHealthPct() {
        return this.getSymbolHealth
      },
      /** @returns {Number}} */
      metaDataStyle() {
        // let maxHeight = '35%'
        // if (this.chartHeight < 785) maxHeight = '20%'
        return `min-height: 100px; overflow-y: auto;margin-top:0px`
      },
      //  togglebox(){
      //     // return `display : none`
      // },
      /** @returns {boolean}} */
      shouldAllowNextSymbol() {
        return (
          this.resultsCount === 0 ||
          this.selectedScanResultIndex >= this.selectedScanResults.length - 1
        )
      },
      /** @returns {boolean}} */
      shouldAllowBackSymbol() {
        return this.resultsCount === 0 || this.selectedScanResultIndex <= 0
      },
      /** @returns {String} */
      trainingModeStyles() {
        return this.isTrainingMode ? 'background: #029acf' : ''
      },
      /** @returns {Boolean} */
      intradayMode: {
        get() {
          return this.isIntradayMode
        },
        set(val) {
          // console.log("isIntradayMode",this.isIntradayMode,val)
          if (this.isIntradayMode !== val) {
            this.updateIntraDayModeState(val)
          }
        },
      },
      /** @returns {Boolean} */
      isIntradayModeAllowed() {
        const { isMarketOpened } = getMarketTime(this.getMarketId)
        return isMarketOpened
      },
      intradayTooltip() {
        return this.isIntradayModeAllowed ? '' : 'Markets are closed'
      },
      /** @returns {String} */
      timeFrameText() {
        return TimeFrameMap[this.timeFrame]
      },
    },
    updated() {
      //console.log("Hello world: ",this.themePrimaryColor)
      //console.log("Hello world: ",this.themeSecondaryColor)
    },
    watch: {
      themeSecondaryColor() {
        this.hoverColorDrpd = this.themeSecondaryColor.backgroundColor
        // console.log("....themesecondary color is hoverColorDrpd ", this.hoverColorDrpd)
      },
      selectedScan(val) {
        // this.selectedScanResultIndex = 0
        this.selectedSymbol =
          this.selectedScanResults[this.selectedScanResultIndex]
        this.AssignSymbol(this.selectedSymbol)
      },
      scanResults(newVal) {
        if (newVal.length === 0) return
  
        const latest = newVal[newVal.length - 1]
        this.selectedScan = `${latest?.scanType} - ${latest?.timeFrame} - ${latest?.date}`
        if (latest.results.length > 0) {
          this.selectedScanResultIndex = 0
          this.selectedSymbol = latest.results[0]
          this.AssignSymbol(latest.results[0])
        }
        console.log('Darvas Scan Symbol', this.sacnResults)
      },
      selectedSymbol(symbolName) {
        if (!symbolName) return
        console.log('Darvas Scan Symbol', symbolName)
        // this.showSymbolScanDetails(symbolName)
        chartEvents.$emit('selectedsymbolInMobile', symbolName)
      },
      symbolName(newSymbol) {
        // console.log("symbolName",newSymbol,this.selectedSymbol)
      },
      formattedSymbolData(newValue) {
        //TODO:CHANGE_EFFECTS
        if (newValue?.length > 0 && this.maxLength) {
          this.candleIndex = this.maxLength - 1
          const splitters = this.dc.get('onchart.Splitters')
          if (splitters) {
            splitters.forEach((splitter) => {
              if (splitter.name === 'CurrentCandle') {
                return this.dc.set(`${splitter.id}.data`, [
                  [
                    moment(
                      this.formattedSymbolData?.[this.candleIndex]?.date
                    ).valueOf(),
                  ],
                ])
              }
            })
          }
        }
      },
      maxLength(newValue, oldValue) {
        if (!oldValue && newValue && !this.candleIndex) {
          this.candleIndex = newValue - 1
        }
      },
      async candleIndexSelected(newValue, oldValue) {
        // console.log("SET_CANDLE_INDEX candleIndex",newValue,oldValue)
        if (!oldValue) return
  
        if (newValue?.index < this.getSymbolData.length) {
          const timeFrame = this.getSymbolData[newValue?.index][0]
          this.setTimeStampToSplitter(timeFrame)
        }
      },
      async candleIndexJumpSelected(newValue, oldValue) {
        console.log('SET_CANDLE_INDEX traningModeIndex', newValue, oldValue)
        if (!oldValue || !this.isTrainingMode) return
        // let dataVal = this.getSymbolData;
  
        /* console.log("trainingModeStartingIndex",JSON.stringify({
          trainingModeStartingIndex,
          newValue,
          actualData: actualDatum,
          newData: newDatum,
        }))*/
  
        const formattedSymbolData = this.formattedSymbolData
        const symbolData = this.getSymbolData
        let trainingModeStartingIndex = this.trainingModeStartingIndex
        const trainingOffset = this.trainingOffset
        let selectedIndex = newValue?.index
        let selectedIndexOld = oldValue?.index
        const selectedTime = newValue?.time
        let trainingModeDataRaw = JSON.parse(
          JSON.stringify(this.trainingModeDataRaw)
        )
        if (selectedIndex <= trainingOffset) {
          if (selectedTime) {
            // When we are using chart to change data index
            this.setTimeStampToSplitter(selectedTime)
          } else {
            // when we are using arrow button to change data index
            if (selectedIndex >= 0) {
              const timeFrame = trainingModeDataRaw[selectedIndex][0]
              this.setTimeStampToSplitter(timeFrame)
            }
          }
          return
        }
        const selectedFromChart = newValue?.chartHit
        let endPoint = 0
        if (selectedFromChart) {
          let index = 0
          //   find index
  
          for (const dt of this.getSymbolData) {
            if (dt[0] > selectedTime) break
            index++
          }
          endPoint = index
        } else {
          endPoint = trainingModeStartingIndex + (selectedIndex - trainingOffset)
        }
  
        const maximumIndex = symbolData.length
        console.log(
          'trainingModeIndex',
          JSON.stringify({
            selectedIndex,
            selectedIndexOld,
            selectedTime,
            endPoint,
            maximumIndex,
            trainingModeStartingIndex,
            trainingOffset,
          })
        )
        if (endPoint <= maximumIndex && this.isTrainingMode) {
          const trainingModeData = this.trainingModeData
          const oldIndexStart = selectedIndexOld + 1
          let startPoint =
            trainingModeStartingIndex + (oldIndexStart - trainingOffset)
          if (selectedIndex > selectedIndexOld) {
            //this.getSymbolData[newValue]
  
            if (selectedFromChart) {
              let sliceData = symbolData.slice(startPoint, endPoint)
              let slicedDataIndex = 0
              for (let i = oldIndexStart; i <= selectedIndex; i++) {
                trainingModeDataRaw[i] = sliceData[slicedDataIndex]
                slicedDataIndex++
              }
              console.log(
                JSON.stringify({ sliceData, oldIndexStart, selectedIndex })
              )
            } else {
              trainingModeDataRaw[selectedIndex] = symbolData[startPoint]
            }
            trainingModeDataRaw = trainingModeDataRaw.map((candle) => [
              candle[0],
              candle[1] ? candle[1] : NaN,
              candle[2] ? candle[2] : NaN,
              candle[3] ? candle[3] : NaN,
              candle[4] ? candle[4] : NaN,
              candle[5] ? candle[5] : NaN,
              candle[6] ? candle[6] : NaN,
              candle[7] ? candle[7] : NaN,
            ])
            // debugger
            // debugger
            // this.dc.merge('chart.data', sliceData)
            this.updateTrainingModeState({
              state: this.isTrainingMode,
              startingIndex: trainingModeStartingIndex,
              trainingDataIndex: trainingModeData.length - 1,
              trainingModeData,
              trainingModeDataRaw: trainingModeDataRaw,
            })
          } else if (selectedIndex < selectedIndexOld) {
            for (let i = trainingModeDataRaw.length - 1; i > selectedIndex; i--) {
              trainingModeDataRaw[i] = [
                trainingModeDataRaw[i][0],
                NaN,
                NaN,
                NaN,
                NaN,
                NaN,
                NaN,
                NaN,
              ]
            }
            this.updateTrainingModeState({
              state: this.isTrainingMode,
              startingIndex: trainingModeStartingIndex,
              trainingDataIndex: trainingModeData.length - 1,
              trainingModeData,
              trainingModeDataRaw: trainingModeDataRaw,
            })
            // this.dc.data.chart.data.pop()
          }
          if (selectedTime) this.setTimeStampToSplitter(selectedTime)
          else {
            const timeFrame = trainingModeDataRaw[selectedIndex][0]
            this.setTimeStampToSplitter(timeFrame)
          }
        }
        /**
         * let difference = t1 maps....
         * let _index = 0;
         * let newCandles = []
         * let currentCandle = newValue;
         * while(_index < difference){
         *   newCandles.push(this.getSymbolData[currentCandle++])
         * }
         * this.dc.merge('chart.data', [...newCandles]])
         */
  
        /* if (newValue < this.getSymbolData.length - 1 && this.isTrainingMode) {
           if (newValue > oldValue) {
             //this.getSymbolData[newValue]
             this.dc.merge('chart.data', [this.getSymbolData[newValue]])
           } else if (newValue < oldValue) {
             this.dc.data.chart.data.pop()
           }
           await this.$nextTick()
           this.dc.tv.resetChart(true)
   
         }
         if(newValue < this.getSymbolData.length){
           const timeFrame = this.getSymbolData[newValue][0];
           this.setTimeStampToSplitter(timeFrame)
         }*/
      },
      isTrainingMode() {
        if (this.isTrainingMode) {
          this.traningModeIndex = this.trainingModeStartingIndexSubset
        } else {
          this.candleIndex = this.formattedSymbolData.length - 1
        }
      },
      showWatchRenameModal(n, o) {
        if (!n) {
          this.watchListMainId = this.watchListMainId + 1
        }
      },
    },
   
  created() {
    chartEvents.$on('removeSymbolInWatchListModal', this.removeSymbolInWatchListModal)
    // chartEvents.$on('symbolChangeVerifyScanDetails', this.symbolChangeVerifyScanDetails)
    
  },
  beforeDestroy(){
    chartEvents.$off('removeSymbolInWatchListModal', this.removeSymbolInWatchListModal)
    // chartEvents.$off('symbolChangeVerifyScanDetails')1111
  }
  }
  </script>
  
  <style>
  .scan {
    position: relative;
    display: inline-block;
    z-index: 101;
  }
  
  /* Dropdown Content (Hidden by Default) */
  .scan-content {
    position: absolute;
    background-color: #ffffff;
    width: 125px;
    box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.2);
    z-index: 1;
    top: 30px;
    border-radius: 2px;
    max-height: 25vh;
    overflow-y: scroll;
  }
  
  /* Links inside the dropdown */
  .scan-content a {
    color: black;
    padding: 6px 10px;
    text-decoration: none;
    display: block;
    cursor: pointer;
    display: flex;
    gap: 5px;
  }
  .scan-content a:hover {
    color: black;
    background-color: v-bind(hoverColorDrpd);
  }
  
  .scan-dropbtn {
    background-color: #414343;
    color: white;
    height: 28px;
    width: 25px;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .scan-BtnDiv {
    width: 100px;
    height: 28px;
    background-color: #ffffff;
    border: 1px solid #777777;
    display: flex;
    align-items: center;
    padding: 0 5px;
    gap: 5px;
  }
  
  /* Show the dropdown menu on hover */
  /* .owais:active .owais-content {
    display: block;
  } */
  
  @media screen and (max-width: 1000px) {
    #toggleSidebar {
      /* display: none; */
    }
  }
  
  /* .isActive{
  
   } */
  
  .small-font {
    font-family: Segoe UI, Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.05em;
    /*  font-size:0.95em;   changed*/
    /* font-weight: 600; */
    /* letter-spacing: 1px; */
  }
  
  /* .f-wh{
    margin-bottom: 2px;
  } */
  
  .watchlist-button-group {
    display: flex;
    max-width: 50%;
  }
  </style>
  