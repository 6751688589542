<template>
  <pattern-base-modal
    scanName="Activity"
    class="fontSize"
    v-model="open"
    @onClose="$emit('change', !open)"
    @stop="onScanStop"
    @ok="onScanClick"
    @saveScanSettings="saveScanSettings"
    @deletedScan="deletedScan"
    @changeScanSettings="changeScanSettings"
    @ResetScanDefaultSettings="ResetScanDefaultSettings"
    header="Activity"
    :scanType="id"
    :id="id"
    :valid="isValid"
    customStyle=""
    :isLoading="isLoading"
  >
    <sui-form-fields grouped class="fontSize">
      <!-- <data-settings :id="id" type="scanner" /> -->
      <!-- top -->
      <sui-form-fields inline class="fontSize">
        <sui-form-field inline class="fontSize">


          
          <input
            type="radio"
            id="activity_settings~top"
            name="activity_settings"
            value="top"
            :style="{ cursor: 'pointer' }"
            
            v-model="settings.mode"
            @input="handleUserChoice"
          />
          <label
            for="activity_settings~top"
            :style="getRadioProps"
            class="fontSize"
            >Top</label
          >
          <sui-input
            type="number"
            tabindex="1"
            :style="themeDarkMode ? {'background-color':'#505050'}:{}"
            class="hidden"
            v-model="settings.topCount"
            @input="handleUserChoice"
          />
          stocks by
        </sui-form-field>
        <sui-dropdown
          placeholder="stocks by"
          selection
          :options="topModeOptions"
          v-model="settings.topMethod"
          @input="handleUserChoice"
        />
      </sui-form-fields>
      <!-- closed on high -->
      <sui-form-field inline>
        <input
          type="radio"
          id="activity_settings_closedOnHigh"
          name="activity_settings"
          value="closedOnHigh"
          :style="{ cursor: 'pointer' }"
          v-model="settings.mode"
          @input="handleUserChoice"
        />
        <label for="activity_settings_closedOnHigh" :style="getRadioProps"
          >All stocks that closed on their high</label
        >
      </sui-form-field>

      <!-- closed % within high -->
      <sui-form-field inline>
        <input
          type="radio"
          id="activity_settings_closedOnHighPct"
          name="activity_settings"
          value="closedOnHighPct"
          :style="{ cursor: 'pointer' }"
          v-model="settings.mode"
          @input="handleUserChoice"
        />
        <label for="activity_settings_closedOnHighPct" :style="getRadioProps"
          >All stocks that closed within</label
        >

        <sui-input
          type="number"
          tabindex="1"
          class="hidden"
          :style="themeDarkMode ? {'background-color':'#505050'}:{}"
          v-model="highPct"
          @input="handleUserChoice"
        />
        % within their high
      </sui-form-field>

      <!-- closed on low -->
      <sui-form-field inline>
        <input
          type="radio"
          id="activity_settings_closedOnLow"
          name="activity_settings"
          value="closedOnLow"
          :style="{ cursor: 'pointer' }"
          v-model="settings.mode"
          @input="handleUserChoice"
        />
        <label for="activity_settings_closedOnLow" :style="getRadioProps"
          >All stocks that closed on their low</label
        >
      </sui-form-field>

      <!-- closed % within low -->
      <sui-form-field inline>
        <input
          type="radio"
          id="activity_settings_closedOnLowPct"
          name="activity_settings"
          value="closedOnLowPct"
          :style="{ cursor: 'pointer' }"
          v-model="settings.mode"
          @input="handleUserChoice"
        />
        <label for="activity_settings_closedOnLowPct" :style="getRadioProps"
          >All stocks that closed within</label
        >
        <sui-input
          type="number"
          tabindex="1"
          :style="themeDarkMode ? {'background-color':'#505050'}:{}"
          class="hidden owais"
          v-model="lowPct"
          @input="handleUserChoice"
        />
        % within their low
      </sui-form-field>
      <!-- closed within price range -->
      <sui-form-field inline>
        <input
          type="radio"
          id="activity_settings_range"
          name="activity_settings"
          value="range"
          :style="{ cursor: 'pointer' }"
          v-model="settings.mode"
          @input="handleUserChoice"
        />
        <label for="activity_settings_range" :style="getRadioProps"
          >All stocks that closed within a price range from
        </label>
        <sui-input
          type="number"
          tabindex="1"
          class="hidden"
          :style="themeDarkMode ? {'background-color':'#505050'}:{}"
          v-model="settings.priceRangeStart"
          @input="handleUserChoice"
        />
        to
        <sui-input
          type="number"
          tabindex="1"
          class="hidden"
          :style="themeDarkMode ? {'background-color':'#505050'}:{}"
          v-model="settings.priceRangeEnd"
          @input="handleUserChoice"
        />
      </sui-form-field>
    </sui-form-fields>
  </pattern-base-modal>
</template>

<script>
const DEFAULT_SETTINGS = {
  mode: 'top',
  topCount: 10,
  topMethod: 'volume',
  withinPct: 1.0,
  priceRangeStart: 1,
  priceRangeEnd: 5,
}
import PatternBaseModal from './PatternBaseModal.vue'
import { mapActions, mapGetters } from 'vuex'
import {DEFAULT_DATA_SETTINGS,DEFAULT_FILTER_SETTINGS,DEFAULT_EVENT_SETTINGS} from "../../../settings/USER_SETTINGS_DEFAULT"
import DataSettings from '../DataSettings.vue'

export default {
  components: { PatternBaseModal ,DataSettings },
  model: {
    prop: 'open',
    event: 'change',
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  name: 'Activity',
  methods: {
    ...mapActions([
      'updateMarketAccordingTODataSettings',
      'setActivityScanSettings',
      'sendActivityScanRequest',
      'cancelScans',
      'saveActivityScanRequest',
      'deleteScanSettings',
      'getUserScanList',
      'setSelectedMarket',
    ]),
    async onScanClick() {
      this.isLoading = true
      await this.sendActivityScanRequest()
      this.isLoading = false
      // console.log('onScanClick --- ',open)
      this.$emit('change', !open)
    },
    handleUserChoice() {
      if (this.userChoice == false) {
        this.$store.commit('SET_USER_CHOICE', true)
      }
    },
    async saveScanSettings(name) {
      await this.saveActivityScanRequest(name)
      this.$store.commit('SCAN_POPUP', false)
      await this.getUserScanList()
    },
    changeScanSettings(val) {
      this.updateMarketAccordingTODataSettings({...val,id:this.id})
      this.settings = val.settings
    },
    async deletedScan(selectedScan) {
      await this.deleteScanSettings(selectedScan)
      this.$store.commit('SCAN_DELETE_POPUP', false)
    },
    ResetScanDefaultSettings() {
      let val = {
        dataSettings:JSON.parse(JSON.stringify(DEFAULT_DATA_SETTINGS)),
        filterSettings:JSON.parse(JSON.stringify(DEFAULT_FILTER_SETTINGS)),
        events:JSON.parse(JSON.stringify(DEFAULT_EVENT_SETTINGS))
      }
      this.updateMarketAccordingTODataSettings({...val,id:this.id})
      this.settings = JSON.parse(JSON.stringify(DEFAULT_SETTINGS))
    },
    onScanStop() {
      this.isLoading = false
      this.cancelScans()
    },
  },
  data() {
    return {
      inputBackColor:'white',
      id: "activity",
      isLoading: false,
      lowPct: 5,
      highPct: 5,
      settings: JSON.parse(JSON.stringify(DEFAULT_SETTINGS)),
      topModeOptions: [
        {
          text: 'volume',
          value: 'volume',
        },
        {
          text: 'price increase',
          value: 'priceIncrease',
        },
        {
          text: '% price increase',
          value: 'priceIncreasePct',
        },
        {
          text: 'market cap',
          value: 'marketCap',
        },
      ],
    }
  },
  computed: {
    ...mapGetters(['themeFontColor', 'userChoice', 'themeDarkMode']),
    getRadioProps() {
      return {
        ...this.themeFontColor,
        cursor: 'pointer',
        marginRight: '.8em',
        fontWeight: 'normal',
      }
    },
    /** @returns {Boolean} */
    isValid() {
      return Boolean(this.settings.mode)
    },
  },
  watch: {
    themeDarkMode(val){
      if(val){
        this.inputBackColor = "#505050"
        // this.inputFontColor = "#A2A2A2"
      }
      else{
        this.inputBackColor = "white"
        // this.inputFontColor = this.themeFontColor.color
      }
    },
    settings: {
      handler: function (newSettings) {
        this.setActivityScanSettings(newSettings)
      },
      deep: true,
    },
    highPct(newVal) {
      if (this.mode === 'closedOnHighPct') {
        this.settings.withinPct = newVal
      }
    },
    lowPct(newVal) {
      if (this.mode === 'closedOnLowPct') {
        this.settings.withinPct = newVal
      }
    },
  },
}
</script>

<style scoped>

div[role="listbox"]{
  background-color:v-bind(inputBackColor) !important;
}
.fontSize {
  font-size: 13.5px;
}

/*input[type="radio"] {
  accent-color: #e72510 !important;
} */

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

@media screen and (max-width: 460px) {
  .ui.form .inline.field:not(.wide) .ui.input,
  .ui.form .inline.fields .field:not(.wide) .ui.input {
    width: 40px;
  }
}
/* .owais::-webkit-outer-spin-button,
.owais::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
} */
</style>
