<template>
    <dialog-box
     :modalHeader="`Remove ${deleteWatchListSymbolName} from ${deleteWatchListlName}?`"
     :open="open"
     @onClose="onClose"
     @ok="onOk"
     :size="`Small`"
    >
        <h4 class="pr-5">Are you sure you want to remove this ticker from your watchlist?</h4>
        <h4 class="pr-5">Don't worry, you can always add it back whenever you need.?</h4>
     </dialog-box>   
        
</template>

<script>
import DialogBox from '@/baseComponents/DialogBox.vue'
import { mapActions } from 'vuex'
import { chartEvents } from '../../../chartEvents'
export default {
    name: 'DeleteWatchListSymbolModal',
    components :{
        DialogBox
    },
    model:{
    prop: 'open',
    event: 'change'
  }, 
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    deleteWatchListSymbolName:{
      type: String,
      default: '',
    },
    deleteWatchListlName:{
      type: String,
      default: '',
    },
    deleteWatchListSymbolId :{
      type: String,
      default: '',
    },
    deleteWatchListMarket:{
      type: String,
      default: '',
    }


  },
methods: {
  ...mapActions(['removeCurrentSymbolFromLists']),
    onClose(){
      console.log('onClose', this.deleteWatchListSymbolName, this.deleteWatchListlName)
      this.$emit('onModalClose')
    },
    async onOk(){
        console.log('onOk', this.deleteWatchListSymbolName,this.deleteWatchListSymbolId, this.deleteWatchListMarket)
        chartEvents.$emit('removeSymbolInWatchListModal' ,this.deleteWatchListSymbolName,this.deleteWatchListSymbolId, this.deleteWatchListMarket)
        this.$emit('onModalClose')
        // await this.removeCurrentSymbolFromLists({
        //   name :this.deleteWatchListSymbolName,
        //   id :this.deleteWatchListSymbolId,
        //   index :this.deleteWatchListMarket
        // })
        

        
    }
},
mounted(){
  console.log("selectedWatchCode----->", this.$store.getters.selectedWatchCode)
}

}
</script>