<template>
    <dialog-box
     modalHeader="Remove All Drawings"
     :open="open"
     @onClose="onClose"
     @ok="onOk"
     :size="`Small`"
    >
        <h4 class="pr-5">Are you sure you want to remove all the drawings?</h4>
     </dialog-box>   
        
</template>

<script>
import DialogBox from '@/baseComponents/DialogBox.vue'
export default {
    name: 'DeleteMessage',
    components :{
        DialogBox
    },
    model:{
    prop: 'open',
    event: 'change'
  }, 
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
methods: {
    onClose(){
        this.$emit('onClose')
    },
    onOk(){
        this.$emit('onOk')
    }
}

}
</script>