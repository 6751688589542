<template>
    <pro-scans-base-modal
     scaneName="Pro Divergence"
     v-model="open"
     @onClose="$emit('change', !open)"
     @stop="onScanStop"
     @ok="onScanClick"
     header="Pro Divergence Watch"
     :valid="isValid"
     :scanType="scanType"
     id="proDivergence"
     :isLoading="isLoading"
     
   >
     
   </pro-scans-base-modal>
 </template>
 
 <script>
 import { mapActions, mapGetters } from 'vuex'
 import ProScansBaseModal from '../ProScansBaseModal.vue'
 
 export default {
   components: {ProScansBaseModal },
   model: {
     prop: 'open',
     event: 'change'
   },
   props: {
     open: {
       type: Boolean,
       default: false
     }
   },
   name:'proDivergence',
   methods: {
     ...mapActions([
       'cancelScans',
       'sendDivergenveRequest'
       
     ]),
     async onScanClick() {
       this.isLoading = true
       await this.sendDivergenveRequest()
       this.isLoading = false
       console.log('onScanClick --- ')
       this.$emit('change', !open)
     },
     onScanStop() {
       this.isLoading = false
       this.cancelScans()
     }
   },
   data() {
     return {
       scanType:"proDivergence",
       isLoading: false,
     }
   },
   computed: {
     ...mapGetters(['gurusScanSettings']),
     /** @returns {Boolean} */
     isValid() {
       return true
     },
     
       
     
   }
 }
 </script>
 
 <style lang="scss" scoped>
 .input-container{
   font-size: 13.5px;
 }
 .fontSize{
   font-size: 13.5px;
   margin-bottom: 5px;
 }
 @media screen and (max-width:870px){
 .fontSize{
   font-size: 11px;
   margin-bottom: 5px;
 }
 }
 @media screen and (max-width:510px){
 .fontSize{
   font-size: 10px;
   margin-bottom: 7px;
 }
 }
 </style>
 