<template>
    <div class="w-full h-full flex justify-between px-8 bg-white">
      <div class="flex items-center gap-2">
        <span>
          <span 
           class="cursor-pointer"
           @click="handleClick"
          >
            <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 2L2 8L8 14" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
             </svg>
          </span>
          
        </span>
        <span class="text-[18px] font-[700]">{{ headerName }}</span>
      </div>
      <div
        class="cursor-pointer flex justify-center items-center"
        @click="handleClick"
      >
        <BackgroundHover>
          <span>
            <svg
              width="30"
              height="30"
              viewBox="0 0 26 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.25 6.75L6.75 19.25"
                stroke="#BCBCBC"
                stroke-width="2.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M6.75 6.75L19.25 19.25"
                stroke="#BCBCBC"
                stroke-width="2.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
        </BackgroundHover>
      </div>
    </div>
  </template>
  <script>
  import BackgroundHover from '../BackgroundHover.vue'
  import { chartEvents } from '../../chartEvents'
  export default {
    name: 'App',
    data() {
      return {}
    },
    props: {
      headerName :{
        type: String,
        default: 'Menu',
      }
    },
    components: {
      BackgroundHover,
    },
    methods: {
      handleClick(){
        this.$emit('onClose')
      }

    },
    computed: {},
    mounted() {},
    watch: {},
  }
  </script>
  <style scoped></style>
  