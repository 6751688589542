<template>
    <div class="w-full !h-[60rem] overflow-y-auto scrollbar -bg-red-200" >
        <ul
        class="!w-full -h-full -bg-white  dark:!bg-[#1A1A1A] dark:!border-[#6C707A] leading-3 flex flex-col items-center justify-between rounded-[3px] scrollbar overflow-y-scroll scroll-container"
        >
            <div class="!w-full h-full flex flex-col px-4 py-4 -bg-red-300">
                 <li v-for="(item, index) in scannerNameList" :key="index"
                 class="flex w-full h-[5rem] items-center py-[14px] border border-transparent -text-appBtnActiveTxt dark:!text-[#6C707A] rounded-lg md:pl-4 hover:border-[#e4e4e4] hover:border dark:hover:!bg-[#2D2D2D] hover:bg-appBtnHover cursor-pointer -!border-[transparent]"
                 >
                 <div
                 @click="triggerScannerInMobileModal(item)" 
                 class="hover:bg-appBtnHover -bg-red-200 h-full w-full flex cursor-pointer px-5">
                    <div class="flex items-center gap-4">
                        <span v-html="getSvg(item.image)"></span>
                        <div class="!text-[16px]">{{ item.name }}</div>
                    </div>
                 </div>
                    

                 </li>
            </div>

        </ul>
    </div>
</template>

<script>
import { getSvgByName } from '../../../assets/getSvgByName'
import { indicatorSvgsWithName } from '../../../assets/getChartSvg'
import { chartEvents } from '../../../chartEvents'
export default {
    name: 'SacnnerOption',

    data() {
        return {
            scannerNameList :[
  { id: 1, name: 'Charting Patterns', image: 'charting-patterns', onClickHandler: this.patterns },
  { id: 2, name: 'Activity', image: 'activity', onClickHandler: this.activity },
  { id: 3, name: 'Candlestick', image: 'candlestick', onClickHandler: this.candleDialog },
  { id: 4, name: 'Technical Indicators', image: 'technical-indicators', onClickHandler: this.indicators },
  { id: 5, name: 'Darvas Box', image: 'darvas-box', onClickHandler: this.darvasBoxes },
  { id: 6, name: 'Fundamental', image: 'fundamental', onClickHandler: this.fundementals },
  { id: 7, name: 'Capitulation Scan', image: 'capitulation-days', onClickHandler: this.capitulationDialog },
  { id: 8, name: 'Higher High, Higher Low', image: 'hhll', onClickHandler: this.higherHighHigherLowsDialog },
  { id: 9, name: 'Bollinger Squeeze', image: 'bollinger-squeeze', onClickHandler: this.bollingerSqueezeDialog },
  { id: 10, name: 'Divergence Scan', image: 'divergence-scan', onClickHandler: this.divergenceScanDialog },
  { id: 11, name: 'Big Candle', image: 'big-candle', onClickHandler: this.bigCandleDialog },
  { id: 12, name: 'Heiken Ashi', image: 'heiken-ashi', onClickHandler: this.heikenAshiDialog },
  { id: 13, name: 'Pocket Pivot', image: 'pocket-pivot', onClickHandler: this.pocketPivotDialog },
  // { id: 14, name: 'ProReversal Signal', image: 'proReversal-signal', onClickHandler: this.isProReversalBox },
  // { id: 15, name: 'ProDowntrend Monitor', image: 'proDowntrend-Monitor', onClickHandler: this.onProDowntrendMonitorClick },
  // { id: 16, name: 'ProBreakout Alert', image: 'proBreakout-alert', onClickHandler: this.onProbreakoutAlertClick },
  // { id: 17, name: 'ProDivergence Watch', image: 'proDivergence-watch', onClickHandler: this.onProDivergenceWatchClick },
  // { id: 18, name: 'ProSwing Boost', image: 'proSwing-Boost', onClickHandler: this.onProSwingBoostClick },
  // { id: 19, name: 'ProSwing Drop', image: 'proSwing-drop', onClickHandler: this.onProSwingDropClick }
],

            
        }
    },

    methods: {
        getSvg(name) {
        return indicatorSvgsWithName(name, false)
      },

      triggerScannerInMobileModal(item) {
        console.log(item)
        chartEvents.$emit('triggerScannerInMobileModal', item)
      }
    }
}
</script>