<template>
   <pro-scans-base-modal
    scaneName="Pro Reversal"
    v-model="open"
    @onClose="$emit('change', !open)"
    @stop="onScanStop"
    @ok="onScanClick"
    header="Pro Reversal"
    :valid="isValid"
    :scanType="scanType"
    id="proReversalSignal"
    :isLoading="isLoading"
    
  >
    
  </pro-scans-base-modal>
  <!-- <guru-base-modal
    v-model="open"
    @onClose="$emit('change', !open)"
    @stop="onScanStop"
    @ok="onScanClick"
    header="Higher High Higher Low"
    :valid="isValid"
    customStyle=""
    :scanType="scanType"
    id="proRevresal"  
    :isLoading="isLoading"
  >
  </guru-base-modal>  -->
</template>

<script>
import GuruBaseModal from './GuruBaseModal.vue'
import { mapActions, mapGetters } from 'vuex'
import PatternBaseModal from '../patterns/PatternBaseModal.vue'
import ProScansBaseModal from '../ProScansBaseModal.vue'

export default {
  components: { GuruBaseModal, PatternBaseModal, ProScansBaseModal },
  model: {
    prop: 'open',
    event: 'change'
  },
  props: {
    open: {
      type: Boolean,
      default: false
    }
  },
  name:'ProRevresal',
  methods: {
    ...mapActions([
      'setPocketPivotScanSettings',
      'sendPocketPivotScanRequest',
      'cancelScans',
      "sendProReversalScanRequest"
    ]),
    async onScanClick() {
      this.isLoading = true
      await this.sendProReversalScanRequest()
      this.isLoading = false
      console.log('onScanClick --- ')
      this.$emit('change', !open)
    },
    onScanStop() {
      console.log("stopScan",)
      this.isLoading = false
      this.cancelScans()
    }
  },
  data() {
    return {
      scanType:"proReversalSignal",
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters(['gurusScanSettings']),
    /** @returns {Boolean} */
    isValid() {
      return true
    },
    
      
    
  }
}
</script>

<style lang="scss" scoped>
.input-container{
  font-size: 13.5px;
}
.fontSize{
  font-size: 13.5px;
  margin-bottom: 5px;
}
@media screen and (max-width:870px){
.fontSize{
  font-size: 11px;
  margin-bottom: 5px;
}
}
@media screen and (max-width:510px){
.fontSize{
  font-size: 10px;
  margin-bottom: 7px;
}
}
</style>
