<template>
  <dialog-box
    modalHeader=""
    :open="open"
    :action="false"
    :scrolling="false"
    
    class="radius:[0px]"
  >
<div class="flex flex-col gap-3 h-full w-full">
  <!--    New Modal Hearder start -->
  <div
    class="w-full h-full flex items-center justify-between py-2 pl-2 border-b dark:border-[#2D2D2D]!important border-[#E2E8F0]!important"
  >
    <div>
      <span class="md:text-[18px] font-sans font-[600]">Symbol lookup</span>
    </div>

    <div
      @click="closeModal"
      class="cursor-pointer p-[8px] rounded-md hover:!bg-appBtnHover dark:!bg-[#FFFFFF1A] dark:hover:!bg-[#2D2D2D]"
    >
      <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 18 18"
         class="h-[18px] w-[18px]"
        >
          <path
            stroke="currentColor"
            stroke-width="1.2"
            d="m1.5 1.5 15 15m0-15-15 15"
          ></path>
        </svg>

    </div>
  </div>

  <!--    New Modal Hearder end -->

  <div class="xxs:h-auto!important md:!h-[6vh]!important md:py-1!important -bg-green-500">
          <span
            class="text-gray-400 xxs:!leading-[10px] xxs:!text-[12px] md:!text-[13px] !font-normal"
          >Enter the stock symbol or company name to search for real-time data
            and trading information. For example, type 'AAPL' for Apple Inc. or
            'GOOGL' for Alphabet Inc.</span
          >
  </div>

  <!--  New Searchbar Start  -->
  <div
    class="flex items-center xxs:h-auto md:!h-[4vh] shrink-0 w-full space-x-1 -bg-red-600 dark:hover:bg-[#23232370] dark:!border-[#2D2D2D] qm:mt-0 xxs:mt-4 px-2 py-1.5 text-sm hover:bg-appBtnHover dark:bg-[#121212] bg-[#F6F6F6] rounded-xl"
    :class="
            themeDarkMode ? '!border-[#1a1a1a] border' : 'border border-[#D1D1D1]'
          "
  >
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.16667 15.8333C12.8486 15.8333 15.8333 12.8486 15.8333 9.16667C15.8333 5.48477 12.8486 2.5 9.16667 2.5C5.48477 2.5 2.5 5.48477 2.5 9.16667C2.5 12.8486 5.48477 15.8333 9.16667 15.8333Z"
        stroke="#6D6D6D"
        stroke-width="1.7"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.5 17.5L13.875 13.875"
        stroke="#6D6D6D"
        stroke-width="1.7"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>

    <input
      v-model="filteredtext"
      v-on:keyup="keyUpSearch"
      class="bg-transparent text-[#181818] uppercase dark:text-[#ffffff80] placeholder:text-[#6D6D6D] placeholder:dark:text-[#6D6D6D] placeholder:text-[14px] text-[16px] focus:outline-none w-full"
      placeholder="Symbol, eg: AAPL"
    />
    <div class="flex items-center space-x-3">

      <svg
        width="2"
        class="text-[#E7E7E7] dark:text-[#7C7C7C]"
        height="28"
        viewBox="0 0 2 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="2" height="28" fill="currentColor" />
      </svg>

      <div @click="clearSymbolSearch" class="text-[#7C7C7C] cursor-pointer text-[14px]">
        Clear
      </div>
    </div>
  </div>
  <!--  New Searchbar End  -->

  <!--  New Market Section Start  -->
  <div
    class="w-full flex h-[10%] items-center mt-1 space-x-3 px-2 overflow-x-auto no-scrollbar"
  >
    <div
      v-for="(metadata, i) in markets"
      :key="metadata.id"
      v-show="metadata.show"
      className="flex items-center"
      @click="changeMarketState(i, 'markets', 'showCategory')"
    >
      <div
        :ref="`marketDiv_${metadata.code}`"
        class="flex p-1.5 w-fit px-4 rounded-3xl xxs:!text-[12px] sm:!text-[14px] suggestionText whitespace-nowrap font-[400]"
        :class="
                  metadata.selected
                    ? 'text-[#F6F6F6] cursor-default bg-[#292929] font-[500]'
                    : 'bg-[#F6F6F6] cursor-pointer hover:bg- dark:hover:bg-[#232323]  dark:bg-[#1a1a1a] dark:text-[#ffffff60] text-[#292929]'
                "
      >
        {{ metadata.desc }}
      </div>
    </div>
  </div>
  <!--  New Market Section End  -->

  <!--  New Alphabet section start  -->
  <div
    class="w-full flex h-[10%] items-center space-x-3 px-2 overflow-x-auto no-scrollbar"
  >
    <div
      v-for="(metadata, i) in categoryByMarket"
      :key="metadata.id"
      className="flex items-center"
      v-show="showCategory && metadata.show"
      @click.prevent="
                updateContract(i, metadata.desc, 'category', 'showContracts')
              "
    >
      <div
        :ref="`categoryDiv_${metadata.desc}`"
        class="flex p-1.5 w-fit px-4 rounded-3xl xxs:!text-[12px] sm:!text-[14px] suggestionText whitespace-nowrap font-[400]"
        :class="[
                  metadata.selected
                    ? 'text-[#F6F6F6] cursor-default bg-[#292929] font-[500]'
                    : 'bg-[#F6F6F6] cursor-pointer hover:bg-[#e2e2e2] dark:hover:bg-[#232323]  dark:bg-[#1a1a1a] dark:text-[#ffffff60] text-[#292929]',
                    metadata.isHidden ? 'hidden':'block'
                  ]
                "

      >
        {{ metadata.desc }}

      </div>
    </div>
  </div>
  <!--  New Alphabet section End    -->

  <div

    class="flex flex-col  h-[60vh] p-4 text=[13.5] overflow-y-auto scrollbar"
  >


    <!--  New Searched Symbol section Start    -->
    <div
      v-for="(symbol, i) in symbolData"
      :key="i"
      @click="symbolChange(i, symbol)"
      class="flex items-start justify-between cursor-pointer px-2 dark:hover:!bg-[#2D2D2D30] hover:bg-[#E4E4E460] border-b dark:border-b-[#2D2D2D] py-4 border-b-[#E7E7E7]"
      :style="
                          symbol.selected
                            ? {
                                cursor: 'pointer',
                                color: 'black',
                                // ...themeSecondaryColor,
                              }
                            : {
                                cursor: 'pointer',
                                ...themeFontColor,
                                padding: '5px',
                              }
                        "
    >
      <div class="flex items-start sm:space-x-4 space-x-1">
        <div>
          <div
            class="w-12 h-12 flex uppercase  items-center dark:bg-[#676868] text-[white] dark:text-[#f6f6f6] justify-center rounded-full bg-[#6B7280]"
          >
            <!--              <TickerIcon :ticker="items.symbol" />-->
            <img v-if="symbol.symbol.indexOf('_') == -1" :src="`https://logo.synthfinance.com/ticker/${symbol.symbol?.split('.')[0]}`" class="rounded-full w-full h-full object-contain" />
            <span v-else>{{ symbol.symbol.slice(0,1)}}</span>
          </div>
        </div>
        <div class="dark:text-[#F6F6F6]">
          <div
            class="font-[300] text-[1.3rem]"
            :title="symbol?.companyName"
          >
            {{ symbol.symbol }}
            <span class="!font-normal dark:text-[#ffffff90]">{{
                getCompanyName(symbol)
              }}</span>
          </div>
          <!--            <div class="text-[#BDBDBD] mt-1 sm:text-[14px]">-->
          <!--              {{ items?.exchange }}-->
          <!--            </div>-->
        </div>
      </div>
      <div class="flex sm:items-center sm:space-x-5">
        <div>
          <div class="font-[600] dark:text-white priceText">
            {{ symbol?.close ? `$ ${symbol?.close?.toFixed(2)}` : '$0'
            }}<span
            :class=" symbol?.change < 0 ?'text-errorText':' text-[#037950]'"
            class="text-[12px] changeText ml-1 font-[500]"
          >
                       {{symbol?.change > 0 ? '+' : ''}}{{ symbol?.change?.toFixed(2) || '0' }}
                        {{
              symbol?.changePercent
                ? `(${symbol.changePercent?.toFixed(2)}%)`
                : '(0.0%)'
            }}</span
          >
          </div>

        </div>
      </div>
    </div>
    <!--  New Searched Symbol section End    -->



  </div>
</div>
  </dialog-box>
</template>

<script>
import DialogBox from '@/baseComponents/DialogBox.vue'
import { mapActions, mapGetters } from 'vuex'
import { fetchSymbolByName } from '../../services/symbol'
import { marketsData } from 'Data/markets'
import getChildListeners from 'semantic-ui-vue/src/lib/mixins/SemanticUIVueMixin/getChildListeners'

const categoryList = [
  {
    show: true,
    id: 0,
    desc: '0-9',
    keys: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
    selected: false,
  },
  { show: true, id: 1, desc: 'A-B', keys: ['A', 'B'], selected: false },
  {
    show: true,
    id: 2,
    desc: 'C-F',
    keys: ['C', 'D', 'E', 'F'],
    selected: false,
  },
  {
    show: true,
    id: 3,
    desc: 'G-M',
    keys: ['G', 'H', 'I', 'J', 'K', 'L', 'M'],
    selected: false,
  },
  {
    show: true,
    id: 4,
    desc: 'N-R',
    keys: ['N', 'O', 'P', 'Q', 'R'],
    selected: false,
  },
  {
    show: true,
    id: 5,
    desc: 'S-Z',
    keys: ['S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
    selected: false,
  },
]
const localMarket = marketsData.map((m) => {
  return { ...m, show: true, categoryList }
})

const getLocalMarketClone = () => {
  return JSON.parse(JSON.stringify(localMarket))
}
export default {
  name: 'SearchSymbol',
  components: {
    DialogBox,
  },
  model: {
    prop: 'open',
    event: 'change',
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    keyUpSearch(e) {
      const ignoredKeyCodes = [13,35, 36, 37, 38, 39, 40, 16, 17]
      const { keyCode, target } = e
      const searchText = target.value.trim()
      if (searchText === '') {
        if (this.searchMode) {
          this.filteredText(searchText)
        }
        this.searchMode = false
        return
      } else {
        this.searchMode = true
      }

      if (ignoredKeyCodes.indexOf(keyCode) > -1) {
        return
      }
      this.activateSearch = true
      this.filteredText(searchText)
    },
    async onSaveSettings() {
      // this.$emit('change', !open)

      this.onClose()
      this.$emit(
        'selectSymbolModalData',
        this.selectedSymbol + '~' + this.selectedMarket
      )
    },
    getSymbolName(symbol) {
      let res = ''
      let symbolName = symbol.symbol
      if (symbolName) {
        let symbolNameParts = symbolName.split('.')
        if (symbolNameParts.length) {
          res = symbolNameParts[0]
        }
      }
      return res
    },
    getCompanyName(symbol) {
      let res = ''
      let companyName = symbol.companyName
      if (companyName) {
        if (companyName.length > 30) {
          res = `${companyName.substring(0, 30)} ...`
        } else {
          res = companyName
        }
      }
      return res
    },
    onClose() {
      this.$emit('change', !open)
    },
    closeModal() {
      if (this.changesNotSaved) {
        this.$store.commit('RESET_CHART_SETTINGS', {
          chartSettings: this.defaultChartSettings,
        })
        this.$store.commit('UPDATE_CHART_STATE', new Date().getTime())
      }
      this.clearSymbolSearch()
      // this.settings = this.defaultIndicatorSettings
      this.onClose()
    },
    changeMarketState(id, prop, changeProp) {
      // console.log("changeMarketState",id, prop, changeProp)
      this[prop] = this[prop].map((v, i) => {
        if (i === id) {
          v.selected = true
        } else {
          v.selected = false
        }
        return v
      })
      this[changeProp] = true
      this.selectedMarket = this[prop][id].code
      this.symbolData = []
      let filterMarket = this.markets.find(
        (m) => m.code === this.selectedMarket
      )
      if (
        filterMarket &&
        filterMarket.categoryList &&
        filterMarket.categoryList.length
      ) {
        let selectedRange = filterMarket.categoryList[0].desc
        // console.log("selectedRange After Market Select")
        let divRefs = this.$refs[`categoryDiv_${selectedRange}`]
        if (divRefs && divRefs[0]) {
          divRefs[0].click()
        }
      }

      // this.resetCat()
      // if(market)
    },
    async updateContract(id, desc) {
      // this.changeState(id, 'category', 'showContracts')
      //  updateText
      this.selectedRange = desc
      let allMarkets = this.markets
      if (allMarkets && allMarkets.length) {
        let selectedMarket = allMarkets.find(
          (m) => m.code === this.selectedMarket
        )
        if (selectedMarket) {
          let categoryList = selectedMarket.categoryList
          categoryList = categoryList.map((v, i) => {
            if (i === id) {
              v.selected = true
            } else {
              v.selected = false
            }
            return v
          })
          selectedMarket.categoryList = categoryList
          // this.markets =allMarkets.map( m => {
          //   if(m => m.code === this.selectedMarket){
          //     // return {...m,categoryList}
          //     m.categoryList = categoryList
          //   }
          //   return m
          // })
        }
      }

      if (this.filteredtext && this.filteredtext.length) {
        this.activateSearch = false
      }
      await this.filteredText(desc, false)
    },
    clearSymbolSearch() {
      this.filteredtext = ''
      this.filtered2 = []
      this.symbolData = []
      this.activateSearch = true
      this.markets = getLocalMarketClone()
      // this.category= JSON.parse(JSON.stringify(categoryList))
      this.selectedMarket = 'ASX'
      this.selectedRange = '0-9'
      this.filteredText('1', false)
    },
    async filteredText(text, updateText = true) {
      // console.log("TEXT",text)


      let isRange = !updateText
      // console.log(this.activateSearch)
      if (this.activateSearch) {
        this.loading = true
        let filtered2 = await fetchSymbolByName(
          this,
          text,
          false,
          isRange,
          isRange ? this.selectedMarket : '',
          true,
          true
        )
        if (typeof filtered2 !== 'string') {
          this.filtered2 = filtered2
          this.loading = false
          this.filterData(updateText)
        } else {
          let textIsNotExist = text === null || text.length === 0
          if (textIsNotExist) {
            this.loading = false
            this.clearSymbolSearch()
          }
        }
      } else {
        this.filterData(updateText)
      }

      // console.log(JSON.stringify(filtered2))
    },

    transformData(data) {
    let result = [];

    for (let exchange in data) {
        let innerKeys = Object.keys(data[exchange]); // Exchange ke andar jo bhi key hai usko find karo
        innerKeys.forEach(key => {
            if (Array.isArray(data[exchange][key])) {
                result.push(...data[exchange][key]);
            }
        });
    }

    return result;
},
sortData(data, text) {
    return data.sort((a, b) => {
        if (a.symbol === text) return -1; // Exact match comes first
        if (b.symbol === text) return 1;
        if (a.symbol.startsWith(text) && !b.symbol.startsWith(text)) return -1; // Prefix match comes first
        if (!a.symbol.startsWith(text) && b.symbol.startsWith(text)) return 1;
        return a.symbol.length - b.symbol.length; // Shorter symbols come first
    });
},
groupByExchange(data) {
    return data.reduce((acc, item) => {
        const firstLetter = item.symbol[0]; // Pehla letter nikalna
        
        if (!acc[item.exchange]) {
            acc[item.exchange] = {}; // Exchange key create karna
        }
        
        if (!acc[item.exchange][firstLetter]) {
            acc[item.exchange][firstLetter] = []; // First letter array create karna
        }

        acc[item.exchange][firstLetter].push(item); // Data push karna
        
        return acc;
    }, {});
},

    filterData(updateText) {
      let filterData = this.filtered2
      console.log("filterData--->",this.filteredtext)

    if(this.filteredtext){
     let v = this.transformData(filterData)      
     let c = this.sortData(v, this.filteredtext)
     let f = this.groupByExchange(v)
     filterData = f
    //  console.log("filterData--->",this.filteredtext, c, f)
 
    }
    // console.log("filterData--->",filterData)
  

      if (filterData) {
        let selectedRange = this.category.find(
          (d) => d.desc === this.selectedRange
        )
        let selectedMarketData = filterData[this.selectedMarket]
        let filterMarkets = []
        let firstMarketKey
        let firstCategory
        if (updateText) {
          let marketKeys = Object.keys(filterData)
          if (marketKeys.length) {
            for (const marketKey of marketKeys) {
              let marketData = filterData[marketKey]
              if (!firstMarketKey) firstMarketKey = marketKey
              let keys = Object.keys(marketData)

              let localMarketClone = getLocalMarketClone()
              let filterMarket = localMarketClone.find(
                (m) => m.code === marketKey
              )
              if (filterMarket) {
                let categoryList = filterMarket.categoryList
                let filteredCatList = categoryList.filter((m) => {
                  let categoryKeys = m.keys
                  // let result = categoryKeys.includes(keys)
                  let result = categoryKeys.find((element) =>
                    keys.includes(element)
                  )
                  // console.log({categoryKeys,keys,result})
                  return result
                })
                // filteredCatList = Object.assign({},filteredCatList)
                // console.log("category Keys",marketKey,keys,filteredCatList.length)
                // console.log("filteredCatList for market",filteredCatList.length)
                if (filteredCatList.length) {
                  filterMarket.categoryList = filteredCatList
                  firstCategory = filteredCatList[0].desc
                }

                filterMarkets.push(filterMarket)
              }
            }
          }

          // console.log("marketKeys",marketKeys)

          // categoryList.filter(c => )
          // console.log("filterMarkets",filterMarkets)
          this.markets = filterMarkets

          // if(firstCategory)
          // this.selectedRange = firstCategory
        }
        // console.log("set Data with lenght ",{
        //   selectedRangeProp:this.selectedRange,
        //   selectedMarketData,
        //   firstMarketKey,
        //   firstCategory,
        //   selectedRange
        // })
        let alphaCategoryData = []
        if (selectedRange) {
          let keys = selectedRange.keys
          for (const selectedMarketDataKey in selectedMarketData) {
            // console.log("selectedMarketDataKey",selectedMarketDataKey)
            if (keys.includes(selectedMarketDataKey)) {
              alphaCategoryData.push(
                ...selectedMarketData[selectedMarketDataKey]
              )
            }
          }
        }
        console.log("alphaCategoryData",alphaCategoryData)
        this.symbolData = alphaCategoryData
        this.showCategory = true
        this.showContracts = true

        // console.log("firstMarketKey",firstMarketKey)
        if (firstMarketKey) {
          this.selectedMarket = firstMarketKey
          setTimeout(() => {
            this.selectMarketRef(this.selectedMarket)
          }, 300)
        }
      }
    },
    symbolChange(id, obj) {
      this.selectedSymbol = obj.symbol
      this.symbolData = this.symbolData.map((v, i) => {
        if (i === id) {
          v.selected = true
          this.cannotUpdateSymbol = false
        } else {
          v.selected = false
        }
        return v
      })
      this.selectedMarket = 'ASX'
      this.onSaveSettings()
    },
    selectMarketRef(selectedMarket) {
      let marketRef = this.$refs[`marketDiv_${selectedMarket}`]
      // console.log(`refs updates `,marketRef)
      if (marketRef && marketRef[0]) {
        marketRef[0].click()
      }
    },
  },
  data() {
    return {
      cannotUpdateSymbol: true,
      searchMode: false,
      activateSearch: true,
      loading: false,
      filtered2: [],
      filteredtext: '',
      selectedMarket: 'ASX',
      selectedRange: '0-9',
      selectedSymbol: '',
      markets: getLocalMarketClone(),
      category: JSON.parse(JSON.stringify(categoryList)),
      symbolData: [
        // { code: 'AAF', name: 'Symbol Name', selected: false },
      ],
      userData: {},
      multiItems: 0,
      changesNotSaved: false,
      showCategory: true,
      showContracts: false,
      theme_type: 1,
      selected_theme: 1,
      darkBackColor: 'white',
      darkFontColor: 'black',
      darkBorderColor: '#eee',
    }
  },
  watch: {
    themeDarkMode(val) {
      if (val) {
        this.darkBackColor = '#505050'
        this.darkFontColor = '#A2A2A2'
        this.darkBorderColor = '#505050'
      } else {
        this.darkBackColor = 'white'
        this.darkFontColor = 'black'
        this.darkBorderColor = '#eee'
      }
    },
    open(n, o) {
      // console.log("watching modal open close",n,o)
      if (n) {
        this.selectMarketRef(this.selectedMarket)
      }
    },
  },
  computed: {
    ...mapGetters([
      'getUserSettingData',
      'themePrimaryColor',
      'themeSecondaryColor',
      'themeFontColor',
      'themeDarkMode',
    ]),
    categoryByMarket() {
      if (this.markets && this.markets.length) {
        let selectedCategory = this.markets.find(
          (m) => m.code === this.selectedMarket
        )
        // console.log("selectedCategory",selectedCategory)
        return selectedCategory && selectedCategory.categoryList
          ? selectedCategory.categoryList
          : []
      }
      return []
    },
  },
  mounted() {
    // console.log('getUserSettingData -- ',this.getUserSettingData)
  },
}
</script>

<style>
.symbolList {
  display: flex;
  justify-content: space-between;
}

.symbolList button {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  z-index: 100;
  width: 100%;
  font-size: 12px;
}

.symbolList button:hover {
  background-color: #000000;
}

.selected-chart {
  /* background-color: red; */
  color: #ffffff !important;
  border-bottom: 0px !important;
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

.header {
  font-size: 12.3px;
}

.ui.fluid.input > input {
  background-color: v-bind(darkBackColor);
  color: v-bind(darkFontColor);
  border: 1px solid v-bind(darkBorderColor);
}
</style>
