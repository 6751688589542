<template>
  <div
    style="
      display: flex;
      flex-direction: column;
      height: 100vh;
      position: relative;
      gap: 0;
    "
    :style="themePrimaryColor"
     
  >
    <!-- Top Navigation Bar -->
    <!-- <div class="top-container">
      <TopToolbox />
    </div> -->
    <div class="top-container border-b-[3px]">
      <TopNavBar 
      @showNavDropdown="showNavDropdown"
      />
    </div>

    <!-- Middle Container (LeftBar + Chart + Right Toolbar) -->

    
      <div class="middle-container" id="middle-container">
      
        <!-- Left Bar -->
      <div class="left-bar-container border-r-[3px] -bg-red-400">
        <LeftBar />
      </div>

      <!-- Chart Container -->
      <div
        class="left-container"
        id="left-container"
        :style="themePrimaryColor"
      >
        <div class="chart-container">
          <loader v-if="showLoader" :showLoader="showLoader" />
          <chart v-if="showChart"></chart>
        </div>
      </div>

      <!-- Right Toolbar -->
      <div
        class="right-container scrollbar"
        id="right-container"
        :style="
          themeDarkMode
            ? { 'border-left': '1px solid #2C2929' }
            : { 'border-left': '1px solid #eee' }
        "
      >
        <!-- <RightToolBox /> -->
        <!-- <span v-if="width > 890"> -->
        <RightBar/>
      <!-- </span> -->

      </div>
      
      
    </div>
    
    
    <!-- Mobile Nav Dropdowns -->
    <div
      v-if="showNavDropdownInMobile && width < 600"
      class="w-full !h-full top-0 absolute z-[1000] bg-white"
    >
      <ShowNavDropDown
        @ShowNavDropDownClose="ShowNavDropDownClose"
        @handleSelectInterval="onHandleTimeFrame"
        :btnName="btnName"
        @closeRange="ShowNavDropDownClose"
      />
    </div>

    <div v-if="showNavDropdownInTab" class="w-full !h-full top-0 absolute z-[1000] bg-white">
      <NavBarOptions
        :tabHeadrName="tabHeadrName"
        @onClose="closeMenuInTab"
      />
    </div>

    <!-- Bottom Toolbar -->
    <div v-if="width < 600" class="-bg-red-200">
      <BottomToolBar
    @bottomToolBarClick="showNavDropdown"
    style="z-index: 2;" />
    <!-- <bottom-toolbox
    style="z-index: 2;"
    /> -->
    </div>
    
  </div>
</template>

<script>
import Chart from './Chart';
import TopNavBar from './TopNavBar.vue';
import BottomToolbox from './BottomToolbox.vue';
import BottomToolBar from './BottomToolBar.vue';
import TopToolbox from './TopToolbox.vue';
import RightToolBox from './RightToolBox.vue';
import RightBar from './RightBar.vue';
import Loader from 'BaseComponents/Loader';
import LeftBar from './Leftbar.vue'; // Import the LeftBar component
import { mapGetters, mapActions } from 'vuex';
import ShowNavDropDown from './ShowNavDropDown.vue';
import NavBarOptions from './navDropDown/NavBarOptions.vue';
import { chartEvents } from '../chartEvents';
import {
  isLogged,
  fetchAllSubscriptions,
  isClientAccountActive,
} from '../services/user';
import { setJwt } from '../services/http';

export default {
  name: 'HomeComponent',
  components: {
    Chart,
    ShowNavDropDown,
    TopNavBar,
    BottomToolbox,
    BottomToolBar,
    TopToolbox,
    RightToolBox,
    Loader,
    NavBarOptions,
    LeftBar, // Add LeftBar to components
    RightBar
  },
   data() {
     return {
      showNavDropdownInMobile: false,
      showNavDropdownInTab : false,
      width: window.innerWidth,
      btnName: '',
      tabHeadrName: ''
     }
     
   },
  async mounted() {
    this.$store.commit('SET_MINUS_INDEX', this.$route.query?.i ?? 0);
    const response = await this.$store.dispatch(
      'user/retrieveUserSubscriptionStatus'
    );
    if (response) {
      await isLogged();
      setJwt();
      setInterval(() => {
        console.log('check status');
        isClientAccountActive().then((d) => {
          console.log(d);
          if (d.status === 'inactive') {
            // this.$router.push('/products');
            this.retrieveSymbolsList();
          }
        });
      }, 60000 * 30);
      this.retrieveSymbolsList();
    } else {
      // this.$router.push('/products');
      this.retrieveSymbolsList();
    }
  },

  methods: {
    ...mapActions(['retrieveSymbolsList', 'showBrushData']),
    showNavDropdown(payload) {
      console.log('showNavDropdown', payload);
      if (this.width <= 600) {
        this.showNavDropdownInMobile = !this.showNavDropdownInMobile
        this.btnName = payload
        console.log('showNavDropdown1', this.showNavDropdownInMobile, payload)
      } else {
        console.log('desktop payload', payload)
        this.showNavDropdownInMobile = false
        this.showNavDropdownInDesktop = !this.showNavDropdownInDesktop
        console.log('showNavDropdown2', this.showNavDropdownInDesktop)
      }
    },

    ShowNavDropDownClose() {
      this.showNavDropdownInMobile = false
      
    },
    onHandleTimeFrame(payload) {
      console.log('payload', payload);
    },

    openMenuInTab(name){

      this.showNavDropdownInTab = !this.showNavDropdownInTab
      this.tabHeadrName = name
    },

    openMenuScanner(data){
      this.showNavDropdownInTab = !this.showNavDropdownInTab
      this.tabHeadrName = name.id
    },

    closeMenuInTab(){
      this.showNavDropdownInTab = false
    }

  },
  computed: {
    ...mapGetters([
      'getSymbolsList',
      'getSymbolData',
      'isChartLoading',
      'themeSettings',
      'themePrimaryColor',
      'themeSecondaryColor',
      'themeDarkMode',
      'themeFontColor',
    ]),
    showChart() {
      return this.getSymbolsList.length > 0;
    },
    showLoader() {
      return !this.showChart || this.isChartLoading;
    },
    

    
  },
  
  created() {
    chartEvents.$on('openMenuInTab', this.openMenuInTab)
    chartEvents.$on('openMenuScanner', this.openMenuScanner)
  },
  beforeDestroy() {
    chartEvents.$off('openMenuInTab')
    chartEvents.$off('openMenuScanner')
  },

};
</script>

<style scoped>
/* Main Container */
.top-container {
  flex: 0 0 auto; /* Fixed height for top navigation bar */
}

.middle-container {
  display: flex;
  flex: 1; /* Take up remaining space */
  overflow: hidden;
  margin-top: -2px;
   /* Prevent overflow */
}

.left-bar-container {
  flex: 0 0 auto; /* Fixed width for left bar */
  width: 60px; /* Adjust width as needed */
  background-color: #1a1a1a; /* Example background color */
}

.left-container {
  flex: 1; /* Take up remaining space */
  position: relative;
  overflow: hidden; /* Prevent chart overflow */
}

.right-container {
  flex: 0 0 auto; /* Fixed width for right toolbar */
  width: 300px; /* Adjust width as needed */
  background-color: #ffffff;
  overflow: scroll;
  height: 100vh;
   /* Example background color */
}

.bottom-toolbox {
  flex: 0 0 auto; /* Fixed height for bottom toolbar */
}
</style>