<template>
    <!-- main Hedeer -->
    <div>
            <right-bar-header
               headerText="Stock Info"
               @onClose="onClose"
               :headerIcon="isStockInfo"
            ></right-bar-header>
             <div v-if="isStockInfo">
                <div class="h-[250px] px-[10px] overflow-y-auto scrollbar">
                  
                    <!-- ticker info header start-->
                    <div class="flex items-center py-4 gap-2">
                        <div class=" w-[60px] ">
                             <div class=" h-[40px]">
                                <img :src="`https://logo.synthfinance.com/ticker/${this.tickerInfoData[0]?.symbolName}`" class="h-[40px] w-[40px] rounded-full object-cover" />
                             </div>
                        </div>

                        <div>
                            <div class="text-[14px] font-medium line-clamp-1" :title="this.tickerInfoData[0]?.companyName">
                                {{ this.tickerInfoData[0]?.companyName }}
                            </div>
                            <!-- ticker info price start -->
                            <div class="flex gap-2">
                                
                                <div 
                                class="text-[12px]"
                                
                                >
                                    ${{ this.tickerInfoData[0]?.close }}
                                </div>

                                <div 
                                class="text-[12px]"
                                :class="this.tickerInfoData[0]?.change > 0 ? 'text-[#00b300]' : 'text-[#ff0000]' "
                                >
                                     {{this.tickerInfoData[0]?.change }}({{this.tickerInfoData[0]?.changeInPercent }}%)
                                </div>

                                <!-- <div class="text-[12px] text-[#7c7c7c]">
                                    Vol: {{ this.tickerInfoData[0]?.volume }}
                                </div> -->
                            </div>
                            <!-- ticker info price end -->

                        </div>
                    </div>
                    <!-- ticker info header end-->

                    <div
                     v-for="(metadata, index) of metaData " :key="index"
                     >
                        <div class="flex justify-between border-t-[1px] border-[#e4e4e4] py-2">
                            <div class="text-[12px] text-[#989898]">
                                {{ metadata.left }}
                            </div>
                            <div class="text-[12px] text-[#000]">
                                {{ metadata.right.replace(/"/g, '')  }}
                            </div>
                        </div>

                        
                    </div>
                </div>
             </div>
        </div>
</template>

<script>
import RightBarHeader from "../components/RightBarHeader.vue"

export default {
    name : 'TickerInfo',
    components : {
        RightBarHeader
    },
    props : {
        metaData : {
            type : Array,
            default : []
        },
        tickerInfoData : {
            type : Array,
            default : []
        }
        // isStockInfo : {
        //     type : Boolean,
        //     default : false
        // }
    },
    data() {
        return {
            isStockInfo : true
        }
    },
    methods : {
        onClose() {
            console.log("onClose")
            this.isStockInfo = !this.isStockInfo
            let result = JSON.parse(localStorage.getItem("rightBar"))
            result.stockInfo = !result.stockInfo
            localStorage.setItem("rightBar", JSON.stringify(result))
            
        },
        consoleLog() {
            console.log("tick--->", this.tickerInfoData[0]);

        },

        checkRightBar(){
          let result = JSON.parse(localStorage.getItem("rightBar"))
          this.isStockInfo = result.stockInfo
        }

        
    },
    mounted(){
        //  this.consoleLog()  

        this.checkRightBar()
        }
}
</script>
