<template>
    <div class="w-full h-full">
      <div
        class="w-full h-[4rem] px-4 flex items-center mt-3 font-[700] text-[16px]"
      >
        <span>Intervals</span>
      </div>
      <ul
      class="!w-full -h-full -bg-white  dark:!bg-[#1A1A1A] dark:!border-[#6C707A] leading-3 flex flex-col items-center justify-between rounded-[3px] scrollbar overflow-y-scroll scroll-container"
      >
        <div class="!w-full h-full flex flex-col px-4 py-4 -bg-red-300">
          <li
          class="flex w-full px-5 gap-4 h-[5rem] items-center py-[14px] border border-transparent text-appBtnActiveTxt dark:!text-[#6C707A] rounded-lg md:pl-4"
            v-for="(interval, index) in intervals"
            :key="index"
            @click="handleSelectInterval(interval.key)"
            :class="
              [selectedInterval === interval.key
              ? 'bg-selectedColor dark:!bg-transparent !text-appBtnTxt border !border-selectedBorder'
                      : 'hover:border-[#e4e4e4] hover:border dark:hover:!bg-[#2D2D2D] !text-appBtnTxt hover:bg-selectedHover  -!border-[transparent]',
                      index == 0 ? 'mt-0' : 'mt-2',
        ]"
          >
            <div class="text-[16px]">{{ interval.label }}</div>
          </li>
        </div>
      </ul>
    </div>
  </template>
  <script>
  import { mapActions, mapGetters } from 'vuex'
  import { chartEvents } from '../../chartEvents'
  export default {
    name: 'IntervalVue',
    data() {
      return {
        intervals: [
          { key: 'D', label: 'Daily' },
          { key: 'W', label: 'Weekly' },
          { key: 'M', label: 'Monthly' },
        ],
  
        selectedInterval: 'D',
      }
    },
    components: {},
    methods: {
      ...mapActions([
        'setNewChartType',
        'toggleTheme',
        'handleNextSymbol',
        'handlePreviousSymbol',
        'toggleScannerModal',
        'setNewChartTimeFrame',
        'updateTrainingModeState',
      ]),
  
      handleSelectInterval(name) {
        console.log('name==>', name)
        chartEvents.$emit('setChartItervalInMobile', name)
        // If the clicked dropdown is already open, close it. Otherwise, open the new dropdown.
        this.selectedInterval = name
        // this.$emit('timeFrameEmit', name)
        // console.log('intervalname', name, this.selectedInterval)
        // this.setNewChartTimeFrame({ timeFrame: name })
        this.$emit('handleSelectInterval')
      },
    },
    computed: {
      ...mapGetters(['timeFrame']),
    //   getTime(){
    //     return 
    //   },
    },
    mounted() {
      this.selectedInterval = localStorage.getItem("SET_TIME_FRAME")
    //   console.log('this.selectedInterval', this.selectedInterval,this.getTime )
    },
    watch: {},
  }
  </script>
  <style scoped></style>
  