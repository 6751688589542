<template>
    <div>
        <div v-if="isRightBar">
        <div class="w-full h-[5rem] border-b">
            <IndicatorsHeaders
            @onClose="ShowNavDropDownClose"
          :headerName="headerName"
          />
        </div>
          
        <div>
                     
    
        </div>
        <div class="w-full h-full" >
        <ul
        class="!w-full -h-full -bg-white  dark:!bg-[#1A1A1A] dark:!border-[#6C707A] leading-3 flex flex-col items-center justify-between rounded-[3px] scrollbar overflow-y-scroll scroll-container"
        >
            <div class="!w-full h-full flex flex-col px-4 py-4 -bg-red-300">
                 <li v-for="(item, index) in indicatorOptions.data" :key="index"
                 class="flex w-full h-[5rem] items-center py-[14px] border border-transparent -text-appBtnActiveTxt dark:!text-[#6C707A] rounded-lg md:pl-4 hover:border-[#e4e4e4] hover:border dark:hover:!bg-[#2D2D2D] hover:bg-appBtnHover cursor-pointer -!border-[transparent]"
                 >
                 <div
                 @click="toggleOverlay(item, index, $event)"
                 class="hover:bg-appBtnHover -bg-red-200 h-full w-full flex cursor-pointer px-5">
                    <div class="flex items-center gap-4">
                        <div v-html="getSvg(item.selected ? 'check' : 'unChecked')"></div>
                        <div class="!text-[16px]">{{ item.desc }}</div>
                    </div>
                 </div>
                    

                 </li>
            </div>

        </ul>
    </div>
        </div>


        <!--  -->

        <div v-else  class="w-full h-full" >
        <ul
        class="!w-full h-full bg-white  dark:!bg-[#1A1A1A] dark:!border-[#6C707A] leading-3 flex flex-col items-center justify-between rounded-[3px] scrollbar overflow-y-scroll scroll-container"
        >
            <div class="!w-full h-full flex flex-col px-4 py-4 -bg-red-300">
                 <li v-for="(item, index) in indicatorsList" :key="index"
                 class="flex w-full h-[5rem] items-center py-[14px] border border-transparent -text-appBtnActiveTxt dark:!text-[#6C707A] rounded-lg md:pl-4 hover:border-[#e4e4e4] hover:border dark:hover:!bg-[#2D2D2D] hover:bg-appBtnHover cursor-pointer -!border-[transparent]"
                 >
                 <div
                 @click="triggerIndicatorInMobileModal(item)"
                 class="hover:bg-appBtnHover -bg-red-200 h-full w-full flex cursor-pointer px-5">
                    <div class="flex items-center gap-4">
                        <span v-html="getSvg(item.image)"></span>
                        <div class="!text-[16px]">{{ item.name }}</div>
                    </div>
                 </div>
                    

                 </li>
            </div>

        </ul>
    </div>



    </div>
</template>

<script>
import { chartEvents } from '../../../chartEvents';
import { getindicatorSvgsWithName } from '../../../assets/getIndicatorSvg';
import { mapGetters, mapActions } from 'vuex';
import IndicatorsHeaders from '../IndicatorHeader.vue'
// import { getindicatorSvgsWithName } from '../../../assets/getIndicatorSvg'



export default {
    name: 'IndicatorsOptions',

    
    components: {
        IndicatorsHeaders
    },
    data() {
        return {
            activeIndex: null,
            isRightBar: false,
            indicatorOptions : [],
            headerName : 'Indicators',
            indicatorsList :[
  {
     id:1,
    name: "Trend", 
  image:'trend',
},

  {
    id:2,
    name:"Momentum", 
    image:'momentum',


  },
  
  {
    id:3,
    name:"Volality" ,
      image: "voltility",


    },
]
        }
    },

    methods: {
        getSvg(name){
     return  getindicatorSvgsWithName (name, false)
    },  
    triggerIndicatorInMobileModal(item){
        this.headerName = item.name
        this.selectedIndicatorList(item.id);
        chartEvents.$emit('triggerIndicatorInMobileModalHeader', item)
        chartEvents.$emit('toggaleHeaderInTab')
        this.isRightBar = true
    },
     
    selectedIndicatorList(index) {
      switch (index) {
        case 1:
        this.indicatorOptions = this.trendCharts
        break;
        case 2:
        this.indicatorOptions = this.momentumCharts
        break;  
        case 3:
        this.indicatorOptions = this.volatilityCharts// this.indicatorOptions = this.volatilityCharts
        break;
       
        
      }
      console.log(this.indicatorOptions)
    },
    ShowNavDropDownClose() {
        console.log('ShowNavDropDownClose')
        this.isRightBar = false
        chartEvents.$emit('toggaleHeaderInTab')
        chartEvents.$emit('triggerIndicatorInMobileModalHeader')
    },

    toggleOverlay(subItem, index, event) {
         let data ={
            subItem,
            index,
            event
         }
         chartEvents.$emit('triggerIndicatorInMobile', data)


    }

    },

    computed: {
        ...mapGetters([
            'trendCharts',
            'momentumCharts',
            'volatilityCharts',
        ]),
    }

}
</script>