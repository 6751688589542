var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:(_vm.themePrimaryColor)},[_c('div',{staticClass:"bottom-tool-bar"},[_c('div',[_c('sui-button-group',{staticClass:"bottom-container-btns"},[_c('sui-dropdown',{staticClass:"button secondary",style:(_vm.trendCharts.selected ? _vm.themeSecondaryColor : {}),attrs:{"text":"Trend"}},[_c('sui-dropdown-menu',{staticStyle:{"z-index":"101","width":"150px","right":"0","color":"black"},style:(_vm.themePrimaryColor)},_vm._l((_vm.trendCharts.data),function(trend){return _c('sui-dropdown-item',{key:trend.id,ref:trend.chartId,refInFor:true,class:trend.selected ? 'selected-chart' : '',style:(trend.selected
                  ? { ..._vm.themeSecondaryColor, ..._vm.themeFontColor }
                  : { ..._vm.themePrimaryColor, ..._vm.themeFontColor }),on:{"mouseover":function($event){trend.selected ? null : _vm.enterThemehoverColor($event.target)},"mouseout":function($event){trend.selected ? null : _vm.exitElementColor($event.target)},"click":function($event){return _vm.toggleOverlay(trend)}}},[_vm._v(" "+_vm._s(trend.desc)+" ")])}),1)],1),_c('sui-dropdown',{staticClass:"button secondary",style:(_vm.momentumCharts.selected ? _vm.themeSecondaryColor : {}),attrs:{"text":"Momentum"}},[_c('sui-dropdown-menu',{staticStyle:{"z-index":"101","width":"150px","right":"0","color":"black"},style:(_vm.themePrimaryColor)},_vm._l((_vm.momentumCharts.data),function(trend){return _c('sui-dropdown-item',{key:trend.id,ref:trend.chartId,refInFor:true,class:trend.selected ? 'selected-chart' : '',style:(trend.selected
                  ? { ..._vm.themeSecondaryColor, ..._vm.themeFontColor }
                  : { ..._vm.themePrimaryColor, ..._vm.themeFontColor }),on:{"mouseover":function($event){trend.selected ? null : _vm.enterThemehoverColor($event.target)},"mouseout":function($event){trend.selected ? null : _vm.exitElementColor($event.target)},"click":function($event){return _vm.toggleOverlay(trend)}}},[_vm._v(" "+_vm._s(trend.desc)+" ")])}),1)],1),_c('sui-dropdown',{staticClass:"button secondary",style:(_vm.volatilityCharts.selected ? _vm.themeSecondaryColor : {}),attrs:{"text":"Volatility"}},[_c('sui-dropdown-menu',{staticStyle:{"z-index":"101 !important","width":"150px","right":"0","color":"black"},style:(_vm.themePrimaryColor)},_vm._l((_vm.volatilityCharts.data),function(trend){return _c('sui-dropdown-item',{key:trend.id,ref:trend.chartId,refInFor:true,class:trend.selected ? 'selected-chart' : '',style:(trend.selected
                  ? { ..._vm.themeSecondaryColor, ..._vm.themeFontColor }
                  : { ..._vm.themePrimaryColor, ..._vm.themeFontColor }),on:{"mouseover":function($event){trend.selected ? null : _vm.enterThemehoverColor($event.target)},"mouseout":function($event){trend.selected ? null : _vm.exitElementColor($event.target)},"click":function($event){return _vm.toggleOverlay(trend)}}},[_vm._v(" "+_vm._s(trend.desc)+" ")])}),1)],1),(_vm.getUserEmail==='mat@loop2.com.au')?_c('sui-dropdown',{staticClass:"button secondary",style:(_vm.plusCharts.selected ? _vm.themeSecondaryColor : {}),attrs:{"text":"+"}},[_c('sui-dropdown-menu',{staticStyle:{"z-index":"101 !important","width":"150px","right":"0","color":"black"},style:(_vm.themePrimaryColor)},_vm._l((_vm.plusCharts.data),function(trend){return _c('sui-dropdown-item',{key:trend.id,ref:trend.chartId,refInFor:true,class:trend.selected ? 'selected-chart' : '',style:(trend.selected
                  ? { ..._vm.themeSecondaryColor, ..._vm.themeFontColor }
                  : { ..._vm.themePrimaryColor, ..._vm.themeFontColor }),on:{"mouseover":function($event){trend.selected ? null : _vm.enterThemehoverColor($event.target)},"mouseout":function($event){trend.selected ? null : _vm.exitElementColor($event.target)},"click":function($event){return _vm.toggleOverlay(trend)}}},[_vm._v(" "+_vm._s(trend.desc)+" ")])}),1)],1):_vm._e(),_c('sui-dropdown',{staticClass:"button secondary drawing footer-drawing-btn",staticStyle:{"display":"none"},attrs:{"text":"Drawings"}},[_c('sui-dropdown-menu',{staticStyle:{"z-index":"101","width":"195px","right":"0","color":"black"},style:(_vm.themePrimaryColor)},[_c('sui-dropdown-item',{style:(_vm.mob_menu_dropdn_item === 'Measure Tool'
                  ? { ..._vm.themeSecondaryColor, ..._vm.themeFontColor }
                  : {} && _vm.themeFontColor),on:{"click":function($event){_vm.Mob_Menu_Dropdn_Item('Measure Tool'),
                  _vm.selectTool('RangeTool:PriceTime')}}},[_c('sui-icon',{style:(_vm.themeFontColor),attrs:{"name":"ticket alternate icon "}}),_vm._v(" Measure Tool ")],1),_c('sui-dropdown-item',{style:(_vm.mob_menu_dropdn_item === 'Trend Line'
                  ? { ..._vm.themeSecondaryColor, ..._vm.themeFontColor }
                  : {} && _vm.themeFontColor),on:{"click":function($event){_vm.Mob_Menu_Dropdn_Item('Trend Line'),
                  _vm.selectTool('TrendLine:Trend')}}},[_c('sui-icon',{style:(_vm.themeFontColor),attrs:{"name":"window minimize outline icon "}}),_vm._v(" Trend Line ")],1),_c('sui-dropdown-item',{style:(_vm.mob_menu_dropdn_item === 'Horizontal Line'
                  ? { ..._vm.themeSecondaryColor, ..._vm.themeFontColor }
                  : {} && _vm.themeFontColor),on:{"click":function($event){_vm.Mob_Menu_Dropdn_Item('Horizontal Line'),
                  _vm.selectTool('HLineTool:Segment')}}},[_c('sui-icon',{style:(_vm.themeFontColor),attrs:{"name":"arrows alternate horizontal icon "}}),_vm._v(" Horizontal Line ")],1),_c('sui-dropdown-item',{style:(_vm.mob_menu_dropdn_item === 'Text'
                  ? { ..._vm.themeSecondaryColor, ..._vm.themeFontColor }
                  : {} && _vm.themeFontColor),on:{"click":function($event){_vm.Mob_Menu_Dropdn_Item('Text'), _vm.selectTool('TextTool:Label')}}},[_c('sui-icon',{style:(_vm.themeFontColor),attrs:{"name":"font icon "}}),_vm._v(" Text ")],1),_c('sui-dropdown-item',{style:(_vm.mob_menu_dropdn_item === 'Box'
                  ? { ..._vm.themeSecondaryColor, ..._vm.themeFontColor }
                  : {} && _vm.themeFontColor),on:{"click":function($event){_vm.Mob_Menu_Dropdn_Item('Box'), _vm.selectTool('RectangleTool:Shape')}}},[_c('sui-icon',{style:(_vm.themeFontColor),attrs:{"name":"box icon "}}),_vm._v(" Box ")],1),_c('sui-dropdown-item',{style:(_vm.mob_menu_dropdn_item === 'Parallel Line'
                  ? { ..._vm.themeSecondaryColor, ..._vm.themeFontColor }
                  : {} && _vm.themeFontColor),on:{"click":function($event){_vm.Mob_Menu_Dropdn_Item('Parallel Line'),
                  _vm.selectTool('ChannelTool:Segment')}}},[_c('sui-icon',{style:(_vm.themeFontColor),attrs:{"name":"pause icon "}}),_vm._v(" Parallel Line ")],1),_c('sui-dropdown-item',{style:(_vm.mob_menu_dropdn_item === 'Fibonacci Retracement'
                  ? { ..._vm.themeSecondaryColor, ..._vm.themeFontColor }
                  : {} && _vm.themeFontColor),on:{"click":function($event){_vm.Mob_Menu_Dropdn_Item('Fibonacci Retracement'),
                  _vm.selectTool('FibonacciRetrace:Segment')}}},[_c('sui-icon',{style:(_vm.themeFontColor),attrs:{"name":"random icon "}}),_vm._v(" Fibonacci Retracement ")],1),_c('sui-dropdown-item',{style:(_vm.mob_menu_dropdn_item === 'Trade Planner'
                  ? { ..._vm.themeSecondaryColor, ..._vm.themeFontColor }
                  : {} && _vm.themeFontColor),on:{"click":function($event){_vm.Mob_Menu_Dropdn_Item('Trade Planner'),
                  _vm.selectTool('TradeVisualizerTool:PL')}}},[_c('sui-icon',{style:(_vm.themeFontColor),attrs:{"name":"sliders horizontal icon "}}),_vm._v(" Trade Planner ")],1),_c('sui-dropdown-item',{style:(_vm.mob_menu_dropdn_item === 'Trailing Stop'
                  ? { ..._vm.themeSecondaryColor, ..._vm.themeFontColor }
                  : {} && _vm.themeFontColor),on:{"click":function($event){_vm.Mob_Menu_Dropdn_Item('Trailing Stop'),
                  _vm.selectTool('TrailingStopTool:PL')}}},[_c('sui-icon',{style:(_vm.themeFontColor),attrs:{"name":"schlix icon "}}),_vm._v(" Trailing Stop ")],1),_c('sui-dropdown-item',{style:(_vm.mob_menu_dropdn_item === 'Delete All Drawings'
                  ? { ..._vm.themeSecondaryColor, ..._vm.themeFontColor }
                  : {} && _vm.themeFontColor),on:{"click":function($event){_vm.onDeleteButtonClick(),
                  _vm.Mob_Menu_Dropdn_Item('Delete All Drawings')}}},[_c('sui-icon',{style:(_vm.themeFontColor),attrs:{"name":"trash alternate outline icon "}}),_vm._v(" Delete All Drawings ")],1)],1)],1)],1)],1),_c('div',{staticClass:"footer-web-drawings",staticStyle:{"margin-left":"2rem"}},[_c('sui-button-group',{attrs:{"icons":""}},[_vm._l((_vm.toolButtons),function(tool){return _c('custom-image-icon-button',{key:tool.img,attrs:{"imageSrc":tool.img,"isText":tool.isText,"tooltipText":tool.toolTip,"toolColor":tool.style},on:{"click":tool.onClick}})}),_vm._l((_vm.emojiButton),function(tool){return _c('custom-image-icon-button',{key:tool.img,attrs:{"imageSrc":tool.img,"isText":tool.isText,"tooltipText":tool.toolTip,"toolColor":tool.style},on:{"click":tool.onClick}})}),_c('sui-button',{attrs:{"icon":"trash","secondary":"","data-tooltip":"Delete All Drawings","data-position":"top center","data-inverted":""},on:{"click":_vm.onDeleteButtonClick}})],2)],1)]),(_vm.openEmoji)?_c('div',{staticStyle:{"position":"absolute","/*margin-bottom":"200%","*/\n  /*top":"0px","*/\n  bottom":"80px","left":"39%","transform":"translate(-50%, -89%)","z-index":"99 !important","background":"white","/* Optional":"Padding for spacing */"}},[_c('VuemojiPicker',{attrs:{"isDark":false},on:{"emojiClick":_vm.handleEmojiClick}})],1):_vm._e(),(_vm.showTrailingStop)?_c('trailing-stop',{attrs:{"trialingStopData":_vm.trialingStopData},on:{"hideTrailingStop":_vm.trailingStopDialogOnClose}}):_vm._e(),_vm._v("` ")],1)
}
var staticRenderFns = []

export { render, staticRenderFns }