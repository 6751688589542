<template>
    <div class="!h-full">
      <div 
      v-if="isRightSidebarOpen"
      class="w-full h-full">
        <!-- heading -->
        <div
          class="w-full h-[4rem] px-4 flex items-center mt-3 font-[700] text-[16px]"
        >
          <span>Nav buttons</span>
        </div>
    
        <!-- menu btns -->
        <ul
          class="!w-full h-full bg-white  dark:!bg-[#1A1A1A] dark:!border-[#6C707A] leading-3 flex flex-col items-center justify-between rounded-[3px] overflow-y-scroll scroll-container"
        >
          <div class="w-full h-full flex flex-col -space-y-1">
            <li
              class="flex w-full h-[5rem] items-center space-x-2 gap-2 --px-8 py-4 -border-b text-appBtnTxt dark:!text-[#6C707A] -rounded-md"
              v-for="(chartType, index) in navBtns"
              :key="index"
              :class="[
                isActive == chartType?.name
                  ? 'bg-appBtnActive dark:!bg-transparent !text-appBtnActiveTxt dark:!text-[#F6F6F6] !border-appBtnActive'
                  : '-hover:border-[#E4E4E4] dark:hover:!bg-[#2D2D2D] hover:bg-appBtnHover -!border-[transparent]',
                getDisable(chartType.key)
                  ? 'pointer-events-none opacity-50 cursor-default'
                  : 'cursor-pointer',
              ]"
            >
              <div @click="newSettings(chartType)" :class="[chartType?.name == 'indicator' ? 'w-[90%]' : 'w-full',isActive == chartType?.name
                  ? 'bg-appBtnActive dark:!bg-transparent !text-appBtnActiveTxt dark:!text-[#F6F6F6] !border-appBtnActive'
                  : '-hover:border-[#E4E4E4] dark:hover:!bg-[#2D2D2D] hover:bg-appBtnHover -!border-[transparent]' ]" class="flex px-4 py-6 items-center space-x-2">
                <div
                  :class="
                    chartType?.name === isActive
                      ? '!text-appBtnActiveTxt'
                      : 'text-[#323232]'
                  "
                >
                  <span v-html="getSvg(chartType.icon, isActive)"></span>
                </div>
                <div class="!text-[16px]">{{ chartType.btn }}</div>
              </div>
              <!-- v-if="getSelectedIndicators?.length > 0 && ohlcv?.length > 0" -->
    
              <!-- indicator delete btn -->
              <div  v-if="getSelectedIndicators?.length > 0 && ohlcv?.length > 0"
                title="Delete all indicators"
                :class="chartType?.name == 'indicator' ? '' : 'hidden'"
                class="bg-white rounded-sm p-2 absolute right-6"
                @click="toggleRemoveIndicator(chartType)"
              >
                <div v-html="getSvg(chartType.deleteIcon)"></div>
              </div>
            </li>
          </div>
        </ul>
  
  
  
  
      </div>
  
       
      <!-- right sidebar -->
      <div v-else >
        <div
        > <div v-if="isActiveHeader" class="w-full h-[5rem] border-b">
        <IndicatorHeader
          :headerName="tabHeadrName"
          @onClose="closeModal"
        />
    </div>

          <div v-if="isActive == 'scanner'">
            <SacnnerOption/>
          </div>
  
          <div v-if="isActive == 'indicator'">
            <IndicatorsOptions/>
          </div>
  
  <div v-if="isActive == 'Stock info'" class="w-full">
    <ScannerResultOption/>
    
  </div>
  
        </div>   
      </div>
    </div>
      
    </template>
    <script>
    import { getSvgByName } from '../../assets/rightbar/svgUtilis'
    import { mapGetters, mapActions } from 'vuex'
    import { chartEvents } from '../../chartEvents'
    import SacnnerOption from './subMenuComponent/SacnnerOption.vue'
    import IndicatorsOptions from './subMenuComponent/IndicatorsOptions.vue'
    import ScannerResultOption from './subMenuComponent/ScannerResultOption.vue'
    import  RightBar  from '../RightBar.vue'
    import IndicatorHeader from './IndicatorHeader.vue'
    
    
    export default {
      name: 'ChartTypeVue',
      data() {
        return {
          isActive: 'indicator',
          // isActiveTab : 'watchlist',
          isActiveHeader : true,
          isRightSidebarOpen: false,
          navBtns: [
            {
              id: 1,
              icon: 'watchlist',
              name: 'watchlist',
              btn: 'Watchlist',
            },
            {
              id: 2,
              icon: 'scanResult',
              name: 'scannerResults',
              btn: 'Scan Result',
            },
            // {
            //   id: 3,
            //   icon: 'trainingMode',
            //   name: 'trainingMode',
            //   btn: 'Training Mode',
            // },
            {
              id: 4,
              icon: 'comparingSymbols',
              name: 'comparingSymbols',
              btn: 'Comparing Symbols',
            },
            {
              id: 5,
              icon: 'indicator',
              name: 'indicator',
              btn: 'Indicators',
              deleteIcon: 'deleteIcon',
            },
            {
              id: 6,
              icon: 'scanner',
              name: 'scanner',
              btn: 'Scanners',
            },
            {
              id: 7,
              icon: 'settings',
              name: 'settings',
              btn: 'Settings',
            },
            {
              id: 8,
              icon: 'logout',
              name: 'logout',
              btn: 'Logout',
            },
          ],
        }
  
        
      },
      props: {
        tabHeadrName:{
              type: String,
              default: ''
          }
      },
      components: {
        SacnnerOption,
        IndicatorsOptions,
        ScannerResultOption,
        RightBar,
        IndicatorHeader

      },
    
      methods: {
        ...mapActions([
          'setNewChartType',
          'toggleTheme',
          'handleNextSymbol',
          'handlePreviousSymbol',
          'toggleScannerModal',
          'setNewChartTimeFrame',
          'updateTrainingModeState',
        ]),
        
    
        
        getSvg(name, chartType) {
          return getSvgByName(name, chartType)
        },
    
        getDisable(key) {
          if (key == 'CompareChartMain') {
            return true
          } else {
            return false
          }
        },
    
        toggleRemoveIndicator(item){
          chartEvents.$emit('toggleRemoveIndicator')
          this.$emit('ShowNavDropDownClose')
        },
    
        newSettings(item) {
           if (item.name == 'comparingSymbols') {
            this.$emit('ShowNavDropDownClose')
            chartEvents.$emit('comparingSymbols')
            return
           }
           if( item.name == 'settings' ){
            chartEvents.$emit('showSettings')
            this.$emit('ShowNavDropDownClose')
            return
           }
           if( item.name == 'logout' ){
               localStorage.clear();
               this.$router.push({ name: 'login' })
               return
           }
          this.isRightSidebarOpen = !this.isRightSidebarOpen
          this.isActive = item.name
          this.$emit('trasferData', item)
        },
        previousPage() {
          this.isRightSidebarOpen = !this.isRightSidebarOpen
          this.$emit('trasferData', {btn :'Menu'})
        },

        setHeaderName(name){
            this.isActive = name    
        },

        closeModal() {
            this.$emit("onClose");
        },
        toggaleHeaderInTab(){
          this.isActiveHeader = !this.isActiveHeader
        }
  
      },
      computed: {
        ...mapGetters([
          'getChartType',
          'getSelectedIndicators',
          'ohlcv',
        ])
      },
      mounted() {
    
        let type = localStorage.getItem('SET_CHART_TYPE')
        if (type) {
          let obj = this.chartTypes?.find((item) => item.key == type)
          if (obj) {
            this.selectedChartType = obj
          }
        }
    
        this.setHeaderName(this.tabHeadrName)
        
    
      },
      
      watch: {
        getChartType(type) {
          if (type) {
            let obj = this.chartTypes?.find((item) => item.key == type)
            if (obj) {
              this.selectedChartType = obj
            }
          }
        },
    
      
      },
      created() {
        chartEvents.$on('previousPage' , this.previousPage)
        chartEvents.$on("toggaleHeaderInTab", this.toggaleHeaderInTab)
      },
      beforeDestroy() {
        chartEvents.$off('previousPage')
        chartEvents.$off("toggaleHeaderInTab")
      },
    }
    </script>
    <style scoped></style>
    